import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Entitlement, EntitlementId } from "types/entitlements";
import { EntitlementIds } from "types/entitlements";
import type { BillingPlanType } from "types/billing/BillingPlan";
import { useConfigMapBase } from "./useConfigMapBase";
import uniq from "lodash/uniq";
import { useMemo } from "react";

type EntitlementAvailabilityMap = {
  [entitlementId in EntitlementId]: boolean;
};

type EntitlementMap = {
  [entitlementId: string]: boolean;
};

type UseEntitlement = EntitlementAvailabilityMap & {
  userPlan?: BillingPlanType;
};

export function useEntitlements(): UseEntitlement {
  const { currentPlan } = useSelector((state: RootState) => state.billing);
  const { entitlementMap } = useSelector((state: RootState) => state.entitlement);

  const entitlements: EntitlementAvailabilityMap = EntitlementIds.reduce((map, entitlementId) => {
    const maybeEntitlement: Entitlement | undefined = entitlementMap[entitlementId];

    map[entitlementId] = maybeEntitlement?.available ?? false;

    return map;
  }, {} as EntitlementAvailabilityMap);

  return {
    ...entitlements,
    userPlan: currentPlan,
  };
}

export function useEntitlementKey(entitlementId: EntitlementId): boolean {
  return useSelector((state: RootState) => {
    const maybeEntitlement: Entitlement | undefined = state.entitlement.entitlementMap[entitlementId];
    return maybeEntitlement?.available ?? false;
  }, shallowEqual);
}

export function useAllEntitlementKeys(entitlementIds: string[]): { [key: string]: boolean } {
  return useSelector((state: RootState) => {
    const results: { [key: string]: boolean } = {};

    entitlementIds.forEach((entitlementId) => {
      const maybeEntitlement: Entitlement | undefined = state.entitlement.entitlementMap[entitlementId];
      if (maybeEntitlement?.available) {
        results[entitlementId] = true;
        return;
      }

      const entitlementConfig = state.entitlement.entitlementConfigs.find((config) => config.code === entitlementId);
      const currentPlan = state.billing.currentPlan;
      const currentUser = state.session.user;
      const organizations = state.organizations.organizations;

      if (!entitlementConfig || !currentUser) {
        results[entitlementId] = false;
        return;
      }

      const isUserEnabled = entitlementConfig.users?.some((user) => user.id === currentUser.id);
      const isPlanEnabled = currentPlan ? entitlementConfig.plans[currentPlan.code]?.enabled : false;
      const isOrgEnabled = entitlementConfig.organizations?.some((org) => organizations[org.id]?.memberIds.includes(currentUser.id));

      results[entitlementId] = isUserEnabled || isPlanEnabled || isOrgEnabled || false;
    });

    return results;
  }, shallowEqual);
}

export function useConfigEntitlementIds(): string[] | undefined {
  const configMap = useConfigMapBase();

  return useMemo(() => {
    const configEntitlementIds = Object.values(configMap).flatMap((projectConfig) => [
      ...(projectConfig.config.entitlement ? [projectConfig.config.entitlement] : []),
      ...(projectConfig.config.reverseEntitlement ? [projectConfig.config.reverseEntitlement] : []),
    ]);

    return uniq(configEntitlementIds);
  }, [configMap]);
}

export function useCombinedEntitlementIds() {
  const uniqueConfigEntitlementIds = useConfigEntitlementIds() || [];
  return [...uniqueConfigEntitlementIds, ...EntitlementIds];
}

export function useProjectConfigEntitlements(): EntitlementMap {
  const { entitlementMap } = useSelector((state: RootState) => state.entitlement, shallowEqual);
  const uniqueConfigEntitlementIds = useConfigEntitlementIds();

  return useMemo(() => {
    if (!uniqueConfigEntitlementIds) {
      return {};
    }

    return uniqueConfigEntitlementIds.reduce((map, entitlementId) => {
      if (!entitlementId) {
        return map;
      }
      const maybeEntitlement: Entitlement | undefined = entitlementMap[entitlementId];
      map[entitlementId] = maybeEntitlement?.available ?? false;
      return map;
    }, {} as EntitlementMap);
  }, [entitlementMap, uniqueConfigEntitlementIds]);
}

export function useEntitlementsCount(): number {
  return useSelector((state: RootState) => Object.keys(state.entitlement.entitlementMap).length);
}
