import {
  Button,
  Text,
  Stack,
  Center,
  ModalCloseButton,
  useBreakpointValue,
  useToast,
  FormControl,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { useButtonProps, usePortfolioCollections } from "hooks";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { ProjectCriteriaForm } from "screens/panels/research/ProjectCriteriaForm";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import { v4 as uuid } from "uuid";
import { CommonModal } from "./CommonModal";
import equity from "screens/common/static/misc/portfolio_equity.jpg";
import market from "screens/common/static/misc/portfolio_market.jpg";
import mergers from "screens/common/static/misc/portfolio_mergers.jpg";
import environment from "screens/common/static/misc/portfolio_environment.jpg";
import eft from "screens/common/static/misc/portfolio_eft.jpg";
import dividends from "screens/common/static/misc/portfolio_dividends.jpg";
import { TileSelector } from "./TileSelector";
import { sendMessage } from "state/websocket/operations";
import type { RequestEntities } from "types/charliui";
import { useDispatch } from "react-redux";
import { ToastMessageContent } from "screens/common/components";
import { SectionHeader } from "screens/content/contentView/previewSection/SectionHeader";

export const portfolioSelectors = [
  {
    label: "Equities Research",
    entityName: "portfolio_theme",
    value: "equity_research",
    image: equity,
    imageDimensions: { width: "9rem", height: "5rem" },
    default: true,
  },
  {
    label: "Mergers & Acquisitions",
    entityName: "portfolio_theme",
    value: "mergers_and_acquisitions",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: mergers,
  },
  {
    label: "Market Sector Performance",
    entityName: "portfolio_theme",
    value: "market_sector_performance",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: market,
  },
  {
    label: "Environmental Due Diligence",
    entityName: "portfolio_theme",
    value: "environmental_due_diligence",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: environment,
  },
  {
    label: "Dividend Analysis",
    entityName: "portfolio_theme",
    value: "dividend_analysis",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: dividends,
  },
  {
    label: "Asset Bundles and ETFs",
    entityName: "portfolio_theme",
    value: "asset_bundles_and_etf",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: eft,
  },
];

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onClickStep?: () => void;
  modalColor?: string;
  modalFontColor?: string;
  modalTitle?: string;
  closeButton?: boolean;
}

export const ModalHeader: React.FC<Props> = ({ modalColor = "#4799d4", modalFontColor = "white", modalTitle, closeButton }) => {
  return (
    <Stack
      color={modalFontColor}
      bgColor={modalColor}
      direction={"row"}
      fontSize="md"
      textAlign="center"
      width="100%"
      height={"3rem"}
      justifyContent={"space-between"}
      px="1rem">
      <Center>
        <Text fontWeight={"semibold"} fontSize="lg">
          {modalTitle}
        </Text>
      </Center>
      {closeButton && (
        <Center>
          <ModalCloseButton cursor="pointer" _hover={{}} />
        </Center>
      )}
    </Stack>
  );
};

export const NewPortfolioModalBody: React.FC<Props> = ({ isOpen, onClose, onClickStep }) => {
  const commonButtonProps = useButtonProps("sm", "cta");
  const { onConversationOpen } = useContext(ConversationContext);
  const portfolioCollections = usePortfolioCollections({ filterShared: true });
  const [portfolioName, setPortfolioName] = useState<string>("");
  const [portfolioTheme, setPortfolioTheme] = useState<string>("");
  const [isPortfolioNameUsed, setIsPortfolioNameUsed] = useState(false);
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const toast = useToast();
  const modalRef = useRef<HTMLDivElement>(null);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  const handleOnClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    const isNameUsed = portfolioCollections.some((collection) => collection.name === portfolioName);
    setIsPortfolioNameUsed(isNameUsed);
  }, [portfolioCollections, portfolioName]);

  const onSubmitPortfolioRequest = useCallback(() => {
    if (isPortfolioNameUsed) return;

    setIsCreatingPortfolio(true);
    const newConversationId = uuid();
    const entities: RequestEntities = [
      { entity: "collection_type", value: "portfolio" },
      { entity: "collection_name", value: portfolioName.trim() },
      { entity: "portfolio_theme", value: portfolioTheme },
    ];

    dispatch(
      sendMessage({
        conversationId: newConversationId,
        intent: "/create_or_fetch_portfolio",
        entities: [...entities],
      })
    );

    toast.closeAll();
    toast({
      render: ({ onClose }) => (
        <ToastMessageContent
          message={`I'll set up your ${portfolioName} portfolio now. It will be ready for you in a few seconds.`}
          onClick={() => {
            !isMobile && onConversationOpen(newConversationId);
            onClose();
          }}
          onClose={onClose}
        />
      ),
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    handleOnClose();
  }, [isPortfolioNameUsed, portfolioName, portfolioTheme, dispatch, toast, handleOnClose, isMobile, onConversationOpen]);

  const onHandleSubmit = useCallback(() => {
    onSubmitPortfolioRequest();
    onClickStep && onClickStep();
  }, [onSubmitPortfolioRequest, onClickStep]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen) return;
      if (event.key === "Enter" && portfolioName.length > 0) {
        onHandleSubmit();
      }
      if (event.key === "Escape") {
        handleOnClose();
      }
    },
    [handleOnClose, isOpen, onHandleSubmit, portfolioName.length]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const firstInput = modalRef.current?.querySelector("input");
        if (firstInput) {
          (firstInput as HTMLInputElement).focus();
        }
      }, 0);
      setPortfolioTheme(portfolioSelectors[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Stack ref={modalRef} justifyContent="space-between" spacing="0" height="32.5rem" background={bgColor}>
      <Stack
        spacing="2rem"
        px={isMobile ? "1rem" : "2rem"}
        pt=".5rem"
        pb="1.5rem"
        width="100%"
        height={"100%"}
        justifyContent="space-between">
        <Stack spacing="2rem">
          <Stack spacing=".5rem" color={textColor}>
            {/* TODO: replace ProjectCriteriaForm, and update My Portfolios project config, with system preferences for copy when available  */}
            <ProjectCriteriaForm collectionType={"portfolio"} />
            <FormControl pt="1rem">
              <SectionHeader title="Portfolio Name" />
              <Input
                type="text"
                background={bgColor}
                id="portfolio-name-input"
                placeholder=""
                size="sm"
                rounded="10px"
                value={portfolioName}
                onChange={(e) => setPortfolioName(e.target.value)}
              />
            </FormControl>
            {isPortfolioNameUsed && (
              <Text as="i" fontSize="sm">
                {`"${portfolioName}" is already in use. Please choose a different name.`}
              </Text>
            )}
          </Stack>
          <TileSelector
            layout="scroll"
            title="Portfolio Theme"
            tiles={portfolioSelectors}
            onSelectedTile={(tile) => setPortfolioTheme(tile.value)}
          />
        </Stack>
        <Stack direction="row" justifyContent={"end"} width="100%">
          <Button
            {...commonButtonProps}
            isDisabled={isPortfolioNameUsed || portfolioName.length === 0 || isCreatingPortfolio}
            id={`ch-create-portfolio`}
            aria-label="submit"
            onClick={onHandleSubmit}>
            {`Create Portfolio`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const NewPortfolioModal = () => {
  const { isPortfolioModalOpen, onPortfolioModalClose } = useConversationContext();
  const modalBorderColor = useColorModeValue("#4799d4", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <CommonModal
      borderColor={modalBorderColor}
      isOpen={isPortfolioModalOpen}
      onClose={onPortfolioModalClose}
      modalHeader={<ModalHeader modalColor={modalBorderColor} modalFontColor={textColor} modalTitle="Create New Portfolio" closeButton />}
      modalBody={<NewPortfolioModalBody isOpen={isPortfolioModalOpen} onClose={onPortfolioModalClose} />}
    />
  );
};
