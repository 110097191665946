import { Box, Grid, GridItem, Stack, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { useProjectParams, useTileProps } from "hooks";
import React, { memo, useMemo, useContext, useCallback, useState, useRef } from "react";
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { ProjectHeaderImage } from "screens/landing/tabs/project/projectLandingTileLayouts/components/ProjectHeaderImage";
import { CollectionsFilterContext } from "../../CollectionsFilterContext";
import { useNavigate } from "react-router-dom";

export interface StockMarketChartData {
  projectId?: string;
  ticker: string;
  currentPrice: number;
  fairValueLow?: number;
  fairValueHigh?: number;
}

interface StockMarketChartProps {
  data: StockMarketChartData[];
}

interface TooltipProps {
  active?: boolean;
  payload?: Array<{
    value: number;
    payload: {
      projectId?: string;
      name: string;
      currentPrice: number;
      fairValue: [number | undefined, number | undefined];
    };
  }>;
}

// Memoized tooltip component
const CustomTooltip = memo(({ active, payload }: TooltipProps) => {
  const commonTileProps = useTileProps();
  const borderColor = useColorModeValue("gray.400", "gray.300");
  const tooltipBgColor = useColorModeValue("gray.700", "gray.900");
  const labelColor = useColorModeValue("gray.300", "gray.400");
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();
  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  if (!active || !payload?.length) return null;

  const fairValueLow = payload[0].payload.fairValue[1];
  const fairValueHigh = payload[0].payload.fairValue[0];

  return (
    <Stack
      {...commonTileProps}
      boxShadow={"lg"}
      p="0"
      borderColor={borderColor}
      className="stock-market-chart-tooltip"
      onClick={() => {
        if (payload[0].payload.projectId) {
          handleNavigate(payload[0].payload.projectId);
        }
      }}>
      <Box px="1rem" pt=".5rem" minH={"2rem"}>
        <ProjectHeaderImage maxHeight="1.5rem" width="8rem" collectionId={payload[0].payload.projectId} logoOnly />
      </Box>
      <Box p="1rem" bgColor={tooltipBgColor} color={"gray.100"}>
        <Grid templateColumns="auto 1fr" gap={2} alignItems="center">
          <GridItem>
            <Text fontSize="sm" color={labelColor}>
              Ticker:
            </Text>
          </GridItem>
          <GridItem>
            <Text fontSize="sm" className="stock-market-chart-tooltip-ticker">
              {payload[0].payload.name}
            </Text>
          </GridItem>
          {!isNaN(Number(fairValueHigh)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Fair Value High:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm" className="stock-market-chart-tooltip-high">
                  ${fairValueHigh}
                </Text>
              </GridItem>
            </>
          )}
          {!isNaN(Number(payload[1].value)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Current Price:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm" className="stock-market-chart-tooltip-price">
                  ${payload[1].value}
                </Text>
              </GridItem>
            </>
          )}
          {!isNaN(Number(fairValueLow)) && (
            <>
              <GridItem>
                <Text fontSize="sm" color={labelColor}>
                  Fair Value Low:
                </Text>
              </GridItem>
              <GridItem>
                <Text fontSize="sm" className="stock-market-chart-tooltip-low">
                  ${fairValueLow}
                </Text>
              </GridItem>
            </>
          )}
        </Grid>
      </Box>
    </Stack>
  );
});

CustomTooltip.displayName = "CustomTooltip";

const StockMarketChart: React.FC<StockMarketChartProps> = memo(({ data }) => {
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const { selectedProjectId, setSelectedProjectId } = useContext(CollectionsFilterContext);
  const { projectFilter, parentRoute } = useProjectParams();
  const navigate = useNavigate();
  const barOpacity = useColorModeValue(0.3, 0.5);
  const hideTooltipTimeout = useRef<NodeJS.Timeout>();
  const chartRef = useRef<HTMLDivElement>(null);
  const [tooltipData, setTooltipData] = useState<{
    active: boolean;
    payload?: Array<{
      value: number;
      payload: {
        projectId?: string;
        name: string;
        currentPrice: number;
        fairValue: [number | undefined, number | undefined];
      };
    }>;
    position?: { x: number; y: number };
  }>({ active: false });

  const handleNavigate = useCallback(
    (projectId: string) => {
      navigate(`/${parentRoute}/${projectFilter}/${projectId}`);
    },
    [navigate, parentRoute, projectFilter]
  );

  // Memoize data transformation
  const transformedData = useMemo(
    () =>
      data.map((item) => ({
        projectId: item.projectId,
        name: item.ticker,
        currentPrice: item.currentPrice,
        fairValue: [item.fairValueHigh, item.fairValueLow] as [number | undefined, number | undefined],
        fairValueBase: item.fairValueLow,
      })),
    [data]
  );

  const getOpacity = (projectId?: string) => {
    return !selectedProjectId || projectId === selectedProjectId ? 1 : 0.3;
  };

  const renderDot = (props: any) => {
    const { cx, cy, payload } = props;
    const isSelected = payload.projectId === selectedProjectId;
    const opacity = getOpacity(payload.projectId);

    return (
      <circle
        key={`dot-${payload.projectId}-${payload.name}`}
        className="stock-market-chart-dot"
        onClick={() => {
          if (payload.projectId) {
            handleNavigate(payload.projectId);
          }
        }}
        cx={cx}
        cy={cy}
        r={isSelected ? 8 : 4}
        fill="#ff4d4f"
        opacity={opacity}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const renderActiveDot = (props: any) => {
    const { cx, cy, payload } = props;

    return (
      <circle
        key={`active-dot-${payload.projectId}-${payload.name}`}
        className="stock-market-chart-active-dot"
        onClick={() => {
          if (payload.projectId) {
            handleNavigate(payload.projectId);
          }
        }}
        cx={cx}
        cy={cy}
        r={8}
        fill="#ff4d4f"
        opacity={1}
        style={{ cursor: "pointer" }}
      />
    );
  };

  // Memoize chart settings
  const chartSettings = useMemo(
    () => ({
      margin: { top: 0, right: 0, left: -35, bottom: 0 },
      barSettings: {
        fill: "#90caf9",
        fillOpacity: barOpacity,
        barSize: 40,
        className: "stock-market-chart-bar",
      },
      lineSettings: {
        stroke: "#ff4d4f",
        strokeWidth: 1,
        className: "stock-market-chart-line",
      },
      gridSettings: {
        strokeDasharray: "3 3",
        vertical: true,
        horizontal: false,
      },
      axisSettings: {
        fontSize: "10px",
      },
    }),
    [barOpacity]
  );

  const handleMouseOver = (data: any) => {
    if (data && data.activePayload && data.activePayload[0]) {
      const projectId = data.activePayload[0].payload.projectId;
      setSelectedProjectId(projectId);

      if (hideTooltipTimeout.current) {
        clearTimeout(hideTooltipTimeout.current);
      }

      setTooltipData({
        active: true,
        payload: data.activePayload,
        position: {
          x: data.activeCoordinate?.x ?? 0,
          y: data.activeCoordinate?.y ?? 0,
        },
      });
    }
  };

  const handleMouseOut = () => {
    setSelectedProjectId(undefined);
    hideTooltipTimeout.current = setTimeout(() => {
      setTooltipData({ active: false });
    }, 100);
  };

  return (
    <Box
      ref={chartRef}
      height="19rem"
      width={isMobile ? "4/3" : "31rem"}
      className="stock-market-chart"
      position="relative"
      onMouseLeave={() => {
        setSelectedProjectId(undefined);
      }}>
      {tooltipData.active && tooltipData.position && (
        <Box
          position="absolute"
          width="15rem"
          ref={(el) => {
            if (el && tooltipData.position) {
              const container = el.parentElement;
              if (!container) return;

              const containerRect = container.getBoundingClientRect();
              const top = containerRect.height / 2.3;
              const spaceOnRight = containerRect.width - tooltipData.position.x;

              let left = "unset";
              let right = "unset";
              if (containerRect.width / 2 > spaceOnRight) {
                right = `${containerRect.width / 2}px`;
                left = "unset";
              } else {
                right = "unset";
                left = `${containerRect.width / 2}px`;
              }
              el.style.transform = "translate(0, -50%)";
              el.style.right = right;
              el.style.left = left;
              el.style.top = `${top}px`;
            }
          }}
          zIndex={1000}
          onMouseEnter={() => {
            if (hideTooltipTimeout.current) {
              clearTimeout(hideTooltipTimeout.current);
            }
          }}
          onMouseLeave={() => {
            hideTooltipTimeout.current = setTimeout(() => {
              setTooltipData({ active: false });
            }, 100);
          }}>
          <CustomTooltip active={tooltipData.active} payload={tooltipData.payload} />
        </Box>
      )}
      <ResponsiveContainer>
        <ComposedChart
          className=""
          data={transformedData}
          margin={chartSettings.margin}
          onMouseMove={handleMouseOver}
          onMouseLeave={handleMouseOut}>
          <defs>
            <clipPath id="chartArea">
              <rect x={0} y={0} width="100%" height="100%" />
            </clipPath>
          </defs>
          <CartesianGrid {...chartSettings.gridSettings} />
          <rect
            x={0}
            y={0}
            width="100%"
            height="100%"
            fill="transparent"
            onMouseMove={(e: any) => {
              const chartElement = e.target.ownerSVGElement;
              const rect = chartElement.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const width = rect.width;
              const barWidth = chartSettings.barSettings.barSize;
              const dataLength = transformedData.length;
              const spacing = (width - dataLength * barWidth) / (dataLength + 1);
              const index = Math.floor((x - spacing) / (barWidth + spacing));

              if (index >= 0 && index < transformedData.length) {
                const item = transformedData[index];
                const barX = spacing + index * (barWidth + spacing) + barWidth / 2;
                const margin = chartSettings.margin;
                handleMouseOver({
                  activeCoordinate: {
                    x: barX + margin.left,
                    y: rect.height * 0.1,
                  },
                  activePayload: [
                    {
                      value: item.fairValue[0] || 0,
                      payload: item,
                    },
                    {
                      value: item.currentPrice,
                      payload: item,
                    },
                  ],
                });
              }
            }}
            style={{ pointerEvents: "all" }}
            clipPath="url(#chartArea)"
          />
          <XAxis dataKey="name" fontSize={chartSettings.axisSettings.fontSize} />
          <YAxis fontSize={chartSettings.axisSettings.fontSize} />
          <Bar isAnimationActive={false} dataKey="fairValue" {...chartSettings.barSettings} />
          <Line
            key="price-line"
            isAnimationActive={false}
            type="monotone"
            dataKey="currentPrice"
            {...chartSettings.lineSettings}
            dot={renderDot}
            activeDot={renderActiveDot}
            opacity={barOpacity}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
});

StockMarketChart.displayName = "StockMarketChart";

export default StockMarketChart;
