import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Stack, IconButton, useColorModeValue, useBreakpointValue } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useSwipeable } from "react-swipeable";
import debounce from "lodash/debounce";

interface FilterButtonsProps {
  uniqueFilters: string[];
  activeFilter: string | null;
  setActiveFilter: (filter: string | null) => void;
}

export const FilterButtons: React.FC<FilterButtonsProps> = ({ uniqueFilters, activeFilter, setActiveFilter }) => {
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const buttonColor = useColorModeValue("primary.default", "gray.300");
  const bgColor = useColorModeValue("white", "gray.700");
  const activeColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const isMobile = useBreakpointValue({ base: true, md: false }) || false;
  const itemsPerView = uniqueFilters.length > 6 ? 3 : 0;

  const handleSlide = useCallback(
    (direction: "prev" | "next") => {
      if (direction === "prev") {
        setCurrentIndex((prevIndex) => {
          const newIndex = Math.max(0, prevIndex - itemsPerView);
          setShowLeftScroll(newIndex > 0);
          setShowRightScroll(true);
          return newIndex;
        });
      } else {
        setCurrentIndex((prevIndex) => {
          const maxIndex = 5;
          const newIndex = Math.min(maxIndex, prevIndex + itemsPerView);
          setShowRightScroll(newIndex < maxIndex);
          setShowLeftScroll(true);
          return newIndex;
        });
      }
    },
    [itemsPerView]
  );

  const debouncedHandleSlide = useMemo(() => debounce(handleSlide, 300), [handleSlide]);

  const handlers = useSwipeable({
    onSwipedLeft: () => debouncedHandleSlide("next"),
    onSwipedRight: () => debouncedHandleSlide("prev"),
    trackMouse: true,
    trackTouch: true,
    delta: 2,
    rotationAngle: 0,
  });

  useEffect(() => {
    setCurrentIndex(0);
    setShowLeftScroll(false);
    setShowRightScroll(uniqueFilters.length > itemsPerView);
  }, [uniqueFilters.length, itemsPerView]);

  // Cleanup debounced function
  useEffect(() => {
    return () => {
      debouncedHandleSlide.cancel();
    };
  }, [debouncedHandleSlide]);

  const handleFilterChange = useCallback(
    (filter: string | null) => {
      setActiveFilter(filter);
    },
    [setActiveFilter]
  );

  return (
    <Stack direction="row" spacing="2" position="relative" width="100%">
      {showLeftScroll && (
        <IconButton
          aria-label="Scroll left"
          icon={<ChevronLeftIcon boxSize={"1rem"} />}
          size="sm"
          position="absolute"
          left="-1"
          top="50%"
          transform="translateY(-50%)"
          zIndex={2}
          bg={bgColor}
          minWidth="1.4rem"
          _hover={{ color: buttonColor }}
          _active={{}}
          onClick={() => debouncedHandleSlide("prev")}
        />
      )}
      <Box
        {...handlers}
        overflowX="hidden"
        position="relative"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
        px={"0"}>
        <Stack
          direction="row"
          spacing="2"
          minW="min-content"
          style={{
            transform: `translateX(calc(-${currentIndex} * (${isMobile ? "4.5rem" : "5rem"})))`,
            transition: "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            willChange: "transform",
            scrollSnapType: "x mandatory",
            scrollBehavior: "smooth",
            WebkitOverflowScrolling: "touch",
          }}>
          <Box
            as="button"
            px="5px"
            py="1px"
            borderRadius="md"
            fontSize="10px"
            whiteSpace="nowrap"
            fontWeight={activeFilter === null ? "semibold" : "normal"}
            bg={activeFilter === null ? "primary.default" : bgColor}
            color={activeFilter === null ? activeColor : "gray.500"}
            _hover={{ color: activeFilter === null ? "gray.50" : "primary.default" }}
            border="1px solid"
            borderColor={borderColor}
            onClick={(e) => {
              e.stopPropagation();
              handleFilterChange(null);
            }}
            transition="all 0.2s"
            style={{ scrollSnapAlign: "start" }}>
            ALL
          </Box>
          {uniqueFilters.map((type) => (
            <Box
              key={type}
              as="button"
              px="5px"
              py="1px"
              borderRadius="md"
              fontSize="10px"
              whiteSpace="nowrap"
              fontWeight={activeFilter === type ? "semibold" : "normal"}
              bg={activeFilter === type ? "primary.default" : bgColor}
              color={activeFilter === type ? activeColor : "gray.500"}
              _hover={{
                color: activeFilter === type ? "gray.50" : "primary.default",
              }}
              border="1px solid"
              borderColor={borderColor}
              onClick={(e) => {
                e.stopPropagation();
                handleFilterChange(type);
              }}
              transition="all 0.2s"
              style={{ scrollSnapAlign: "start" }}>
              {type.toUpperCase().replace(/_/g, " ")}
            </Box>
          ))}
        </Stack>
      </Box>
      {uniqueFilters.length > 6 && showRightScroll && (
        <IconButton
          aria-label="Scroll right"
          icon={<ChevronRightIcon boxSize={"1rem"} />}
          size="sm"
          position="absolute"
          right="-1"
          top="50%"
          transform="translateY(-50%)"
          minWidth="1.4rem"
          zIndex={2}
          bg={bgColor}
          _hover={{ color: buttonColor }}
          _active={{}}
          onClick={() => debouncedHandleSlide("next")}
        />
      )}
    </Stack>
  );
};
