import {
  Stack,
  Text,
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverBody,
  useColorModeValue,
  Center,
  List,
  ListItem,
  ListIcon,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import OnboardingVideo from "../OnboardingVideo";
import { useButtonProps, useConfigMap, useGetViewConfig, useProjectParams, useUserPreference } from "hooks";
import { useCopyValue } from "hooks/useCopies";
import { BsCursor } from "react-icons/bs";
import { CollectionsFilterContext } from "screens/landing/tabs";
import { generateQuadrantData } from "screens/landing/tabs/collections/chartComponents/data/quadrantData";

const FirstStep = ({ onClose, onNext }: { onClose: () => void; onNext: () => void }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const commonButtonProps = useButtonProps("md", "cta");

  return (
    <PopoverContent
      boxShadow={"lg"}
      width="22rem"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      _focus={{ boxShadow: "none" }}
      zIndex={10}>
      <Box height="3rem" width="3rem" left="-1.5rem" top="3rem" bg={bgColor} position={"absolute"} transform="rotate(45deg)" />
      <PopoverBody px={"1rem"}>
        <Tooltip label="Close the tour" aria-label="Close the tour" placement="top">
          <IconButton
            aria-label="Close popover"
            icon={<CloseIcon />}
            size="sm"
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            onClick={onClose}
          />
        </Tooltip>
        <Stack spacing="1.5rem" p="1rem">
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Tour the Features
          </Text>
          <Stack spacing="1rem">
            <Text fontSize="lg" fontWeight={"semibold"}>
              Check out Shared Research
            </Text>
            <Text fontSize="md">
              Shared Portfolios are a great way to see the power of Charli's automated AI research. This is read only access to real-time
              research performed by our analysts and customers that share access to specific portfolios.
            </Text>
            <Text fontSize="md">
              In each of the Shared Portfolios, you can see the level of detail provided by the AI, as well as the options to dig deeper for
              additional intelligence on the specific investments and the market.
            </Text>
          </Stack>
          <Center pt="1rem">
            <Button {...commonButtonProps} borderRadius="full" width="12rem" onClick={onNext}>
              Next
            </Button>
          </Center>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );
};

const SecondStep = ({ onClose }: { onClose: () => void }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const commonButtonProps = useButtonProps("md", "cta");
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const videoURL = homeOnboardingSteps && homeOnboardingSteps[0].url ? homeOnboardingSteps[0].url : "";

  return (
    <PopoverContent
      boxShadow={"lg"}
      width="22rem"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      _focus={{ boxShadow: "none" }}
      zIndex={10}>
      <Box height="3rem" width="3rem" left="-1.5rem" top="32.5rem" bg={bgColor} position={"absolute"} transform="rotate(45deg)" />
      <PopoverBody px={"1rem"}>
        <Tooltip label="Close the tour" aria-label="Close the tour" placement="top">
          <IconButton
            aria-label="Close popover"
            icon={<CloseIcon />}
            size="sm"
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            onClick={onClose}
          />
        </Tooltip>
        <Stack spacing="1rem" p="1rem">
          <Text fontSize="xl" fontWeight="bold">
            Step 3: Tour the Features
          </Text>
          <Box width="100%">
            <OnboardingVideo maxImageWidth="100%" videoURL={videoURL} showCloseButton={false} onCloseVideo={onClose} />
          </Box>
          <Text fontSize="lg" fontWeight={"semibold"}>
            Check out Interactive Video
          </Text>
          <Text fontSize="md">
            While Charli is analyzing your first project; the Interactive Video is a perfect way to get to know the features.
          </Text>
          <List spacing={2}>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>Charli will email you a summary report as soon as the research is complete.</Text>
            </ListItem>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>Start new research right away, Charli can work on many at the same time</Text>
            </ListItem>
            <ListItem fontSize="md" display="flex" alignItems="top">
              <ListIcon as={BsCursor} color="gray.500" transform="rotate(45deg)" mt="4px" />
              <Text>You can also check out the free shared research on the side panel</Text>
            </ListItem>
          </List>
          <Center pt="1rem">
            <Button {...commonButtonProps} borderRadius="full" width="12rem" onClick={onClose}>
              Close the Tour
            </Button>
          </Center>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );
};

const ThirdStep = ({ onClose }: { onClose: () => void }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.100", "gray.800");
  const commonButtonProps = useButtonProps("md", "cta");
  const onboardingUrl = useCopyValue("copy_onboarding_quadrant_url");

  return (
    <PopoverContent
      boxShadow={"lg"}
      width="35rem"
      border="1px solid"
      borderColor={borderColor}
      bg={bgColor}
      _focus={{ boxShadow: "none" }}
      zIndex={10}>
      <Box height="3rem" width="3rem" left="-1.5rem" top="10rem" bg={bgColor} position={"absolute"} transform="rotate(45deg)" />
      <PopoverBody px={"1rem"}>
        <Tooltip label="Close the tour" aria-label="Close the tour" placement="top">
          <IconButton
            aria-label="Close popover"
            icon={<CloseIcon />}
            size="sm"
            position="absolute"
            right="0.5rem"
            top="0.5rem"
            onClick={onClose}
          />
        </Tooltip>
        <Stack spacing="1.5rem" p="1rem">
          <Text fontSize="xl" fontWeight="bold">
            Mouseover the Pi Quadrant Index for Instant Insights
          </Text>
          <Stack spacing={3}>
            <Text>
              The Pi Quadrant Index is a powerful tool used by top-tier investment advisors to understand how their portfolio and
              investments are doing in the market.
            </Text>
            <Text>
              By leveraging the advanced models and analytics from our AI Labs, the Pi Quadrant Index evaluates all investments across 14
              dimensions including the fundamentals of the company, sector analysis, market perception, analyst commentary, and competitive
              positioning.
            </Text>
            <Text fontSize={"md"}>
              <a href={onboardingUrl} target="_blank" rel="noopener noreferrer">
                Click here for more information about the Pi Quadrant Index.
              </a>
            </Text>
          </Stack>
          <Center pt="1rem">
            <Button {...commonButtonProps} borderRadius="full" width="9rem" onClick={onClose}>
              Close
            </Button>
          </Center>
        </Stack>
      </PopoverBody>
    </PopoverContent>
  );
};

const OnboardingVideoPopover = () => {
  const { isPortfolios, projectId, parentRoute } = useProjectParams();
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const hasCompletedOnboarding = useUserPreference("ui_onboarding_completed") as boolean;
  const hasCompletedOnboardingQuadrantChart = useUserPreference("ui_onboarding_quadrant_chart_completed") as boolean;
  const [activeStep, setActiveStep] = useState(0);
  const [isOpen, setIsOpen] = useState(!hasCompletedOnboarding && !isMobile);
  const [quadrantLeft, setQuadrantLeft] = useState("48rem");
  const dispatch = useDispatch();
  const { groupedCollections } = useContext(CollectionsFilterContext);
  const quadrantData = React.useMemo(() => generateQuadrantData(groupedCollections), [groupedCollections]);

  useEffect(() => {
    if (activeStep === 2) {
      const updatePosition = () => {
        const quadrantElement = document.querySelector(".ch-metric-chart-quadrant");
        if (quadrantElement) {
          const rect = quadrantElement.getBoundingClientRect();
          const gap = 20;
          const popoverWidth = 35; // rem - width of the ThirdStep popover
          const popoverWidthPx = popoverWidth * 16; // convert rem to px
          const availableSpaceRight = window.innerWidth - (rect.left + rect.width + gap);
          const availableSpaceLeft = rect.left - gap;

          // First try to position to the right
          if (availableSpaceRight >= popoverWidthPx) {
            setQuadrantLeft(`${rect.left + rect.width + gap}px`);
          }
          // If not enough space on right, try to position to the left
          else if (availableSpaceLeft >= popoverWidthPx) {
            setQuadrantLeft(`${rect.left - popoverWidthPx - gap}px`);
          }
          // If neither side has enough space, center the popover on screen
          else {
            const centeredLeft = (window.innerWidth - popoverWidthPx) / 2;
            setQuadrantLeft(`${Math.max(gap, centeredLeft)}px`);
          }
        }
      };

      // Initial position update
      updatePosition();

      // Update position on resize and after delays to handle dynamic content
      window.addEventListener("resize", updatePosition);
      const timeouts = [
        setTimeout(updatePosition, 100), // Quick check for initial render
        setTimeout(updatePosition, 500), // Check after potential chart animation
        setTimeout(updatePosition, 1000), // Check after any delayed updates
        setTimeout(updatePosition, 2000), // Final check for any very delayed content
      ];

      // Create a mutation observer to watch for changes in the chart's size or position
      const observer = new MutationObserver(updatePosition);
      const quadrantElement = document.querySelector(".ch-metric-chart-quadrant");
      if (quadrantElement) {
        observer.observe(quadrantElement, {
          attributes: true,
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      // Cleanup
      return () => {
        window.removeEventListener("resize", updatePosition);
        timeouts.forEach(clearTimeout);
        observer.disconnect();
      };
    }
  }, [activeStep, quadrantData]);

  // dispatch(updateTypedUserPreference({ preferenceKey: "ui_hide_onboarding_videos", value: true }));
  // dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: false }));
  // dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_quadrant_chart_completed", value: false }));

  useEffect(() => {
    if (isPortfolios && !projectId && !hasCompletedOnboardingQuadrantChart && quadrantData.length > 0) {
      setActiveStep(2);
      setIsOpen(true);
    } else if (!hasCompletedOnboarding && !isMobile && parentRoute === "home") {
      setActiveStep(0);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isPortfolios, projectId, hasCompletedOnboardingQuadrantChart, parentRoute, hasCompletedOnboarding, isMobile, quadrantData.length]);

  const handleNext = () => {
    setActiveStep(1);
  };

  const handleComplete = () => {
    setIsOpen(false);
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
  };

  const handleCompleteStepThree = () => {
    setIsOpen(false);
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_quadrant_chart_completed", value: true }));
  };

  return (
    <>
      {isOpen && (
        <Box
          // onClick={() => {
          //   activeStep === 2 && handleCompleteStepThree();
          // }}
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="blackAlpha.600"
          zIndex={10}
          display="block"
        />
      )}
      <Popover isOpen={isOpen} placement="right-start" arrowSize={40} closeOnEsc={false} closeOnBlur={false}>
        <Box
          position={"absolute"}
          top={activeStep === 2 ? "5rem" : activeStep === 1 ? "unset" : "14rem"}
          bottom={activeStep === 1 ? "40rem" : "unset"}
          left={activeStep === 2 ? quadrantLeft : "14rem"}>
          {activeStep === 2 ? (
            <ThirdStep onClose={handleCompleteStepThree} />
          ) : activeStep === 0 ? (
            <FirstStep onClose={handleComplete} onNext={handleNext} />
          ) : (
            <SecondStep onClose={handleComplete} />
          )}
        </Box>
      </Popover>
    </>
  );
};

export default OnboardingVideoPopover;
