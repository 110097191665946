import { Stack, useColorModeValue, Box, Flex, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import React, { useEffect, useCallback, memo, useState } from "react";
import { useEntitlementKey } from "hooks/useEntitlements";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { isEqual } from "lodash";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";

interface SourceWeight {
  key: string;
  label: string;
  default?: boolean;
}

interface Props {
  answerFocusOptions: SourceWeight[];
  initialQuestionFocus?: string;
  setInitialQuestionFocus: (focus: string) => void;
  isDisabled?: boolean;
}

const SliderOptions = memo(
  ({
    options,
    value,
    onChange,
    isDisabled,
  }: {
    options: SourceWeight[];
    value: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
  }) => {
    const isDeepAnalysisEnabled = useEntitlementKey("ui_enable_deep_analysis");
    const activeIndex = options.findIndex((opt) => opt.key === value);
    const activeKey = options[activeIndex]?.key;
    const { answerFocusOptions } = useAnswerFocusOptions("due_diligence");
    const filteredFocusData = answerFocusOptions?.filter((option) => option.key.toLowerCase() === activeKey.toLowerCase())[0];
    const { colorMode } = useColorMode();
    const bgColor = useColorModeValue("gray.100", "#161B25");
    const labelColor = useColorModeValue("white", "gray.300");

    return (
      <Box position="relative" width="300px" height="25px">
        <Flex position="relative" width="100%" height="100%" bg={bgColor} borderRadius="full" overflow="hidden">
          <Box
            position="absolute"
            width={`${100 / options.length}%`}
            height="100%"
            bg={
              isDisabled
                ? bgColor
                : filteredFocusData
                ? `${filteredFocusData.color}.${colorMode === "light" ? "400" : "800"}`
                : "charli.primaryBlue"
            }
            borderRadius="full"
            transition="transform 0.3s ease"
            transform={`translateX(${activeIndex * 100}%)`}
          />
          {options.map((sourceWeight: SourceWeight, index) => (
            <Flex
              className={`ch-semantic-focused-answer-switch-${sourceWeight.key.toLowerCase()}`}
              key={sourceWeight.key}
              flex={1}
              alignItems="center"
              justifyContent="center"
              cursor={isDisabled || (sourceWeight.key === "deep_analysis" && !isDeepAnalysisEnabled) ? "not-allowed" : "pointer"}
              onClick={() => {
                if (isDisabled || (sourceWeight.key === "deep_analysis" && !isDeepAnalysisEnabled)) return;
                onChange(sourceWeight.key);
              }}
              position="relative"
              zIndex={1}>
              <Tooltip
                isDisabled={!(sourceWeight.key === "deep_analysis" && !isDeepAnalysisEnabled)}
                label="Upgrade for deep analysis"
                placement="top">
                <Text
                  fontSize="xs"
                  fontWeight="medium"
                  color={
                    isDisabled || (sourceWeight.key === "deep_analysis" && !isDeepAnalysisEnabled)
                      ? "gray.400"
                      : value === sourceWeight.key
                      ? labelColor
                      : "gray.600"
                  }
                  transition="color 0.3s ease"
                  userSelect="none">
                  {sourceWeight.label}
                </Text>
              </Tooltip>
            </Flex>
          ))}
        </Flex>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.options, nextProps.options) && prevProps.value === nextProps.value && prevProps.isDisabled === nextProps.isDisabled
);

SliderOptions.displayName = "SliderOptions";

export const SemanticFocusedAnswer = memo(({ isDisabled, initialQuestionFocus, setInitialQuestionFocus, answerFocusOptions }: Props) => {
  const textColor = useColorModeValue("gray.500", "gray.400");
  const dispatch = useDispatch();
  const defaultOption = answerFocusOptions?.find((opt) => opt.default)?.key || answerFocusOptions?.[0]?.key || "";
  const [localValue, setLocalValue] = useState<string>(initialQuestionFocus || defaultOption);

  useEffect(() => {
    !isDisabled && setLocalValue(initialQuestionFocus || defaultOption);
  }, [answerFocusOptions, initialQuestionFocus, isDisabled, defaultOption]);

  const handleUpdateSourceWeightPreference = useCallback(
    (value: string) => {
      if (value === localValue || isDisabled) return;

      setLocalValue(value);
      dispatch(
        updateTypedUserPreference({
          preferenceKey: "source_weights_index",
          value: value,
        })
      );
      setInitialQuestionFocus(value);
    },
    [dispatch, setInitialQuestionFocus, localValue, isDisabled]
  );

  if (!answerFocusOptions) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" alignSelf="end" color={textColor}>
      <SliderOptions
        options={answerFocusOptions}
        value={localValue}
        onChange={handleUpdateSourceWeightPreference}
        isDisabled={isDisabled}
      />
    </Stack>
  );
});

SemanticFocusedAnswer.displayName = "SemanticFocusedAnswer";
