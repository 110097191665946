/* eslint-disable no-script-url */
import { useColorModeValue, Box, Image, Tag, Center, useBreakpointValue, IconButton, Icon, Stack, useColorMode } from "@chakra-ui/react";
import { useSidebarNavigation, useUserPreference } from "hooks";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CharliLogoSmall from "screens/common/static/logos/charli-logo-small.png";
import { ReactComponent as CharliLogoFull } from "screens/common/static/logos/charli-logo-full.svg";
import { ConversationContext } from "screens/thread/ConversationContext";
import { getEnvironment } from "screens/common/app";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { FiMenu } from "react-icons/fi";

export const SidebarNavigationLogos = () => {
  const charliLogoBgColor = useColorModeValue("gray.700", "gray.900");
  const navigate = useNavigate();
  const showEnvBadgePreference = useUserPreference("ui_show_env_badge");
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const injectTestEntityPreference = useUserPreference("ui_inject_test_entity");
  const defaultHomePage = useUserPreference("ui_home_page");
  const { onConversationClose, setConversationId } = useContext(ConversationContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useContext(SettingsProviderContext);
  const { currentSidebarType } = useSidebarNavigation();
  const debugBgColor = useColorModeValue("#2c8741", "gray.700");
  const testBgColor = useColorModeValue("#ae7a31", "gray.700");
  const badgeFontColor = useColorModeValue("gray.50", "gray.300");
  const menuColor = useColorModeValue("gray.400", "gray.500");
  const { colorMode } = useColorMode();

  const onClickHome = () => {
    setConversationId("");
    const homeRoute = defaultHomePage ? `${String(defaultHomePage)}` : "home";
    navigate(`/${homeRoute}`);

    onConversationClose();
  };

  return (
    <Box width="100%">
      <Stack
        pl={isMobile || currentSidebarType === "hidden" ? "0.5rem" : "2.8rem"}
        justifyContent={"flex-start"}
        className="sidebar-logo-button"
        onClick={() => (currentSidebarType === "hidden" ? setIsSideNavigationOpen(!isSideNavigationOpen) : onClickHome && onClickHome())}
        cursor="pointer"
        direction="row"
        backgroundColor={charliLogoBgColor}
        width="calc(100% + 5px)"
        spacing=".5rem"
        height="2.6rem">
        {currentSidebarType === "hidden" && (
          <Center>
            <IconButton
              className="sidebar-menu_hamburger"
              minWidth="0"
              _hover={{}}
              _active={{}}
              zIndex={10}
              height="2.6rem"
              left="0"
              variant="ghost"
              aria-label="Open Menu"
              fontSize="20px"
              color={menuColor}
              onClick={() => setIsSideNavigationOpen(!isSideNavigationOpen)}
              cursor="pointer"
              icon={<Icon as={FiMenu} />}
            />
          </Center>
        )}
        <Center>
          {isMobile || currentSidebarType === "hidden" ? (
            <Image
              height="1.2rem"
              cursor="pointer"
              onClick={() =>
                currentSidebarType === "hidden" ? setIsSideNavigationOpen(!isSideNavigationOpen) : onClickHome && onClickHome()
              }
              src={CharliLogoSmall}
            />
          ) : (
            <CharliLogoFull
              id="close-modal-button"
              style={{
                width: "100%",
                height: "1rem",
              }}
              cursor="pointer"
            />
          )}
        </Center>
      </Stack>
      {!isMobile && (
        <Stack
          spacing="3px"
          direction="row"
          justifyContent={"flex-start"}
          pl={isMobile || currentSidebarType === "hidden" ? "1rem" : "2.8rem"}
          position="fixed"
          zIndex={9}
          top="2.1rem"
          minWidth="12rem">
          {showEnvBadgePreference && getEnvironment().label.length > 0 && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="10px"
                minHeight={"1rem"}
                color={badgeFontColor}
                backgroundColor={colorMode === "dark" ? "gray.700" : getEnvironment().color}>
                {getEnvironment().label}
              </Tag>
            </Center>
          )}
          {injectDebugEntityPreference && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="10px"
                minHeight={"1rem"}
                color={badgeFontColor}
                backgroundColor={debugBgColor}>
                DEBUG
              </Tag>
            </Center>
          )}
          {injectTestEntityPreference && (
            <Center>
              <Tag
                borderRadius={"3px"}
                px="5px"
                py="1px"
                width="100%"
                textAlign={"center"}
                fontSize="10px"
                minHeight={"1rem"}
                color={badgeFontColor}
                backgroundColor={testBgColor}>
                TESTING
              </Tag>
            </Center>
          )}
        </Stack>
      )}
    </Box>
  );
};
