import React, { useEffect, useMemo, useState } from "react";
import { useMenuConfig, useConfigMap, useUserPreference } from "hooks";
import sortBy from "lodash/sortBy";
import { SidebarNavigationMainMenu } from "./SidebarNavigationMainMenu";

export const SidebarNavigationMain = () => {
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", false);
  const menuItemsOrderPreference = useUserPreference("ui_menu_order") as string;
  const [isMenuLoaded, setIsMenuLoaded] = useState(false);
  const menuItemOrder = useMemo(
    () => (menuItemsOrderPreference && menuItemsOrderPreference.split(",")) || menuConfig.map((menuItem) => menuItem.id),
    [menuConfig, menuItemsOrderPreference]
  );

  useEffect(() => {
    if (menuConfig && menuConfig.length > 0) {
      setIsMenuLoaded(true);
    }
  }, [menuConfig]);

  const reorderedMenuConfig = useMemo(() => {
    if (isMenuLoaded) {
      const reorderedMenuConfig = sortBy(menuConfig, (menuItem) => {
        const index = menuItemOrder.indexOf(menuItem.id);
        return index !== -1 ? index : menuItemOrder.length;
      });

      return reorderedMenuConfig.filter(
        (projectConfig) => projectConfig.config.collectionType !== "home" && projectConfig.config.collectionType !== "due_diligence"
      );
    }

    return [];
  }, [isMenuLoaded, menuConfig, menuItemOrder]);

  return <SidebarNavigationMainMenu reorderedMenuConfig={reorderedMenuConfig} configMap={configMap} />;
};
