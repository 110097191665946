import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";

/**
 * Returns a boolean indicating whether a given API or state store is ready to be used.
 * @param {string} id - Conversation ID to query.
 */
export function useIsMainAppLoading() {
  const { loading: isProjectConfigsLoading, hasInitialSyncCompleted } = useSelector((state: RootState) => state.configMap, shallowEqual);
  const { isLoading: isLoadingEntitlements } = useSelector((state: RootState) => state.entitlement, shallowEqual);
  const { isLoading: isLoadingUserPreferences } = useSelector((state: RootState) => state.userPreference, shallowEqual);
  const { isLoading: isLoadingCollections, hasInitialSyncCompleted: hasCollectionSyncCompleted } = useSelector(
    (state: RootState) => state.collection,
    shallowEqual
  );

  if (hasInitialSyncCompleted && hasCollectionSyncCompleted) {
    return false;
  } else {
    return isProjectConfigsLoading || isLoadingEntitlements || isLoadingUserPreferences || isLoadingCollections;
  }
}
