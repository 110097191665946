import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Stack,
  Text,
  Center,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useButtonProps, useEntitlementKey, useUserPreference, useUserSubscriptionDetails } from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "screens/landing/components/popoverComponent/CommonModal";
import iconWord from "screens/common/static/misc/icon_word.svg";
import iconExcel from "screens/common/static/misc/icon_excel.svg";
import iconPdf from "screens/common/static/misc/icon_pdf.svg";
import iconPP from "screens/common/static/misc/icon_powerpoint.svg";
import iconTemplates from "screens/common/static/misc/icon_templates.svg";
import iconSnowflake from "screens/common/static/misc/icon_snowflake.svg";
import iconSqlServer from "screens/common/static/misc/icon_sql_server.svg";
import iconDatabricks from "screens/common/static/misc/icon_databricks.svg";
import type { TileSelectorProps } from "screens/landing/components/popoverComponent/TileSelector";
import { TileSelector } from "screens/landing/components/popoverComponent/TileSelector";
import { useRequestDefaultReport } from "screens/collection/components/useRequestDefaultReport";
import { useReportTemplatesList } from "hooks/useReportTemplates";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";

interface Props {
  collectionId: string;
  onClose: () => void;
  isOpen: boolean;
  title?: string;
}

const customReportTemplateNames = [
  "Stakeholders Template from CapTable",
  "Certificates Template from CapTable",
  "Awards Template from Cap Table",
  "Certificates Template from PDF",
  "Awards Template from PDF",
];

export const reportTypeTileSelectors = (currentPlan: string, hasCustomReports?: boolean) => [
  {
    label: "Summary Adobe PDF report using the system template",
    entityName: "outputFormat",
    value: "PDF",
    templateName: "Due Diligence Summary Report Template",
    image: iconPdf,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    tag: { label: "Summary", color: "green" },
    default: true,
  },
  {
    label: "Detailed Adobe PDF report using the system template",
    entityName: "outputFormat",
    value: "PDF",
    templateName: "Due Diligence Compliance Report Template",
    image: iconPdf,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: currentPlan === "business" ? false : true,
    tag: { label: "Detailed", color: "blue" },
  },
  {
    label: "Editable MS Word document using the system template",
    tooltip: currentPlan === "business" ? "" : "This feature is available on Upgrade",
    entityName: "outputFormat",
    value: "DOCX",
    templateName: "Due Diligence Compliance Report Template",
    image: iconWord,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: currentPlan === "business" ? false : true,
    tag: { label: "Detailed", color: "blue" },
  },
  {
    label: "Editable data spreadsheet using the system template",
    tooltip: "This feature is available on upgrade to Premium or Enterprise",
    entityName: "outputFormat",
    value: "XLSX",
    image: iconExcel,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Editable MS PowerPoint presentation using the system template",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconPP,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Custom file formats and custom templates",
    tooltip: hasCustomReports ? "" : "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "CUSTOM",
    image: iconTemplates,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: hasCustomReports ? false : true,
  },
];

export const cloudTypeTileSelectors = (): TileSelectorProps[] => [
  {
    label: "Push AI data to Snowflake data lake",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconSnowflake,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Push AI data to Databricks data lake",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconDatabricks,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Push AI data to SQL Server data base",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconSqlServer,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
];

export const ProjectReportPanelNewReportModal = ({ collectionId, onClose, isOpen, title = "Send AI Generated Report" }: Props) => {
  const { setRequestEntities } = useContext(ConversationContext);
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");
  const subscriptionInfo = useUserSubscriptionDetails();
  const hasCustomReports = useEntitlementKey("ui_enable_custom_reports");
  const [showCustomReportTiles, setShowCustomReportTiles] = useState(false);
  const [shouldRequestReport, setShouldRequestReport] = useState(false);
  const primaryButtonProps = useButtonProps("md", "primary");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(scrollContainerRef);

  const reportTemplates = useReportTemplatesList();
  const allReportTemplates = useMemo(() => {
    if (!reportTemplates) {
      return [];
    }
    return reportTemplates;
  }, [reportTemplates]);

  const filteredReportTemplates = useMemo(() => {
    if (!reportTemplates) {
      return [];
    }
    return reportTemplates.filter((template) => customReportTemplateNames.includes(template.name));
  }, [reportTemplates]);

  const customReportTileSelectorsMemo = useMemo(() => {
    return filteredReportTemplates.map((template) => {
      return {
        label: template.name,
        entityName: "report_template_id",
        value: template.id,
        image: iconExcel,
        imageDimensions: { width: "3.5rem", height: "3.5rem" },
        disabled: false,
      };
    });
  }, [filteredReportTemplates]);

  const [selectedReportTemplateId, setSelectedReportTemplateId] = useState<string | undefined>(undefined);
  const [selectedReportType, setSelectedReportType] = useState<string | undefined>(undefined);
  const [selectedOutputFormat, setSelectedOutputFormat] = useState<string | undefined>(
    userDefaultReportTemplateType ? String(userDefaultReportTemplateType).trim() : undefined
  );

  const reportTypeTileSelectorsMemo = useMemo(
    () => reportTypeTileSelectors(subscriptionInfo?.internalPlanName, hasCustomReports),
    [hasCustomReports, subscriptionInfo?.internalPlanName]
  );
  const cloudTypeTileSelectorsMemo = useMemo(() => cloudTypeTileSelectors(), []);

  const defaultReportFunction = useRequestDefaultReport({
    collectionId,
    selectedReportTemplate: selectedReportTemplateId,
    selectedOutputFormat: selectedOutputFormat,
    selectedReportType: selectedReportType,
  });

  const requestDefaultReport = useCallback(() => {
    return defaultReportFunction();
  }, [defaultReportFunction]);

  useEffect(() => {
    if (!isOpen) {
      setRequestEntities([]);
      setShouldRequestReport(false);
    }
  }, [isOpen, setRequestEntities]);

  const modalBorderColor = useColorModeValue("#81c34c", "gray.800");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <CommonModal
      minWidth={["90%", "50rem", "45rem"]}
      borderColor={modalBorderColor}
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Stack
          color={textColor}
          bgColor={modalBorderColor}
          direction={"row"}
          fontSize="md"
          textAlign="center"
          width="100%"
          height={"3rem"}
          justifyContent={"space-between"}
          px="1rem">
          <Center>
            <Text fontWeight={"semibold"} fontSize="lg">
              {title}
            </Text>
          </Center>
          <Center>
            <ModalCloseButton cursor="pointer" _hover={{}} />
          </Center>
        </Stack>
      }
      modalBody={
        <Stack
          bgColor={modalBorderColor}
          justifyContent="space-between"
          spacing="0"
          height="29.5rem"
          overflow={"auto"}
          ref={scrollContainerRef}
          css={scrollbarStyle}>
          <Tabs isLazy px="2rem" pt="1rem">
            <TabList>
              <Tab onClick={() => setShowCustomReportTiles(false)}>Documents</Tab>
              <Tab>Cloud</Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0" pb="0!important">
                {showCustomReportTiles ? (
                  <>
                    <Stack
                      pb="1rem"
                      direction="row"
                      alignItems="center"
                      onClick={() => setShowCustomReportTiles(false)}
                      spacing={0}
                      cursor="pointer">
                      <Icon as={ChevronLeftIcon} />
                      <Text fontSize="md">Back</Text>
                    </Stack>
                    <TileSelector
                      layout="grid"
                      tiles={customReportTileSelectorsMemo}
                      onSelectedTile={(tile) => {
                        setSelectedReportTemplateId(tile.value);
                        setSelectedReportType(tile.tag?.label.toLowerCase());
                        setSelectedOutputFormat("XLSX");
                        setShouldRequestReport(true);
                      }}
                    />
                  </>
                ) : (
                  <TileSelector
                    layout="grid"
                    tiles={reportTypeTileSelectorsMemo}
                    onSelectedTile={(tile) => {
                      if (tile.value === "CUSTOM") {
                        setShowCustomReportTiles(true);
                      } else {
                        setSelectedOutputFormat(tile.value);
                        setSelectedReportTemplateId(
                          tile.templateName ? allReportTemplates.find((report) => report.name === tile.templateName)?.id : undefined
                        );
                        setSelectedReportType(tile.tag?.label.toLowerCase());
                        setShouldRequestReport(true);
                      }
                    }}
                  />
                )}
              </TabPanel>
              <TabPanel pt="2rem" px="0">
                <TileSelector layout="grid" tiles={cloudTypeTileSelectorsMemo} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      }
      modalFooter={
        <Stack bgColor={modalBorderColor} direction="row" justifyContent={"flex-end"} width="100%" p="2rem">
          <Button
            className="ch-publish-report-button"
            {...primaryButtonProps}
            isDisabled={!selectedOutputFormat || (selectedOutputFormat === "CUSTOM" && !selectedReportTemplateId)}
            onClick={() => {
              if (shouldRequestReport && selectedOutputFormat && selectedReportTemplateId) {
                requestDefaultReport();
                onClose();
                setShouldRequestReport(false);
              }
            }}>
            Send Report
          </Button>
        </Stack>
      }
    />
  );
};
