import React from "react";
import { Center, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { TypingIndicator } from "screens/thread/components/cells/components";

interface LoadingMessageProps {
  message?: string;
}

export const LoadingMessage: React.FC<LoadingMessageProps> = ({ message = "Filtering tickers" }) => {
  const textColor = useColorModeValue("primary.darkGray", "gray.400");

  return (
    <Stack direction={"row"} spacing={3} height={"4.3rem"} px="1rem" width="100%" alignItems="left">
      <Center>
        <Text color={textColor} fontSize="sm">
          {message}
        </Text>
      </Center>
      <TypingIndicator size="small" />
    </Stack>
  );
};

export default LoadingMessage;
