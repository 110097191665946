import React, { useContext, useState, useCallback } from "react";
import { Box, Stack, useColorModeValue, Text, Flex, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import { useTileProps, useEntitlementKey, useUserPreference, useSidebarNavigation } from "hooks";
import { useCopyValue } from "hooks/useCopies";
import { SimpleMetric } from "../dashboard/SimpleMetric";
import { ComingSoonOverlay } from "screens/common/components/ComingSoonOverlay";
import QuadrantChart from "./chartComponents/QuadrantChart/QuadrantChart";
import { CollectionsFilterContext } from "./CollectionsFilterContext";
import { useCollectionsStocksContext } from "./CollectionsStocksProvider";
import { StockMarketChart } from "./chartComponents/StockMarketChart";
import { BuySellHoldChart } from "./chartComponents/BuySellHoldChart/BuySellHoldChart";
import { useSwipeable } from "react-swipeable";
import { generateQuadrantData } from "./chartComponents/data/quadrantData";
import { generateTradingValueData } from "./chartComponents/data/tradingValueData";
import { generateBuySellData } from "./chartComponents/data/buySellData";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";

const SLIDES_COUNT = 3;

export const ProjectMetrics = () => {
  const { filteredCollections, groupedCollections } = useContext(CollectionsFilterContext);
  const commonTileProps = useTileProps();
  const { getStockData } = useCollectionsStocksContext();
  const [currentIndex, setCurrentIndex] = useState(0);
  const quadrantHelpInfo = useCopyValue("copy_chart_quadrant_help");
  const buySellHoldHelpInfo = useCopyValue("copy_chart_buysellhold_help");
  const stockMarketHelpInfo = useCopyValue("copy_chart_stockmarket_help");
  const [quadrantHelpText, quadrantHelpUrl] = quadrantHelpInfo || [];
  const [buySellHoldHelpText, buySellHoldHelpUrl] = buySellHoldHelpInfo || [];
  const [stockMarketHelpText, stockMarketHelpUrl] = stockMarketHelpInfo || [];

  // Memoize breakpoint values
  const isMobile = useBreakpointValue({ base: true, md: false, lg: false }, { fallback: "md", ssr: false });
  const isWideScreen = useBreakpointValue({ base: false, md: false, lg: false, xl: true }, { fallback: "md", ssr: false });
  const TILEWIDTH = isMobile ? "100vw" : "33rem";
  const showProjectMetrics = useEntitlementKey("ui_project_metrics");
  const injectDebugEntityPreference = useUserPreference("ui_inject_debug_entity");
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();
  const { colorMode } = useColorMode();
  const tileBgColor = useColorModeValue("white", "#161B25");

  // Memoize slide handling
  const handleSlide = useCallback((direction: "prev" | "next") => {
    setCurrentIndex((prev) => {
      if (direction === "prev") return Math.max(prev - 1, 0);
      return Math.min(prev + 1, SLIDES_COUNT - 1);
    });
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide("next"),
    onSwipedRight: () => handleSlide("prev"),
    trackMouse: true,
  });

  // Debug logging effect
  React.useEffect(() => {
    if (!injectDebugEntityPreference) return;
  }, [filteredCollections, groupedCollections, injectDebugEntityPreference]);

  // Memoize data calculations
  const quadrantData = React.useMemo(() => generateQuadrantData(groupedCollections), [groupedCollections]);
  const tradingValueData = React.useMemo(
    () => generateTradingValueData(groupedCollections, getStockData),
    [getStockData, groupedCollections]
  );
  const buySellData = React.useMemo(() => generateBuySellData(groupedCollections, getStockData), [getStockData, groupedCollections]);

  // Memoize styles
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const arrowBgColor = useColorModeValue("white", "gray.600");
  const arrowBgColorHover = useColorModeValue("gray.100", "gray.500");

  const arrowStyles = React.useMemo(
    () =>
      ({
        cursor: "pointer",
        pos: "absolute",
        top: "50%",
        w: "auto",
        mt: "-22px",
        mx: "-12px",
        p: "1px 5px",
        bg: arrowBgColor,
        color: "gray.700",
        fontWeight: "bold",
        fontSize: "22px",
        transition: "0.6s ease",
        borderRadius: "5px",
        userSelect: "none",
        _hover: {
          bg: arrowBgColorHover,
        },
      } as const),
    [arrowBgColor, arrowBgColorHover]
  );

  const containerStyles = React.useMemo(
    () => ({
      position: "relative" as const,
      height: "100%",
      pt: isMobile ? "0" : "1rem",
      pb: isMobile ? "0" : "1rem",
      px: isMobile ? "0" : "1rem",
      bgColor,
      mb: "1rem",
      mx: "auto",
    }),
    [isMobile, bgColor]
  );

  return (
    <Box {...containerStyles} pt={isMobile ? "0" : "2.5rem"} mb={colorMode === "dark" ? "0" : "1rem"}>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
        position="relative"
        maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth + 30}px)`}>
        <Flex w="full" overflow="hidden" {...handlers}>
          <Stack
            direction="row"
            spacing="1rem"
            justify="center"
            align="center"
            minW="max-content"
            style={{
              transform: `translateX(calc(-${currentIndex} * (${TILEWIDTH} + 1rem)))`,
              transition: "transform 0.3s ease-in-out",
              margin: "0 auto",
            }}>
            {!showProjectMetrics && (
              <ComingSoonOverlay
                message="Upgrade to the Business Plan for access to the Portfolio Investment Ratings."
                height="100%"
                width="100%"
              />
            )}
            {quadrantData && (
              <Box
                {...commonTileProps}
                bgColor={tileBgColor}
                borderRadius={isMobile ? "none" : "lg"}
                width={TILEWIDTH}
                flexShrink={0}
                className={"ch-metric-chart-quadrant"}>
                <SimpleMetric
                  className="ch-chart-help-quadrant"
                  title="Portfolio Investment (Pi) Quadrant"
                  helpText={quadrantHelpText}
                  helpUrl={quadrantHelpUrl}>
                  <QuadrantChart data={quadrantData} xAxisLabel="Strength of Fundamentals" yAxisLabel="Strength of Market Perception" />
                </SimpleMetric>
              </Box>
            )}
            <Box {...commonTileProps} bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
              <SimpleMetric title="Trading Analysis" helpText={buySellHoldHelpText} helpUrl={buySellHoldHelpUrl}>
                <BuySellHoldChart data={buySellData} />
              </SimpleMetric>
            </Box>
            {tradingValueData && (
              <Box {...commonTileProps} bgColor={tileBgColor} borderRadius={isMobile ? "none" : "lg"} width={TILEWIDTH} flexShrink={0}>
                <SimpleMetric title="Fair Market Value vs Trading Price" helpText={stockMarketHelpText} helpUrl={stockMarketHelpUrl}>
                  <StockMarketChart data={tradingValueData} />
                </SimpleMetric>
              </Box>
            )}
          </Stack>
          <Box position="fixed" top="15rem" left={currentSidebarType === "hidden" || isMobile ? "12px" : sidebarWidth + 10}>
            {currentIndex > 0 && showProjectMetrics && (
              <Text className="ch-project-metrics-previous" {...arrowStyles} left="0" onClick={() => handleSlide("prev")}>
                &#10094;
              </Text>
            )}
          </Box>
          <Box position="fixed" top="15rem" right="12px">
            {currentIndex < (isMobile ? 3 : 2) && !isWideScreen && showProjectMetrics && (
              <Text className="ch-project-metrics-next" {...arrowStyles} right="0" onClick={() => handleSlide("next")}>
                &#10095;
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
