import { Box, useBreakpointValue, Stack, Collapse, useDisclosure, useColorModeValue, Center, SimpleGrid } from "@chakra-ui/react";
import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { TabTitle } from "screens/common/components/TabTitle";
import { DEFAULT_SIDEBAR_WIDTH, LandingTitle } from "screens/landing/components";
import {
  filterCollectionsByType,
  useCollections,
  useConfigMap,
  useGetConversationCompanyTickerValue,
  useIsInitialCollectionSyncCompleted,
  useIsMainAppLoading,
  useMenuConfig,
  useSidebarNavigation,
  useUserPreference,
} from "hooks";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { HomeDefaultTile } from "../project/projectLandingTileLayouts/HomeDefaultTile";
import { HomeRightPanel } from "./HomeRightPanel";
import { HomeRightPanelMenu } from "./HomeRightPanelMenu";
import { HomeSkeleton } from "./HomeSkeleton";
import { ScrollingTickers } from "screens/common/components/ScrollingTickers";
import groupBy from "lodash/groupBy";
import { HomePlaceholderTile } from "../project/projectLandingTileLayouts/HomePlaceholderTile";
import { placeholderCompanies } from "./PlaceholderCompanies";
import { ProjectNewInput } from "../project/ProjectNewInput";

export const HomeContent = () => {
  const { filteredProjectsIds, setFilteredProjectsIds } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { setHeaderText } = useContext(SettingsProviderContext);
  const { onConversationClose, isOnboardingModalOpen } = useConversationContext();
  const { setActionView } = useAddToCharliContext();
  const maxProjectTiles = useBreakpointValue([9, 9, 6, 6, 9]) as number;
  const { isOpen: showMetrics, onToggle: toggleMetrics } = useDisclosure();
  const {
    isOpen: showActivities,
    onToggle: toggleActivities,
    onClose: closeActivities,
    onOpen: openActivities,
  } = useDisclosure({ defaultIsOpen: true });
  const bgColor = useColorModeValue("gray.200", "gray.800");
  const bgSectionsColor = useColorModeValue("white", "gray.800");
  const collections = useCollections();
  const configMap = useConfigMap();
  const menuConfig = useMenuConfig(configMap, "all", false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isInitialCollectionSyncCompleted = useIsInitialCollectionSyncCompleted();
  const isMainAppLoading = useIsMainAppLoading();
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();

  const filterCollectionsByPortfolio = useCallback(
    (isFiltered: boolean) => {
      const portfolioCategory = menuConfig.filter((menuItem) => !!menuItem.config.portfolioProjectType);
      const portfolioCategoryTypes = portfolioCategory.map((menuItem) => menuItem.config.collectionType);

      if (!collections) {
        return [];
      }

      return collections.filter((collection) =>
        isFiltered
          ? portfolioCategoryTypes.includes(collection.collectionType)
          : !portfolioCategoryTypes.includes(collection.collectionType)
      );
    },
    [collections, menuConfig]
  );

  useEffect(() => {
    onConversationClose();
    setActionView(undefined);
    if (isMobile && showActivities) {
      closeActivities();
    } else openActivities();
    setHeaderText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    const newFilteredProjects = filterCollectionsByPortfolio(false);
    setFilteredProjectsIds(newFilteredProjects.map((project) => project.id));
  }, [filterCollectionsByPortfolio, setFilteredProjectsIds]);

  const filteredCollections = useMemo(() => filterCollectionsByType(collections, "due_diligence"), [collections]);
  const filterCollectionGroups = useMemo(() => {
    const groupedCollections = groupBy(filteredCollections, (collection) => collection.projectGroupId || collection.id);
    return Object.values(groupedCollections).map((group) => {
      const groupCount = group.length;
      const groupName = group[0].name;
      const groupType = group[0].collectionType;

      return {
        id: group[0].id,
        groupName,
        groupType,
        groupCount,
      };
    });
  }, [filteredCollections]);

  const allProjectConversationIds = useMemo(
    () => filteredCollections.map((collection) => collection.conversationId).filter((id): id is string => id !== undefined),
    [filteredCollections]
  );

  const getConversationTicker = useGetConversationCompanyTickerValue();
  const filteredPlaceholderCompanies = useMemo(() => {
    const stockTickers = new Set(filteredCollections.map((collection) => collection.ticker && collection.ticker.toLowerCase()));
    const conversationTickerValues = allProjectConversationIds.flatMap((conversationId) => {
      const maybeValue = getConversationTicker(conversationId);

      if (maybeValue) {
        return [maybeValue];
      } else {
        return [];
      }
    });
    const allTickers = new Set([...stockTickers, ...conversationTickerValues.map((ticker) => ticker.toLowerCase())]);

    return placeholderCompanies
      .filter((company) => !allTickers.has(company.ticker.toLowerCase()))
      .slice(0, Math.max(0, maxProjectTiles - Math.min(filterCollectionGroups.length, maxProjectTiles)));
  }, [filteredCollections, allProjectConversationIds, maxProjectTiles, filterCollectionGroups.length, getConversationTicker]);

  if (isMainAppLoading || !isInitialCollectionSyncCompleted) {
    return <HomeSkeleton />;
  }
  if (!filteredProjectsIds || isOnboardingModalOpen) {
    return <HomeSkeleton />;
  }

  return (
    <>
      <Box height="100%" mb="2.5rem" overflow={"auto"}>
        <Center pt="1rem" height="3.8rem">
          <ScrollingTickers />
        </Center>
        <Center
          height={isMobile ? "15rem" : "21.6rem"}
          width="100%"
          maxWidth={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth}px)`}
          px={isMobile ? "1rem" : "0"}>
          <ProjectNewInput collectionType="due_diligence" />
        </Center>
        <Box bgColor={bgColor} height=".5rem" width="100%" />
        <Stack direction="row" justifyContent="space-between" bgColor={bgSectionsColor} spacing="0" height="100%">
          <Box height="100%" width="100%" className="ch-dashboard-recent-projects" bgColor={bgSectionsColor}>
            <Stack direction="row" px={isMobile ? ".5rem" : "1rem"} pt={isMobile ? ".5rem" : "1rem"} pb=".7rem">
              <LandingTitle text="Recent AI Analysis Projects" underline color="primary.default" />
            </Stack>
            <SimpleGrid spacing="1rem" columns={[1, 1, 1, 2, 3]} py="1rem" pl={isMobile ? "5px" : "1rem"} pr={isMobile ? "0" : "1rem"}>
              {filterCollectionGroups.length > 0 &&
                filterCollectionGroups
                  .slice(0, maxProjectTiles)
                  .map((project) => (
                    <HomeDefaultTile key={project.id} collectionId={project.id} projectGroupCount={project.groupCount - 1} />
                  ))}
              {filteredPlaceholderCompanies.map((company) => (
                <HomePlaceholderTile
                  key={company.ticker}
                  logo={company.logo}
                  companyName={company.companyName}
                  ticker={company.ticker}
                  exchange={company.exchange}
                  securityType={company.securityType}
                  overview={company.overview}
                />
              ))}
            </SimpleGrid>
          </Box>
          {!isMobile && (
            <>
              <HomeRightPanelMenu
                toggleActivities={toggleActivities}
                showActivities={showActivities}
                toggleMetrics={toggleMetrics}
                showMetrics={showMetrics}
                setCurrentSlide={(slide) => {
                  setCurrentSlide(slide);
                  openActivities();
                }}
              />
              <Collapse className="ch-home-activities-section" animateOpacity in={showActivities} style={{ height: "100%" }}>
                <HomeRightPanel currentSlide={currentSlide} />
              </Collapse>
            </>
          )}
        </Stack>
      </Box>
      <TabTitle title="Charli > Home" />
    </>
  );
};
