import type { SuggestedQuestions } from "api/suggestions";
import { useCallback, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { Source } from "types/config";
import { getTickersQuery } from "api/tickers";
import type { TickerType } from "api/tickers/models/TickerType";
import orderBy from "lodash/orderBy";

export const qaSourcesMap: Record<string, { label: string; value: Source }> = {
  tickers: {
    label: "Tickers list",
    value: "tickers",
  },
  questions: {
    label: "Questions list",
    value: "questions",
  },
};

interface QueryParams {
  limit?: number;
  query?: string;
  type?: TickerType | TickerType[];
  exchange?: string | string[];
  excludedType?: TickerType | TickerType[];
  excludedExchanges?: string | string[];
}

export const useQASourceListFunction = (source: Source | undefined): ((params: QueryParams) => Promise<SuggestedQuestions>) => {
  const questionsData = useSelector((state: RootState) => state.suggestedQuestions.questionData, shallowEqual);

  const getTickers = useCallback(async (params: QueryParams) => {
    try {
      const { limit, query, type, exchange, excludedType, excludedExchanges } = params;

      const data = await getTickersQuery({
        limit,
        query,
        type,
        exchange,
        excludedType,
        excludedExchanges,
      });

      const sortedData = orderBy(data, [(tickerData) => tickerData.symbol.toLowerCase() === query?.toLocaleLowerCase()], ["desc"]);

      return sortedData.map((tickerData) => ({
        question: `${tickerData.name} | (${tickerData.symbol})`,
        matchFilter: [tickerData.symbol.toLowerCase(), tickerData.name.toLowerCase()],
        focus: tickerData.exchange,
        type: tickerData.type,
        status: tickerData.status,
      }));
    } catch (error) {
      console.error("Error fetching tickers:", error);
      return [];
    }
  }, []);

  const getQuestions = useCallback(
    async (_params: QueryParams) => {
      try {
        return questionsData;
      } catch (error) {
        console.error("Error fetching questions:", error);
        return [];
      }
    },
    [questionsData]
  );

  return useMemo(() => {
    if (source === "tickers") {
      return getTickers;
    } else if (source === "questions") {
      return getQuestions;
    } else {
      return () => Promise.resolve([]);
    }
  }, [getQuestions, getTickers, source]);
};
