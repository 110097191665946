import { Record, String } from "runtypes";
import type { Static } from "runtypes";

export const Question = Record({
  id: String,
  question: String,
  focus: String,
});

export type Question = Static<typeof Question>;
