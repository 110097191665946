import React from "react";
import { Box, Text, Link, Stack, useBreakpointValue, useColorModeValue, Image } from "@chakra-ui/react";
import logo from "screens/common/static/logos/charli-logo-full.svg";

interface RegistrationBannerProps {
  isLogin?: boolean;
  disableLogin?: boolean;
}

export const RegistrationBanner: React.FC<RegistrationBannerProps> = ({ isLogin = false, disableLogin }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  // Color mode values
  const bgColor = useColorModeValue("#fbfbfb", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const disabledColor = useColorModeValue("gray.400", "gray.600");
  const linkColor = useColorModeValue("primary.600", "primary.300");
  const linkHoverColor = useColorModeValue("primary.700", "primary.400");

  return (
    <Box
      width={isMobile ? "100%" : "1080px"}
      height="43px"
      background={bgColor}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      paddingRight="0"
      fontSize="sm"
      fontWeight="medium">
      <Stack
        width={isMobile ? "100%" : "unset"}
        direction="row"
        spacing="0"
        justifyContent="space-between"
        alignItems={"center"}
        paddingX={isMobile ? "0" : "1rem"}>
        {isMobile && (
          <Image src={logo} alt="Charli AI" w="150px" cursor="pointer" onClick={() => window.open("https://charliai.com", "_blank")} />
        )}
        <Stack
          width={isMobile ? "unset" : "100%"}
          fontSize="0.8rem"
          fontWeight="500"
          color={textColor}
          direction="row"
          spacing={isMobile ? "1rem" : "2rem"}
          alignItems="flex-end"
          fontFamily="Montserrat">
          <Text>
            <Link
              href="https://support.charli.ai/"
              target="_blank"
              rel="noopener noreferrer"
              color={linkColor}
              _hover={{ color: linkHoverColor }}>
              Support
            </Link>
          </Text>
          <Text>
            {disableLogin ? (
              <Text color={disabledColor} as="span" cursor={"not-allowed"}>
                {isLogin ? "Register" : "Log In"}
              </Text>
            ) : (
              <Link href={isLogin ? "/register" : "/login"} color={linkColor} _hover={{ color: linkHoverColor }}>
                {isLogin ? "Register" : "Log In"}
              </Link>
            )}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
