import { startTransition, useCallback, useContext, useDeferredValue, useEffect, useMemo, useReducer, useState } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useEntitlementKey, useLatestCollectionWorkflowId, useProjectParams, useUserPreference } from "hooks";
import { useCopyValue } from "hooks/useCopies";
import type { SuggestedQuestions } from "api/suggestions";
import { useAvailableCommands, useCurrentCollectionQuestions } from "screens/collection/views/AutoCompleteInputUtils";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useDebouncedCallback } from "use-debounce";
import { useQASourceListFunction } from "hooks/useQASourceList";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import { useWorkflowKey } from "hooks/useWorkflows";
import type { TickerType } from "api/tickers/models/TickerType";

interface Props {
  questionText?: string;
  setQuestionText?: (questionText: string) => void;
  maxSuggestions?: number;
}

const initialState = {
  currentSuggestionListType: undefined as "commands" | "entities" | "questions" | "tickers" | undefined,
  suggestedEntities: [] as SuggestedQuestions,
  recentTickerResults: [] as SuggestedQuestions,
  lastTickerQuery: null as string | null,
  lastActiveFilter: null as string | null,
  isLoading: false,
};

type Action =
  | { type: "SET_SUGGESTION_LIST_TYPE"; payload: typeof initialState.currentSuggestionListType }
  | { type: "SET_SUGGESTED_ENTITIES"; payload: SuggestedQuestions }
  | { type: "SET_TICKERS_RESULTS"; payload: SuggestedQuestions }
  | { type: "SET_TICKER_QUERY"; payload: string | null }
  | { type: "SET_ACTIVE_FILTER"; payload: string | null }
  | { type: "SET_LOADING"; payload: boolean };

const reducer = (state: typeof initialState, action: Action): typeof initialState => {
  switch (action.type) {
    case "SET_SUGGESTION_LIST_TYPE":
      return { ...state, currentSuggestionListType: action.payload };
    case "SET_SUGGESTED_ENTITIES":
      return { ...state, suggestedEntities: action.payload };
    case "SET_TICKERS_RESULTS":
      return { ...state, recentTickerResults: action.payload };
    case "SET_TICKER_QUERY":
      return { ...state, lastTickerQuery: action.payload };
    case "SET_ACTIVE_FILTER":
      return { ...state, lastActiveFilter: action.payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

// Pure function for checking ticker entitlement
export const isTickerEntitled = (
  type: string | undefined,
  exchange: string | undefined,
  {
    showPrivateTypes,
    showRestrictedTypes,
    showRestrictedExchanges,
    restrictedTypes,
    restrictedExchanges,
  }: {
    showPrivateTypes: boolean;
    showRestrictedTypes: boolean;
    showRestrictedExchanges: boolean;
    restrictedTypes: string[];
    restrictedExchanges: string[];
  }
): boolean => {
  // Handle private types
  if (exchange?.toUpperCase().includes("PRIVATE") && !showPrivateTypes) {
    return false;
  }

  // Handle restricted types
  if (type && !showRestrictedTypes && restrictedTypes.includes(type.toLowerCase())) {
    return false;
  }

  // Handle restricted exchanges
  if (exchange && !showRestrictedExchanges && restrictedExchanges.includes(exchange.toUpperCase())) {
    return false;
  }

  return true;
};

// Hook to get entitlement values
export const useTickerEntitlementConfig = () => {
  const showPrivateTypes = useEntitlementKey("ui_show_private_tickers");
  const showRestrictedTypes = useEntitlementKey("ui_show_additional_security_types");
  const showRestrictedExchanges = useEntitlementKey("ui_show_additional_exchanges");

  const restrictedTypes = useCopyValue("copy_ticker_excluded_types") as string[];
  const restrictedExchanges = useCopyValue("copy_ticker_excluded_exchanges") as string[];

  return useMemo(
    () => ({
      showPrivateTypes,
      showRestrictedTypes,
      showRestrictedExchanges,
      restrictedTypes,
      restrictedExchanges,
    }),
    [showPrivateTypes, showRestrictedTypes, showRestrictedExchanges, restrictedTypes, restrictedExchanges]
  );
};

export const useConversationDialogSuggestionLists = ({ questionText, setQuestionText, maxSuggestions = 30 }: Props) => {
  const { projectId } = useProjectParams();
  const { isConversationOpen, setInitialQuestionText, setInitialQuestionFocus } = useConversationContext();
  const { files } = useContext(InputBarDropzoneContext);
  const [state, dispatchState] = useReducer(reducer, initialState);
  const currentWorkflowId = useLatestCollectionWorkflowId(projectId);
  const currentWorkflowStatus = useWorkflowKey(currentWorkflowId, "status");
  const collectionQuestions = useCollectionKey(projectId, "questions");

  // COMMANDLINE SUGGESTIONS
  const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");
  const hideCommandLineButtonUserPref = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const { availableCommandsMemo: suggestedCommands, filterCommandEntitiesMemo: originalSuggestedEntities } = useAvailableCommands(
    questionText || "",
    hideCommandLineButtonUserPref || !hasCommandLineEntitlement
  );
  const [currentSuggestionList, setCurrentSuggestionList] = useState<SuggestedQuestions>([]);

  useEffect(() => {
    if (!questionText) {
      dispatchState({ type: "SET_SUGGESTED_ENTITIES", payload: originalSuggestedEntities });
      return;
    }

    startTransition(() => {
      const segments = questionText.split(" ");
      const entitySegments = segments.filter((segment) => segment.startsWith(">")).map((segment) => segment.trim().toLowerCase());
      const lastSegment = entitySegments[entitySegments.length - 1] || "";
      const removeEndSegment = segments.slice(0, -1).join(" ");

      const updatedSuggestions = originalSuggestedEntities.reduce<SuggestedQuestions>((acc, entity) => {
        const entityLabel = (entity.label || entity.question).toLowerCase();

        if (!entitySegments.includes(entityLabel) && entityLabel.includes(lastSegment)) {
          acc.push({
            ...entity,
            question: `${removeEndSegment} ${entity.question}`.trim(),
            label: (entity.label || entity.question).trim(),
          });
        }

        return acc;
      }, []);
      dispatchState({ type: "SET_SUGGESTED_ENTITIES", payload: updatedSuggestions });
    });
  }, [questionText, originalSuggestedEntities]);

  // QUESTION SUGGESTIONS
  const getSourceQuestions = useQASourceListFunction("questions");
  const [sourcesList, setSourcesList] = useState<SuggestedQuestions>([]);
  useEffect(() => {
    dispatchState({ type: "SET_LOADING", payload: true });
    getSourceQuestions({ limit: maxSuggestions, ...(questionText && { questionText }) })
      .then((response) => setSourcesList(response))
      .catch(() => {
        dispatchState({ type: "SET_LOADING", payload: false });
      })
      .finally(() => dispatchState({ type: "SET_LOADING", payload: false }));
  }, [getSourceQuestions, maxSuggestions, questionText]);
  const { filterQuestionsByFocusMemo: suggestedQuestions } = useCurrentCollectionQuestions(sourcesList, projectId);

  // QUESTION FOCUS
  const { answerFocusOptions, selectedSourceWeightsIndex } = useAnswerFocusOptions("due_diligence");
  const selectedFocus = useMemo(() => {
    return answerFocusOptions ? answerFocusOptions[selectedSourceWeightsIndex]?.key : undefined;
  }, [answerFocusOptions, selectedSourceWeightsIndex]);

  // TICKER SUGGESTIONS
  const getSourceTickers = useQASourceListFunction("tickers");
  const tickerTypes = useCopyValue("copy_ticker_type_options") as string[];
  const uniqueTypes = useMemo(() => tickerTypes, [tickerTypes]);

  const tickerExchanges = useCopyValue("copy_ticker_exchange_options") as string[];
  const uniqueExchanges = useMemo(() => tickerExchanges, [tickerExchanges]);

  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const tickerFilterCallback = useCallback(
    (query?: string, additionalParams?: { type?: TickerType; exchange?: string[] }) => {
      // Only fetch if query or filter hasn't changed
      // For filter comparison, use the active filter value directly
      if (query === state.lastTickerQuery && activeFilter === state.lastActiveFilter) {
        return;
      }

      dispatchState({ type: "SET_LOADING", payload: true });

      const params = {
        limit: maxSuggestions,
        ...(query && { query }),
        ...(additionalParams?.type && { type: additionalParams.type }),
        ...(additionalParams?.exchange && { exchange: additionalParams.exchange }),
      };

      getSourceTickers(params)
        .then((response) => {
          dispatchState({ type: "SET_LOADING", payload: false });
          dispatchState({ type: "SET_TICKERS_RESULTS", payload: response });
          dispatchState({ type: "SET_TICKER_QUERY", payload: query || null });
          dispatchState({ type: "SET_ACTIVE_FILTER", payload: activeFilter || null });
        })
        .catch(() => {
          dispatchState({ type: "SET_LOADING", payload: false });
        });
    },
    [state, activeFilter, maxSuggestions, getSourceTickers]
  );

  const debouncedTickerFilter = useDebouncedCallback(tickerFilterCallback, 150);

  const suggestedTickers = useMemo(() => {
    return state.recentTickerResults;
  }, [state.recentTickerResults]);

  // Helper function to get ticker filter parameters
  const getTickerFilterParams = useCallback(
    (filter: string | null) => {
      if (!filter) return {};

      const isType = uniqueTypes?.includes(filter) && filter.toUpperCase() !== "ALL";
      const typeValue = isType ? filter : undefined;
      const activeFilterArray = filter ? uniqueExchanges.filter((exchange) => exchange.toUpperCase().includes(filter.toUpperCase())) : null;
      const exchangeValue = !isType && activeFilterArray ? activeFilterArray : undefined;

      return {
        type: typeValue as TickerType,
        exchange: exchangeValue,
      };
    },
    [uniqueTypes, uniqueExchanges]
  );

  const handleSetActiveFilter = useCallback(
    (filter: string | null) => {
      setActiveFilter(filter);
      const query = questionText;
      debouncedTickerFilter(query, getTickerFilterParams(filter));
    },
    [questionText, debouncedTickerFilter, getTickerFilterParams]
  );

  const deferredQuestionText = useDeferredValue(questionText);

  useEffect(() => {
    if (state.currentSuggestionListType !== "tickers" || !deferredQuestionText) return;

    debouncedTickerFilter(deferredQuestionText, getTickerFilterParams(activeFilter));
  }, [debouncedTickerFilter, deferredQuestionText, state.currentSuggestionListType, activeFilter, getTickerFilterParams]);

  const isValidTicker = useCallback(
    (query: string) => {
      if (query !== state.lastTickerQuery) {
        return true;
      }
      return state.recentTickerResults.some((suggestedTicker) => suggestedTicker.matchFilter?.includes(query.trim().toLowerCase()));
    },
    [state.recentTickerResults, state.lastTickerQuery]
  );

  useEffect(() => {
    startTransition(() => {
      const determineListType = () => {
        if (currentWorkflowStatus === "in_progress" && !hasCommandLineEntitlement) {
          return undefined;
        }

        if (((questionText && questionText.startsWith("/")) || files.length > 0) && isConversationOpen) {
          return questionText?.includes(" ") ? "entities" : "commands";
        } else {
          return projectId ? "questions" : "tickers";
        }
      };

      const listType = determineListType();
      dispatchState({ type: "SET_SUGGESTION_LIST_TYPE", payload: listType });

      const listTypeMap = {
        disabled: [],
        entities: state.suggestedEntities,
        commands: suggestedCommands,
        questions: suggestedQuestions,
        tickers: suggestedTickers,
      };

      setCurrentSuggestionList(listType ? listTypeMap[listType] : []);
    });
  }, [
    currentWorkflowStatus,
    deferredQuestionText,
    files.length,
    hasCommandLineEntitlement,
    isConversationOpen,
    projectId,
    questionText,
    state.suggestedEntities,
    suggestedCommands,
    suggestedQuestions,
    suggestedTickers,
  ]);

  const isInputDisabled = useMemo(() => {
    if (hasCommandLineEntitlement) return false;
    if (projectId && collectionQuestions?.length === 0) return true;

    return currentWorkflowStatus === "in_progress";
  }, [hasCommandLineEntitlement, projectId, collectionQuestions?.length, currentWorkflowStatus]);

  const onResetQuestion = useCallback(() => {
    setQuestionText && setQuestionText("");
    setInitialQuestionText("");
    setInitialQuestionFocus(undefined);
  }, [setInitialQuestionFocus, setInitialQuestionText, setQuestionText]);

  return useMemo(
    () => ({
      debouncedTickerFilter,
      isValidTicker,
      isLoading: state.isLoading,
      currentSuggestionList,
      currentSuggestionListType: state.currentSuggestionListType,
      isInputDisabled,
      selectedFocus,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      suggestedEntities: state.suggestedEntities,
      suggestedCommands,
      suggestedQuestions,
      suggestedTickers,
      activeFilter,
      setActiveFilter: handleSetActiveFilter,
    }),
    [
      debouncedTickerFilter,
      isValidTicker,
      state.isLoading,
      state.currentSuggestionListType,
      state.suggestedEntities,
      currentSuggestionList,
      isInputDisabled,
      selectedFocus,
      answerFocusOptions,
      selectedSourceWeightsIndex,
      onResetQuestion,
      suggestedCommands,
      suggestedQuestions,
      suggestedTickers,
      activeFilter,
      handleSetActiveFilter,
    ]
  );
};
