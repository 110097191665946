import { Stack, useColorModeValue, Box, useBreakpointValue } from "@chakra-ui/react";
import { useConfigMap, useGetViewConfig, useMenuConfig, useProjectConfigEntitlements, useUserPreference } from "hooks";
import React, { useEffect, useState, useRef } from "react";
import { SidebarNavigationMain } from "./SidebarNavigationMain";
import { HelpMenu } from "./HelpMenu";
import { SidebarAdminButton } from "./SidebarAdminButton";
import { useDebouncedCallback } from "use-debounce";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useDispatch } from "react-redux";
import { SidebarNavigationLogos } from "./SidebarNavigationLogo";
import { useCustomScrollbar } from "hooks/useCustomScrollbar";
import OnboardingVideoPopover from "./popoverComponent/OnboardingVideoPopover";
import OnboardingVideo from "./OnboardingVideo";
import { getTypeFromRoute, getTypeIcon, getViewConfig } from "configs/configMap";
import { AiOutlineHome } from "react-icons/ai";
import { SidebarButton } from "./SidebarButton";

export const SIDEBAR_PADDING = "1rem";

const MIN_SIDEBAR_WIDTH = 208;
const MAX_SIDEBAR_WIDTH = 500;
export const DEFAULT_SIDEBAR_WIDTH = 208;

export const SidebarNavigation = () => {
  const bgColor = useColorModeValue("charli.lightBlue", "gray.900");
  const userPrefSidebarWidth = useUserPreference("ui_sidebar_width") as number;
  const showOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const [width, setWidth] = useState(userPrefSidebarWidth || DEFAULT_SIDEBAR_WIDTH);
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const bgColorResizer = useColorModeValue("gray.300", "gray.900");
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollbarStyle } = useCustomScrollbar(scrollContainerRef, { width: "1px", barTransparency: 0.1 });
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const videoURL = homeOnboardingSteps && homeOnboardingSteps[0].url ? homeOnboardingSteps[0].url : "";

  useEffect(() => {
    if (userPrefSidebarWidth) {
      setWidth(userPrefSidebarWidth);
    }
  }, [userPrefSidebarWidth]);

  const debouncedUpdateWidth = useDebouncedCallback((newWidth: number) => {
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
  }, 150);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = Math.min(Math.max(e.clientX, MIN_SIDEBAR_WIDTH), MAX_SIDEBAR_WIDTH);
      setWidth(newWidth);

      debouncedUpdateWidth(newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      debouncedUpdateWidth.flush();
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleResetWidth = () => {
    const newWidth = DEFAULT_SIDEBAR_WIDTH;
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_sidebar_width", value: newWidth }));
    setWidth(newWidth);
  };

  const menuConfig = useMenuConfig(configMap, "all", false);
  const homeMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "home");
  const dueDiligenceMenuItem = menuConfig.find((menuItem) => menuItem.config.collectionType === "due_diligence");
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();

  return (
    <Stack
      gap="0"
      mt="0!important"
      direction="row"
      backgroundColor={bgColor}
      width={["100vw", "100%", "100%", `${width}px`, `${width}px`]}
      justifyContent="space-between"
      height="100vh"
      ref={containerRef}
      zIndex={1}>
      <Stack className="ch-sidebar-inner" height="100vh" width="100%" overflow="hidden">
        <SidebarNavigationLogos />
        <Stack gap="1rem" width="100%" direction="column" flex={1} overflow="hidden" pl="1rem" pb={isMobile ? "5rem" : "1rem"}>
          <Stack width="100%" flex={1} minHeight={0}>
            <Stack spacing={"1rem"} width="100%" pt="1rem" pr=".5rem">
              <SidebarButton
                key={`sidebar-nav-home`}
                text={homeMenuItem?.config.title}
                icon={AiOutlineHome}
                screen={"home"}
                cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(homeMenuItem?.config.route)}`]}
              />
              {hasDueDiligence && (
                <SidebarButton
                  key={`sidebar-nav-dd`}
                  text={dueDiligenceMenuItem?.config.title}
                  icon={getTypeIcon(getViewConfig("icon", dueDiligenceMenuItem?.id || "", configMap))}
                  screen={getViewConfig("route", dueDiligenceMenuItem?.id || "", configMap)}
                  cssClasses={[`ch-sidebar-nav-${getTypeFromRoute(dueDiligenceMenuItem?.config.route)}`]}
                />
              )}
            </Stack>
            <Stack flex={1} minHeight={0} overflow="auto" ref={scrollContainerRef} css={scrollbarStyle} width="100%">
              <SidebarNavigationMain />
            </Stack>
          </Stack>
          <Stack width="100%" spacing="1rem">
            {!isMobile && showOnboardingVideos && (
              <Box className="ch-sidebar-onboarding-popover" position="relative">
                <OnboardingVideo videoURL={videoURL} />
              </Box>
            )}
            <SidebarAdminButton />
            <HelpMenu />
          </Stack>
        </Stack>
      </Stack>
      {!isMobile && <OnboardingVideoPopover />}
      <Box
        height="100%"
        width="3px"
        cursor="ew-resize"
        backgroundColor="transparent"
        onMouseDown={handleMouseDown}
        onDoubleClick={handleResetWidth}
        _hover={{ backgroundColor: bgColorResizer }}
      />
    </Stack>
  );
};
