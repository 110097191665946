import React from "react";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Table,
  Tbody,
  Tr,
  Td,
  Center,
  Tooltip,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails } from "hooks";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { UpgradePlanButton } from "../subscription/SubscriptionUpgrade";

export const Profile = () => {
  const { id, fullName, firstName, lastName, email, avatarUrl, isProfileUpdatePending, updateUser, organizations } = useUserProfile();
  const secondaryButtonStyle = useButtonProps("sm", "secondary");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const subscriptionInfo = useUserSubscriptionDetails();
  const projectUsage = useFeatureUsage("create_due_diligence_project");
  const answerUsage = useFeatureUsage("generate_answer");
  const [showTooltipProjects, setShowTooltipProjects] = React.useState(false);
  const [showTooltipQuestions, setShowTooltipQuestions] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { colorMode } = useColorMode();
  const colorModeShade = colorMode === "dark" ? "900" : "200";

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<{
    firstName: string;
    lastName: string;
  }>({
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
    },
    mode: "onChange",
  });

  const watchedFields = watch();
  const hasChanged = watchedFields.firstName !== (firstName || "") || watchedFields.lastName !== (lastName || "");

  const onSubmit = useCallback(
    (fields: { firstName: string; lastName: string }) => {
      updateUser(fields);
    },
    [updateUser]
  );

  const { ref: firstNameRef, ...firstNameRest } = register("firstName", { required: true });
  const { ref: lastNameRef, ...lastNameRes } = register("lastName", { required: true });

  return (
    <Flex width="100%" flexDirection="column" justify="space-between" mb="1rem">
      <Stack direction="row" width="100%" spacing="2rem">
        {!isMobile && <Avatar name={fullName} src={avatarUrl} boxSize="4.5rem" />}
        <Stack spacing="0" width="100%">
          <Stack spacing="1.5rem" width="100%" pb="1.5rem">
            <Stack direction="row" spacing="2rem">
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel>First name</FormLabel>
                <Input
                  {...firstNameRest}
                  id="firstName"
                  placeholder="First name"
                  ref={firstNameRef}
                  borderColor={errors.firstName ? "red.500" : borderColor}
                />
                {errors.firstName && (
                  <FormErrorMessage fontSize="xs" mt="0.3rem">
                    First name cannot be blank.
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel>Last name</FormLabel>
                <Input
                  {...lastNameRes}
                  id="lastName"
                  placeholder="Last name"
                  ref={lastNameRef}
                  borderColor={errors.lastName ? "red.500" : borderColor}
                />
                {errors.lastName && (
                  <FormErrorMessage fontSize="xs" mt="0.3rem">
                    Last name cannot be blank.
                  </FormErrorMessage>
                )}
              </FormControl>
            </Stack>
            <Stack direction="row" spacing="2rem">
              <FormControl id="email-address">
                <FormLabel>Email address</FormLabel>
                <Input placeholder="Email address" value={email} isDisabled />
              </FormControl>
              <FormControl id="charli-id">
                <FormLabel>Charli ID</FormLabel>
                <Input placeholder="Charli ID" value={id} isDisabled />
              </FormControl>
            </Stack>
            <Stack direction="row" width="100%" justify="space-between">
              <Button
                className="ch-settings-save-button"
                {...secondaryButtonStyle}
                onClick={handleSubmit(onSubmit)}
                isLoading={isProfileUpdatePending}
                isDisabled={!hasChanged || !isValid}>
                Update Profile
              </Button>
            </Stack>
          </Stack>
          <Stack spacing="1.5rem" width="100%">
            <Divider />
            <Stack spacing="1rem">
              <Stack direction="row" spacing="2rem" width="100%" justifyContent={"space-between"}>
                <FormLabel>Subscription Details</FormLabel>
                <UpgradePlanButton buttonSize="sm" />
              </Stack>
              <Table variant="simple" size="sm">
                <Tbody>
                  <Tr>
                    <Td paddingInlineStart={0}>Current Subscription</Td>
                    <Td paddingInlineStart={0} className="ch-subscription-plan">
                      {subscriptionInfo.plan}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Next Billing Date</Td>
                    <Td paddingInlineStart={0} className="ch-subscription-billing">
                      {subscriptionInfo.nextBillingDate
                        ? new Intl.DateTimeFormat(undefined, {
                            dateStyle: "long",
                            timeZone: "UTC",
                          }).format(new Date(subscriptionInfo.nextBillingDate))
                        : "N/A"}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Status</Td>
                    <Td paddingInlineStart={0}>
                      <Badge className="ch-subscription-status" colorScheme={subscriptionInfo.status === "active" ? "green" : "blue"}>
                        {subscriptionInfo.status}
                      </Badge>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Number of Analysis Remaining</Td>
                    <Td paddingInlineStart={0}>
                      <Center>
                        <Slider
                          width="100%"
                          isReadOnly
                          id="slider"
                          value={projectUsage ? projectUsage.used : 0}
                          min={0}
                          max={projectUsage && projectUsage.allowed ? projectUsage.allowed : 0}
                          onMouseEnter={() => setShowTooltipProjects(true)}
                          onMouseLeave={() => setShowTooltipProjects(false)}>
                          <SliderTrack height="10px" borderRadius={"full"}>
                            <SliderFilledTrack
                              bg={
                                projectUsage && Math.min((projectUsage.used / projectUsage.allowed) * 100, 100) < 90
                                  ? `teal.${colorModeShade}`
                                  : `orange.${colorModeShade}`
                              }
                            />
                          </SliderTrack>
                          <Tooltip
                            hasArrow
                            placement="top"
                            isOpen={showTooltipProjects}
                            label={`You have used ${projectUsage?.used} of ${projectUsage?.allowed} projects`}>
                            <SliderThumb />
                          </Tooltip>
                        </Slider>
                      </Center>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td paddingInlineStart={0}>Number of Questions Remaining</Td>
                    <Td paddingInlineStart={0}>
                      <Center>
                        <Slider
                          width="100%"
                          isReadOnly
                          id="answer-usage-slider"
                          value={answerUsage ? answerUsage.used : 0}
                          min={0}
                          max={answerUsage && answerUsage.allowed ? answerUsage.allowed : 0}
                          colorScheme={
                            answerUsage && Math.min((answerUsage.used / answerUsage.allowed) * 100, 100) < 90 ? "teal" : "orange"
                          }
                          onMouseEnter={() => setShowTooltipQuestions(true)}
                          onMouseLeave={() => setShowTooltipQuestions(false)}>
                          <SliderTrack height="10px" borderRadius={"full"}>
                            <SliderFilledTrack />
                          </SliderTrack>
                          <Tooltip
                            hasArrow
                            placement="top"
                            isOpen={showTooltipQuestions}
                            label={`You have used ${answerUsage?.used} of ${answerUsage?.allowed} AI questions`}>
                            <SliderThumb />
                          </Tooltip>
                        </Slider>
                      </Center>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td paddingInlineStart={0}>Organizations</Td>
                    <Td paddingInlineStart={0} className="ch-profile-organizations">
                      {organizations ? organizations.join(", ") : "None"}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
