import { Tooltip, useColorModeValue, Center, IconButton } from "@chakra-ui/react";
import { useProjectParams, useContents, useCollectionKey, useManualTags } from "hooks";
import type { FunctionComponent } from "react";
import React, { useContext, useMemo } from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ConversationContext } from "screens/thread/ConversationContext";

interface Props {
  collectionId: string;
  isDisabled?: boolean;
  size?: string;
}

export const ProjectReportPanelButton: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  collectionId,
  isDisabled: propIsDisabled,
  size = ".9rem",
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.400");
  const { onProjectReportPanelOpen } = useAddToCharliContext();
  const { projectId } = useProjectParams();
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const tagsFilter = useManualTags();
  const contentData = useContents(collectionMetadataIds ?? [], { refreshFromNetwork: true, filterByTags: tagsFilter });
  const hasReports = useMemo(() => {
    return contentData && contentData.length > 0;
  }, [contentData]);
  const { setSelectedContentId } = useContext(ConversationContext);

  return (
    <Tooltip
      maxWidth={"6.5rem"}
      textAlign={"center"}
      aria-label="project report"
      label={propIsDisabled ? "" : !hasReports ? "No reports created yet" : "Open Reports"}
      placement="top"
      className="ch-open-report-panel">
      <Center>
        <IconButton
          minWidth={"unset"}
          className="ch-open-report-panel-icon"
          icon={<AiOutlineFileText />}
          aria-label="Open Reports"
          variant="ghost"
          color={tileBorderColor}
          size="sm"
          fontSize={size}
          isDisabled={propIsDisabled || !hasReports}
          _hover={{
            color: "primary.default",
            bg: "transparent",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setSelectedContentId(collectionId || projectId);
            onProjectReportPanelOpen();
          }}
        />
      </Center>
    </Tooltip>
  );
};
