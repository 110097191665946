import {
  Box,
  Stack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import type { FunctionComponent } from "react";
import { useState, useCallback } from "react";
import React from "react";
import { useThumbnail } from "hooks";
import { ContentImageWrapper } from "screens/content/common/ContentImage/ContentImageWrapper";
import type { ContentDetails } from "types/content/ContentDetails";
import { useContentDetails } from "hooks";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ContentJsonViewPanel } from "../ContentJsonViewPanel";
import { IntegrationURN } from "types/integration";
import { request } from "api/request";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { BsFiletypeJson } from "react-icons/bs";
import { SmallActionButton } from "screens/content/contentCanvas/cell/SmallActionButton";

export const onOpenContent = (contentData: ContentDetails, answerId?: string) => {
  if (contentData.type === "link") {
    contentData.urls?.url !== null && window.open(contentData.urls?.url, "_blank");
    return;
  }

  if (contentData.integrationUrn === IntegrationURN.charliCloudStorage) {
    if (contentData.type === "document" && contentData.extension === "pdf" && answerId) {
      window.open(`/answer/${answerId}/content/${contentData.id}/pdf`, "_blank");
    } else {
      request()
        .url(contentData.urls?.downloadUrl || "")
        .get()
        .blob((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", contentData.name || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  } else {
    contentData.urls?.url !== null && window.open(contentData.urls?.url, "_blank");
  }
};

interface Props {
  itemContent?: ContentDetails;
  thumbnailHeight?: string;
  thumbnailWidth?: string;
  maxHeight?: string;
  metadataId?: string;
  showExpand?: boolean;
  answerId?: string;
  backgroundColor?: string;
  thumbnailFit?: "cover" | "fill";
  noOnClickAction?: boolean;
  showLargeImage?: boolean;
}

export const ContentPreview: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  itemContent,
  thumbnailHeight,
  thumbnailWidth,
  maxHeight = "unset",
  metadataId,
  showExpand = false,
  answerId,
  backgroundColor,
  thumbnailFit = "cover",
  noOnClickAction,
  showLargeImage,
}) => {
  const { isContentJsonViewOpen, onContentJsonViewClose, onContentJsonViewOpen } = useAddToCharliContext();
  const contentDetails = useContentDetails(metadataId, { refreshFromNetwork: true });
  const contentData = itemContent || contentDetails;
  const bgColor = useColorModeValue("white", "gray.700");
  const bgColorContentRoute = useColorModeValue("white", "gray.800");
  const [isExpanded, setIsExpanded] = useState(false);
  const { isOpen: isImageModalOpen, onOpen: openImageModal, onClose: closeImageModal } = useDisclosure();
  const [modalImage, setModalImage] = useState<{ url: string; title: string; loading: boolean }>({
    url: "",
    title: "",
    loading: false,
  });
  const { thumbnailUrlForFile } = useThumbnail();

  const handleImageClick = useCallback(() => {
    if (!showLargeImage || !contentData?.mediaId) return;

    // Prevent multiple clicks while loading
    if (modalImage.loading) return;

    // Use maximum dimensions for highest quality
    const imageDimensions = { width: "1700", height: "1400" };
    const largeImageUrl = thumbnailUrlForFile(
      contentData.mediaId,
      imageDimensions.width,
      imageDimensions.height,
      "contain",
      "center",
      contentData.id
    );

    // Show loading state immediately in modal
    setModalImage({
      url: "",
      title: contentData.name || "Image Preview",
      loading: true,
    });
    openImageModal();

    // Load the high-res image
    const img = document.createElement("img");
    img.src = largeImageUrl;

    img.onload = () => {
      setModalImage({
        url: largeImageUrl,
        title: contentData.name || "Image Preview",
        loading: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentData, showLargeImage, thumbnailUrlForFile]);

  const thumbnailDimensions = () => {
    const { innerWidth, innerHeight } = window;
    const dimension = (dim: number) => `${Math.min(dim / 3, 700)}px`;
    return { thumbnailWidth: dimension(innerWidth), thumbnailHeight: dimension(innerHeight) };
  };

  const handleOnClickImage = () => {
    if (!contentData) return;

    onOpenContent(contentData, answerId);
  };

  return (
    <>
      {contentData?.mediaId && (
        <Stack bgColor={backgroundColor || bgColor} position={"relative"} borderRadius="md" overflow="hidden" maxHeight={maxHeight}>
          <Box mt="unset!important">
            <ContentImageWrapper
              thumbnailBorderRadius="0.375rem 0.375rem 0 0"
              metadataId={contentData.id}
              thumbnailWidth={thumbnailWidth || thumbnailDimensions().thumbnailWidth}
              thumbnailHeight={thumbnailHeight || thumbnailDimensions().thumbnailHeight}
              fit={thumbnailFit}
              thumbnailFit={thumbnailFit}
              thumbnailPosition="top"
              bgColor={backgroundColor || bgColor}
              onClick={() => {
                if (noOnClickAction) return;
                if (showLargeImage && contentData?.mediaId) {
                  handleImageClick();
                } else if (contentData?.type === "dynamic_data") {
                  onContentJsonViewOpen();
                } else if (contentData?.urls?.url) {
                  handleOnClickImage();
                }
              }}
            />
            {(showExpand || contentData.type === "dynamic_data") && (
              <Box position="absolute" bgColor={bgColorContentRoute} top="5px" right="5px" px="3px" borderRadius="md">
                <SmallActionButton
                  classNames="ch-expand-preview"
                  id="ch-json-view"
                  iconTypeName={contentData.type === "dynamic_data" ? BsFiletypeJson : isExpanded ? BiCollapse : BiExpand}
                  onClick={() => {
                    if (contentData.type === "dynamic_data") {
                      onContentJsonViewOpen();
                    } else setIsExpanded(!isExpanded);
                  }}
                  tooltip={contentData.type === "dynamic_data" ? "Open JSON View" : isExpanded ? "" : "Expand Preview"}
                />
              </Box>
            )}
          </Box>
          <ContentJsonViewPanel isOpen={isContentJsonViewOpen} onClose={onContentJsonViewClose} />
        </Stack>
      )}

      <Modal
        isOpen={isImageModalOpen}
        onClose={closeImageModal}
        size="full"
        closeOnOverlayClick={false}
        closeOnEsc={false}
        trapFocus={false}
        blockScrollOnMount={false}
        preserveScrollBarGap={false}>
        <ModalOverlay />
        <ModalContent bg="blackAlpha.900" margin={0} maxWidth="none" minHeight="100vh" overflowY="auto" overflowX="auto">
          <ModalCloseButton color="white" zIndex={2} />
          <ModalBody
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            padding={0}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            {modalImage.loading ? (
              <Box width="100%" height="100vh" display="flex" justifyContent="center" alignItems="center" color="white" fontSize="lg">
                Loading...
              </Box>
            ) : (
              <Box
                padding="2vh"
                minWidth="100%"
                minHeight="100vh"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                onClick={(e) => e.stopPropagation()}>
                <Image
                  src={modalImage.url}
                  alt={modalImage.title}
                  width="200%"
                  height="auto"
                  maxWidth="none"
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    transformOrigin: "top left",
                    cursor: "default",
                  }}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
