import { Box, Button } from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import React, { useMemo } from "react";
import { Wizard } from "react-use-wizard";
import type { IField } from "screens/common/components";
import { DynamicForm } from "screens/common/components";
import { PanelStep } from "screens/panels/components/PanelStep";
import { PanelView } from "screens/panels/components/PanelView";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

export interface UpsertQuestionForm {
  id: string;
  question: string;
  focus: string;
}

interface IProps {
  onSubmit: (values: UpsertQuestionForm) => void;
  isLoading?: boolean;
  initialValue?: UpsertQuestionForm;
}

interface IPanelProps extends IProps {
  onClose: () => void;
  isOpen: boolean;
}

const validationSchema = yup.object().shape({
  question: yup.string().required("Question is required"),
});

const UpsertQuestion = ({ onSubmit, isLoading, initialValue, onClose }: IPanelProps) => {
  const commonButtonProps = useButtonProps("sm", "primary");
  const { answerFocusOptions, selectedSourceWeightsIndex } = useAnswerFocusOptions("due_diligence");

  const fields: IField<UpsertQuestionForm>[] = useMemo(
    () => [
      {
        type: "text",
        entity: "question",
        label: "Question",
        isRequired: true,
        defaultValue: "",
      },
      {
        type: "select",
        entity: "focus",
        label: "Focus",
        isRequired: true,
        options:
          answerFocusOptions?.map((option) => ({
            label: option.label,
            value: option.key,
          })) ?? [],
        defaultValue: answerFocusOptions?.[0]?.key ?? "",
      },
    ],
    [answerFocusOptions]
  );

  const defaultValues: UpsertQuestionForm = useMemo(() => {
    if (initialValue) {
      return initialValue;
    }
    return {
      id: uuid(),
      question: "",
      focus: selectedSourceWeightsIndex ?? answerFocusOptions?.[0]?.key ?? "sentiment",
    };
  }, [initialValue, selectedSourceWeightsIndex, answerFocusOptions]);

  return (
    <Box>
      <DynamicForm<UpsertQuestionForm>
        formId="upsert-question-form"
        defaultValues={defaultValues}
        fields={fields}
        onSubmit={(values) => onSubmit(values)}
        title={initialValue ? "Edit Question" : "Create Question"}
        isDisabled={isLoading}
        validationSchema={validationSchema}
      />

      <Box display={"flex"} justifyContent={"flex-end"} width="100%" py="1rem">
        <Button isLoading={isLoading} {...commonButtonProps} type="submit" form="upsert-question-form">
          Save Question
        </Button>
      </Box>
    </Box>
  );
};

export const UpsertQuestionModal = ({ isOpen, onClose, ...rest }: IPanelProps) => {
  return (
    <PanelView isOpen={isOpen} onClose={onClose} panelTitle={rest.initialValue ? "Edit Question" : "Create Question"}>
      <Wizard>
        <PanelStep>
          <Box pt="1rem">{isOpen && <UpsertQuestion isOpen={isOpen} onClose={onClose} {...rest} />}</Box>
        </PanelStep>
      </Wizard>
    </PanelView>
  );
};
