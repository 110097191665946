import { useCallback, useMemo, useState } from "react";
import type { Workflow } from "types/workflows/workflow";
import { WorkflowTaskStatus } from "types/workflows/workflow";
import { filterCollectionsByType, getMainCollectionWorkflowId, useCollections } from "./useCollections";
import { useSelector } from "react-redux";
import type { RootState } from "state/rootReducer";
import type { CollectionWithAdditionalProps } from "types/collection";
import { useWorkflowsStatsMap } from "./useWorkflowsStats";
import { chartItemColor, subtractDays } from "screens/landing/tabs/collections/chartComponents/CustomChartUtils";
import { useConfigMap, useGetViewConfig } from "./useViewConfig";
import { useLocation } from "react-router-dom";
import groupBy from "lodash/groupBy";
import moment from "moment";
import capitalize from "lodash/capitalize";
import { useWorkflowsMap } from "./useWorkflows";
import { getViewConfig } from "configs/configMap";
import { useAllContents } from "./useContentService";
import { hasProjectOutput } from "screens/collection/components/utils";
import type { WorkflowStatus } from "api/tasks/models/WorkflowStatus";
import { useUserProfile } from "./useUserProfile";

export const getConfidenceLevel = (score: number) => {
  if (score === 0) return null;
  if (score > 80) {
    return "high";
  } else if (score > 40) {
    return "medium";
  } else {
    return "low";
  }
};

export const scoreColor = (score: number): string => {
  const confidenceLevel = getConfidenceLevel(score);
  return (confidenceLevel && chartItemColor(confidenceLevel)) || "#ad729d";
};

export const getConfidenceColor = (score: number) => {
  const confidenceLevel = getConfidenceLevel(score);
  switch (confidenceLevel) {
    case "high":
      return "green";
    case "medium":
      return "orange";
    case "low":
      return "red";
    default:
      return "blue";
  }
};

export const calculateWorkflowDuration = (workflow: Workflow) => {
  const createdDate = new Date(workflow.creationDate);
  const completionDate = workflow.completionDate ? new Date(workflow.completionDate) : new Date();
  const differenceInMilliseconds = new Date(completionDate).getTime() - new Date(createdDate).getTime();
  const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);
  if (differenceInMilliseconds > 0 && differenceInMinutes < 1) {
    return { differenceInHours: 0, differenceInMinutes: 1 };
  }
  const differenceInHours = Math.floor(differenceInMilliseconds / 1000 / 60 / 60) || 0;
  return { differenceInHours, differenceInMinutes };
};

export type DataType = {
  state?: string;
  name?: string;
  workflowId?: string;
  label: string;
  value: number;
  valueSuffix?: string;
  userId?: string;
};

export type StatesByDateType = {
  [date: string]: {
    [state: string]: number;
  };
};

export type DateType = DataType & {
  name: string;
};

type CollectionTagData = {
  average?: number;
  level?: "high" | "medium" | "low" | null;
  tags?: {
    name: string;
    occurrences: number;
  }[];
  totalItems?: number;
  collectionLastUpdated?: string;
};

const mapWorkflowStatesLabel = (workflowTaskStatus: WorkflowTaskStatus) => {
  switch (workflowTaskStatus) {
    case "queued": {
      return {
        label: "Projects in queue",
      };
    }
    case "in_progress": {
      return {
        label: "In Progress",
      };
    }
    case "incomplete":
    case "failed": {
      return {
        label: "Incomplete Projects",
      };
    }
    case "clarification_needed": {
      return {
        label: "Clarification Needed",
      };
    }
    case "complete": {
      return {
        label: "Completed Projects",
      };
    }
    case "error": {
      return {
        label: "Projects with errors",
      };
    }
    case "cancelled": {
      return {
        label: "Cancelled Projects",
      };
    }
    case "denied_intent_confirmation": {
      return {
        label: "Projects denied intent confirmation",
      };
    }

    default: {
      return {
        label: "Projects with unknown status",
      };
    }
  }
};

export const useWorkflowStatesByDay = (props: { collectionType: string; statesToFilter?: WorkflowTaskStatus[]; limitDate?: Date }) => {
  const { collectionType, statesToFilter: statesToFilterProp, limitDate: limitDateProp = new Date() } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);
  const configMap = useConfigMap();
  const collections = useCollections();
  const { workflowsById } = useSelector((state: RootState) => state.workflow);

  return useMemo(() => {
    limitDateProp.setHours(0, 0, 0, 0);
    const intentFilters = getViewConfig("workflowIntentFilters", collectionType || "", configMap) || [];

    // Filter collections by type
    const filteredCollections = filterCollectionsByType(collections, collectionType);

    const mainCollectionWorkflows = filteredCollections.flatMap((collection) => {
      const filteredWorkflows = intentFilters
        ? (collection?.workflowIds || []).filter((id) => workflowsById[id]?.intent && intentFilters.includes(workflowsById[id].intent))
        : collection?.workflowIds || [];
      const mainWorkflowId = filteredWorkflows.reverse()[0];
      return mainWorkflowId ? [mainWorkflowId] : [];
    });

    const workflows = mainCollectionWorkflows.flatMap((workflowId: string) =>
      workflowsById[workflowId] ? [workflowsById[workflowId]] : []
    );

    // If statesToFilter is defined, we only want to return those states, otherwise return all states
    const statesToReturn = (() => {
      if (statesToFilter) {
        return statesToFilter;
      } else {
        return Object.values(WorkflowTaskStatus);
      }
    })();

    /* 
    The purpose of this code is to process a list of workflows and accumulate status counts 
    for each unique date in workflowDate, based on certain filtering conditions. 
    The result, totalStatusByDate, will be an object where keys are dates, and values are records 
    that represent the counts of different workflow statuses for each date. 
    */
    const totalStatusByDate = workflows.reduce((acc: Record<number, Partial<Record<WorkflowTaskStatus, number>>>, workflow) => {
      const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);

      if (
        !mainCollectionWorkflows.includes(workflow.id) ||
        workflowDate < limitDateProp.getTime() ||
        (statesToFilter && !statesToFilter.includes(workflow.status))
      ) {
        return acc;
      }

      if (acc[workflowDate]) {
        const statusCount = acc[workflowDate][workflow.status];
        if (statusCount) {
          acc[workflowDate][workflow.status] = statusCount + 1;
        } else {
          acc[workflowDate][workflow.status] = 1;
        }
      } else {
        acc[workflowDate] = {};
        statesToReturn.forEach((status) => {
          acc[workflowDate][status] = status === workflow.status ? 1 : 0;
        });
      }

      return acc;
    }, {});

    const dayInMs = 1000 * 3600 * 24;
    const daysOfDifference = -Math.ceil((limitDateProp.getTime() - new Date().getTime()) / dayInMs);
    const datesArray: DateType[] = [];

    const tempDate = new Date(new Date().getTime() - dayInMs * daysOfDifference);
    tempDate.setHours(0, 0, 0, 0);
    for (let index = daysOfDifference; index >= 0; index--) {
      const tempTime = tempDate.getTime();
      const workflowStatusMap = totalStatusByDate[tempTime];

      if (!workflowStatusMap) {
        datesArray.push(
          ...statesToReturn.map((status) => ({
            name: new Date(tempTime).toDateString(),
            value: 0,
            state: status,
            ...mapWorkflowStatesLabel(status),
          }))
        );
        tempDate.setDate(tempDate.getDate() + 1);

        continue;
      }

      Object.entries(workflowStatusMap).forEach(([status, value]) => {
        datesArray.push({
          name: new Date(tempTime).toDateString(),
          value,
          state: status === "failed" ? "incomplete" : (status as WorkflowTaskStatus),
          ...mapWorkflowStatesLabel(status as WorkflowTaskStatus),
        });
      });

      tempDate.setDate(tempDate.getDate() + 1);
    }

    return datesArray;
  }, [limitDateProp, collectionType, configMap, collections, workflowsById, statesToFilter]);
};

export const useWorkflowTotalDuration = (props: {
  collection?: CollectionWithAdditionalProps;
  collectionType: string;
  statesToFilter?: WorkflowTaskStatus[];
  limitDate?: Date;
}) => {
  const { collection, collectionType, statesToFilter: statesToFilterProp, limitDate: limitDateProp = new Date() } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  const collections = useCollections();
  const workflowsById = useWorkflowsMap();

  const filteredCollections = useMemo(
    () => (collection ? [collection] : collectionType ? filterCollectionsByType(collections, collectionType) : collections),
    [collection, collections, collectionType]
  );

  const mainCollectionWorkflows = useMemo(() => {
    return filteredCollections.flatMap((collection) => {
      const mainWorkflowId = getMainCollectionWorkflowId(collection);
      return mainWorkflowId ? [mainWorkflowId] : [];
    });
  }, [filteredCollections]);

  return useMemo(() => {
    limitDateProp.setHours(0, 0, 0, 0);

    const workflows = mainCollectionWorkflows.flatMap((workflowId: string) =>
      workflowsById[workflowId] ? [workflowsById[workflowId]] : []
    );

    const totalDurationByDate = workflows.reduce(
      (acc: Record<string, { duration: number; title: string; date: number; state: WorkflowTaskStatus; userId: string }>, workflow) => {
        const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);
        const workflowTitle = workflow.title;
        const filteredCollectionsForThisWorkflow = filteredCollections.filter((collection) =>
          collection.workflowIds && collection.workflowIds.length > 0 ? collection.workflowIds.includes(workflow.id) : false
        );

        if (
          !mainCollectionWorkflows.includes(workflow.id) ||
          workflowDate < limitDateProp.getTime() ||
          (statesToFilter && !statesToFilter.includes(workflow.status))
        ) {
          return acc;
        }

        acc[workflow.id] = {
          duration: calculateWorkflowDuration(workflow as Workflow).differenceInMinutes,
          title: filteredCollectionsForThisWorkflow[0].name || workflowTitle,
          date: workflowDate,
          state: workflow.status === "failed" ? WorkflowTaskStatus.incomplete : workflow.status,
          userId: workflow.userId,
        };

        return acc;
      },
      {}
    );

    const dataArray: DateType[] = [];

    Object.entries(totalDurationByDate).forEach(([workflowId, { duration, title, date, state, userId }]) => {
      dataArray.push({
        workflowId,
        name: title,
        value: duration,
        label: new Date(date).toDateString(),
        valueSuffix: "minutes",
        state,
        userId: userId,
      });
    });

    return dataArray;
  }, [limitDateProp, mainCollectionWorkflows, workflowsById, filteredCollections, statesToFilter]);
};

export const useWorkflowTotalExecutionDuration = (props: {
  collection?: CollectionWithAdditionalProps;
  collectionType?: string;
  statesToFilter?: WorkflowTaskStatus[];
  limitDate?: Date;
  shouldReturnRangeData?: boolean;
}) => {
  const {
    collection,
    collectionType,
    statesToFilter: statesToFilterProp,
    limitDate: limitDateProp = new Date(),
    shouldReturnRangeData,
  } = props;
  const [statesToFilter] = useState<WorkflowTaskStatus[] | undefined>(statesToFilterProp);

  const collections = useCollections();
  const workflowsById = useWorkflowsMap();

  const filteredCollections = useMemo(
    () => (collection ? [collection] : collectionType ? filterCollectionsByType(collections, collectionType) : collections),
    [collection, collections, collectionType]
  );

  const mainCollectionWorkflows = useMemo(() => {
    return filteredCollections.flatMap((collection) => {
      const mainWorkflowId = getMainCollectionWorkflowId(collection);
      return mainWorkflowId ? [mainWorkflowId] : [];
    });
  }, [filteredCollections]);

  const workflowsStatsMap = useWorkflowsStatsMap(mainCollectionWorkflows);

  limitDateProp.setHours(0, 0, 0, 0);

  const workflows = mainCollectionWorkflows.flatMap((workflowId: string) => (workflowsById[workflowId] ? [workflowsById[workflowId]] : []));

  const filteredWorkflows = workflows.flatMap((workflow) => {
    const workflowDate = new Date(workflow.creationDate).setHours(0, 0, 0, 0);
    const filteredCollectionsForThisWorkflow = filteredCollections.filter((collection) =>
      collection.workflowIds && collection.workflowIds.length > 0 ? collection.workflowIds.includes(workflow.id) : false
    );

    if (!mainCollectionWorkflows.includes(workflow.id) || (statesToFilter && !statesToFilter.includes(workflow.status))) {
      return [];
    } else {
      return {
        id: workflow.id,
        executionTime: workflowsStatsMap[workflow.id] ? Math.round(workflowsStatsMap[workflow.id].executionTime / 1000 / 60) : 0,
        title: filteredCollectionsForThisWorkflow[0].name || workflow.title,
        date: workflowDate,
        state: workflow.status === "failed" ? WorkflowTaskStatus.incomplete : workflow.status,
      };
    }
  });
  const dataArray: DateType[] = [];

  if (shouldReturnRangeData) {
    filteredWorkflows.forEach(({ title, executionTime }) => {
      let value = "";
      if (executionTime >= 0 && executionTime <= 5) {
        value = "0-5";
      } else if (executionTime > 5 && executionTime <= 10) {
        value = "5-10";
      } else {
        value = "10+";
      }
      dataArray.push({
        name: title,
        value: 1,
        label: `${value} mins`,
        state: value,
      });
    });
    const defaultLabels = ["0-5", "5-10", "10+"];
    defaultLabels.forEach((label) => {
      const hasDefault = dataArray.some((data) => data.label === label);
      if (!hasDefault) {
        dataArray.push({
          name: "No Projects",
          value: 0,
          label: `${label} mins`,
          state: label,
        });
      }
    });
  } else {
    filteredWorkflows.forEach(({ id: workflowId, title, executionTime, date, state }) => {
      dataArray.push({
        workflowId,
        name: title,
        value: executionTime,
        label: new Date(date).toDateString(),
        valueSuffix: "minutes",
        state,
      });
    });
  }

  return dataArray;
};

export const useSumAllValuesByLabel = (data: DataType[]) => {
  return useMemo(() => {
    const result: { [key: string]: { value: number; label: string } } = data.reduce(
      (acc: { [key: string]: { value: number; label: string } }, item: DataType) => {
        if (!item.state) {
          return acc;
        }
        if (acc[item.state]) {
          acc[item.state].value += item.value;
        } else {
          acc[item.state] = { value: item.value, label: item.label };
        }
        return acc;
      },
      {}
    );

    return Object.entries(result).map(([state, { value, label }]) => ({ state, value, label }));
  }, [data]);
};

export function useGetProjectConfidenceScoreByTag(): (collection: CollectionWithAdditionalProps) => CollectionTagData {
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const metricTags = useGetViewConfig("metricTags", route, configMap);
  const contents = useAllContents();

  return useCallback(
    (collection: CollectionWithAdditionalProps) => {
      const maybeContents = collection.metadataIds.flatMap((metadataId) => (contents[metadataId] ? [contents[metadataId]] : []));

      if (!metricTags || maybeContents.length === 0) {
        return {};
      }

      const filteredContents = maybeContents.filter((content) => !hasProjectOutput(content));
      const combinedTags = filteredContents.reduce(
        (acc: string[][], content) => [
          [...acc[0], ...content.autoTags],
          [...acc[1], ...content.manualTags],
        ],
        [[], []]
      );

      const allCombinedTags = combinedTags.reduce((accum, tags) => {
        return tags.reduce((innerAccum, tag) => {
          innerAccum[tag] = (innerAccum[tag] || 0) + 1;
          return innerAccum;
        }, accum);
      }, {} as { [key: string]: number });

      const distinctTags = Object.entries(allCombinedTags)
        .map(([name, occurrences]) => ({ name, occurrences }))
        .sort((a, b) => b.occurrences - a.occurrences);

      const totalItems = filteredContents.length || 0;

      const metricTagsStats = metricTags && distinctTags.filter((tag) => metricTags.includes(tag.name));
      const sumOfMetricTags = metricTagsStats?.reduce((accum, tag) => accum + tag.occurrences, 0) || 0;

      const averageConfidenceUsingTags = isNaN(Math.round((sumOfMetricTags / totalItems) * 100))
        ? 0
        : Math.round((sumOfMetricTags / totalItems) * 100);
      const confidenceLevel = averageConfidenceUsingTags ? getConfidenceLevel(averageConfidenceUsingTags) : null;

      return {
        average: averageConfidenceUsingTags,
        level: confidenceLevel,
        tags: metricTagsStats,
        totalItems: totalItems,
        collectionLastUpdated: collection?.metadata.lastUpdatedTime,
      };
    },
    [contents, metricTags]
  );
}

export const useProjectConfidenceScoreByTag = (props: { collectionType: string; limitDate?: Date }): DataType[] => {
  const { collectionType, limitDate: limitDateProp = new Date() } = props;
  const collections = useCollections();
  const { pathname } = useLocation();
  const isProject = pathname.includes("project");
  const route = pathname.split("/").slice(isProject ? 2 : 1, isProject ? 3 : 2)[0];
  const configMap = useConfigMap();
  const metricTags = useGetViewConfig("metricTags", route, configMap);
  const GetProjectConfidenceScoreByTag = useGetProjectConfidenceScoreByTag();
  limitDateProp.setHours(0, 0, 0, 0);

  if (!metricTags || collections.length === 0) {
    return [];
  }

  const filteredCollections = collections.filter((collection) => collection.collectionType === collectionType);
  const formattedCollections = filteredCollections.map((collection) => {
    const formattedTime = moment(collection.metadata.lastUpdatedTime).format("MM/DD/YYYY");
    return { ...collection, lastUpdatedTime: formattedTime };
  });
  const groupedCollections = groupBy(formattedCollections, "lastUpdatedTime");

  const collectionData = Object.entries(groupedCollections).map(([lastUpdatedTime, group]) => ({
    lastUpdatedTime,
    collections: group.map((collection) => GetProjectConfidenceScoreByTag(collection)),
  }));

  return collectionData.flatMap((collectionRecords) => {
    return collectionRecords.collections
      .filter((item) => item.level && item.level !== null)
      .map(
        (item) =>
          ({
            label: `${item.level && capitalize(item.level)} score projects`,
            value: 1,
            state: item.level,
          } as DataType)
      );
  });
};

export const useProjectConfidenceScore = (props: {
  collection?: CollectionWithAdditionalProps;
  collectionType?: string;
  limitDate?: Date;
}) => {
  const { collection, collectionType, limitDate: limitDateProp = new Date() } = props;

  const collections = useCollections();

  return useMemo(() => {
    limitDateProp.setHours(0, 0, 0, 0);

    // Filter collections by type
    const filteredCollections = collection
      ? [collection]
      : collections.filter((collection) => collection.collectionType === collectionType);

    // Calculate min, max, and average confidence per filtered collection
    const confidencePerCollection = filteredCollections.map((collection) => {
      const collectionAnswerConfidence =
        collection.questions && collection.questions.length > 0
          ? collection.questions.flatMap((question) => question.answers.map((answer) => answer.confidence))
          : [];

      const collectionRiskAssessmentAnswerConfidence =
        collection.unstructuredData && collection.unstructuredData.data.assessment_results.length > 0
          ? collection.unstructuredData.data.assessment_results.flatMap(
              (assessmentResult) => assessmentResult.risk_assessment_result.confidence
            )
          : [];

      const collectionConfidenceData = [...collectionAnswerConfidence, ...collectionRiskAssessmentAnswerConfidence];

      const minConfidence = Math.min(...collectionConfidenceData);
      const maxConfidence = Math.max(...collectionConfidenceData);
      const avgConfidence =
        collectionConfidenceData.reduce((sum, confidence) => sum + confidence * 100, 0) / Math.round(collectionConfidenceData.length) / 100;

      const isInfinityOrNaN =
        Number.isNaN(minConfidence) ||
        Number.isNaN(maxConfidence) ||
        Number.isNaN(avgConfidence) ||
        !Number.isFinite(minConfidence) ||
        !Number.isFinite(maxConfidence) ||
        !Number.isFinite(avgConfidence);

      // Return zero if any of the values is Infinity or NaN
      if (isInfinityOrNaN) {
        return {
          collectionId: collection.id,
          minConfidence: 0,
          maxConfidence: 0,
          avgConfidence: 0,
        };
      }

      return {
        collectionId: collection.id,
        minConfidence,
        maxConfidence,
        avgConfidence,
      };
    });

    return confidencePerCollection;
  }, [collection, collections, collectionType, limitDateProp]);
};

export const useMaxAllowedProjectsReached = (collectionType: string) => {
  const configMap = useConfigMap();
  const maxProjectsAllowed = useGetViewConfig("maxActiveProjectsAllowed", collectionType, configMap);
  const workflowStatuses = useWorkflowTotalDuration({
    collectionType: collectionType,
    limitDate: subtractDays(30),
  });
  const { id } = useUserProfile();

  const hasMaxProjectsReached = useMemo(() => {
    const countInProgressWorkflowStatuses = (workflowStatuses: DateType[]) => {
      let count = 0;
      for (const status of workflowStatuses) {
        const isWorkflowInProgress = ["clarification_needed", "in_progress", "queued"].includes(status.state as WorkflowStatus);

        if (status.userId === id && isWorkflowInProgress) {
          count++;
        }
      }

      return count;
    };

    const inProgressCount = countInProgressWorkflowStatuses(workflowStatuses);

    return inProgressCount > 0 && inProgressCount >= Number(maxProjectsAllowed);
  }, [workflowStatuses, maxProjectsAllowed, id]);

  return hasMaxProjectsReached;
};
