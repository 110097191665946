import React, { useContext } from "react";
import { useColorModeValue, InputGroup, IconButton, InputRightElement, Stack, Center } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { AutoCompleteInput } from "@choc-ui/chakra-autocomplete";
import { CharliLogoCircle } from "screens/thread/CharliLogoCircle";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { InputBarAttachmentButton } from "screens/conversation/components/InputBar/InputBarAttachmentButton";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";

interface AutocompleteSuggestionInputProps {
  inputId: string;
  value?: string;
  initialText?: string;
  isInputDisabled?: boolean;
  isSubmitDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  canBypassDisabled?: boolean;
  isFocusedInputDisabled: boolean;
  isConversationOpen: boolean;
  conversationState?: string;
  isWebsocketConnected?: boolean;
  handleInputOnClick: (onOpen: () => void) => void;
  handleSubmit: () => void;
  handleInputChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleConversation: () => void;
  setIsAnotherInputFocused: (value: boolean) => void;
  onBlur?: () => void;
  onOpen: () => void;
}

export const AutocompleteSuggestionInput = React.memo(
  ({
    inputId,
    value,
    initialText,
    isInputDisabled,
    isSubmitDisabled,
    isLoading,
    className,
    inputRef,
    canBypassDisabled,
    isFocusedInputDisabled,
    isConversationOpen,
    conversationState,
    isWebsocketConnected,
    handleInputOnClick,
    handleSubmit,
    handleInputChange,
    onHandleConversation,
    setIsAnotherInputFocused,
    onBlur,
    onOpen,
  }: AutocompleteSuggestionInputProps) => {
    const inputBgColor = useColorModeValue("white", "gray.700");
    const inputBorderColor = useColorModeValue("gray.200", "gray.700");
    const inputSubmitColor = useColorModeValue("gray.50", "gray.800");
    const inputSubmitColorHover = useColorModeValue("gray.200", "primary.default");
    const submitColor = useColorModeValue("primary.default", "gray.700");

    const { files } = useContext(InputBarDropzoneContext);

    return (
      <InputGroup position="relative">
        <Stack bottom={-0.5} zIndex={10} display="flex" position="absolute" direction="row" justifyContent="space-between">
          <CharliLogoCircle className="project-conversation-button" onClick={onHandleConversation} />
          {(value?.startsWith("/") || files.length > 0 || (conversationState && conversationState === "clarification_needed")) &&
            isConversationOpen && (
              <Center width="2rem" borderRadius="30px 0px 0px 30px!important">
                <InputBarAttachmentButton size="md" />
              </Center>
            )}
        </Stack>
        <AutoCompleteInput
          id={inputId}
          ref={inputRef}
          onClick={() => handleInputOnClick(onOpen)}
          onBlur={() => {
            setIsAnotherInputFocused(false);
            onBlur?.();
          }}
          autoComplete="off"
          paddingInlineStart="0"
          paddingInlineEnd="2.5rem"
          paddingLeft={value?.startsWith("/") && isConversationOpen ? "5.5rem" : "4rem"}
          borderRadius="30px 30px 30px 30px!important"
          aria-autocomplete="both"
          className={className ? className : "ch-question-input"}
          disabled={canBypassDisabled && !isFocusedInputDisabled ? false : isInputDisabled || isFocusedInputDisabled}
          backgroundColor={inputBgColor}
          placeholder={initialText}
          onChange={handleInputChange}
          _disabled={{
            cursor: "not-allowed",
            opacity: 0.7,
            color: "gray.200",
          }}
          _hover={{}}
          loadingIcon={<TypingIndicator size="small" />}
          size="md"
          borderColor={inputBorderColor}
          borderWidth="1px"
          borderLeftWidth="0"
          value={value}
          background={`linear-gradient(to right, transparent 2rem, ${inputBgColor} 2rem) !important`}
        />
        <InputRightElement
          width="2.5rem"
          height="2.5rem"
          zIndex={value && value.length > 0 ? 2 : 0}
          children={
            <IconButton
              className="ch-autocomplete-submit"
              aria-label="submit"
              borderRadius="full"
              color={submitColor}
              size="sm"
              backgroundColor={inputSubmitColor}
              _disabled={{ cursor: "not-allowed", opacity: 0.6 }}
              _hover={{ fontWeight: "bold", backgroundColor: inputSubmitColorHover }}
              icon={<ArrowForwardIcon width="1.5rem" height="1.5rem" />}
              isDisabled={
                canBypassDisabled && !isFocusedInputDisabled
                  ? false
                  : isLoading || !isWebsocketConnected || isSubmitDisabled || isInputDisabled || isFocusedInputDisabled
              }
              onClick={handleSubmit}
            />
          }
        />
      </InputGroup>
    );
  }
);
