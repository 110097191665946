import type { ReactNode } from "react";
import React, { useContext, useMemo, useCallback, useRef, useState, useEffect } from "react";
import { Wizard } from "react-use-wizard";
import { PanelStep } from "../../panels/components/PanelStep";
import { PanelView } from "../../panels/components/PanelView";
import { useAddToCharliContext } from "../../panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useCollectionKey, useContents, useTileProps } from "hooks";
import {
  Text,
  Stack,
  Box,
  Center,
  useColorModeValue,
  Image,
  useToast,
  useDisclosure,
  Divider,
  useOutsideClick,
  useBreakpointValue,
  Tooltip,
} from "@chakra-ui/react";
import { LandingTitle } from "screens/landing/components";
import { SmallActionButton } from "../contentCanvas/cell/SmallActionButton";
import { SmallActionConfidenceScoreBadge } from "../contentCanvas/cell/SmallActionConfidenceScoreBadge";
import { ContentFilterContext } from "../contentCanvas/body/ContentFilterContext";
import { ContentPreview, onOpenContent } from "./previewSection/ContentPreview";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { HiOutlineExternalLink } from "react-icons/hi";
import { ToastMessageContent } from "screens/common/components";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import { MarkupTextViewer } from "./sections/MarkupTextViewer";
import { AnswerStatus } from "types/question";
import { sendMessage } from "state/websocket/operations";
import { useDispatch } from "react-redux";
import type { RequestEntities } from "types/charliui";
import { ConversationContext } from "screens/thread/ConversationContext";
import { AiOutlineDelete } from "react-icons/ai";
import { VerifyDeleteModal } from "./VerifyDeleteModal";
import verifiedShieldGray from "screens/common/static/misc/verified_shield_gray.svg";
import { HighlightItemActionsDelete } from "screens/panels/highlights/HighlightItemActionsDelete";
import { VerifiedAIPanelAttribution } from "./VerifiedAIPanelAttribution";
import { Popover } from "react-tiny-popover";
import { ProjectQuestionHotlinks } from "screens/collection/views/ProjectActions/ProjectQuestionHotlinks";
import { useFocusBadgeColors } from "screens/collection/views/AutocompleteSuggestion";
import { useLocation } from "react-router-dom";
import { ProjectThumbHotlinks } from "screens/collection/views/ProjectActions/ProjectThumbHotlinks";

export const VerifiedAIPanel = () => {
  const { isVerifiedAIPanelOpen, onVerifiedAIPanelClose } = useAddToCharliContext();
  const iconColor = useColorModeValue("gray.500", "gray.400");
  const textColor = useColorModeValue("primary.darkGray", "gray.400");
  const commonTileProps = useTileProps();
  const { selectedResult, searchText } = useContext(ContentFilterContext);
  const attributionContentItems = useContents(selectedResult.attributionMetadataIds);
  const dispatch = useDispatch();
  const toast = useToast();
  const { setConversationId } = useContext(ConversationContext);
  const { isOpen: isOpenConfirmDelete, onOpen: onOpenConfirmDelete, onClose: onCloseConfirmDelete } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { pathname } = useLocation();
  const focusBadgeColors = useFocusBadgeColors();
  const headerBgColor = useColorModeValue("gray.100", "gray.700");
  const headerBarColor = useColorModeValue("#009AD8", "yellow.700");
  const disabledBg = useColorModeValue("whiteAlpha.700", "gray.700");
  const bgColor = useColorModeValue("white", "gray.800");
  const headerBarColorNotVerified = useColorModeValue("gray.500", "gray.700");
  const { isOpen: isThumbsUpOpen, onToggle: onThumbsUpToggle, onClose: onThumbsUpClose } = useDisclosure();
  const { isOpen: isThumbsDownOpen, onToggle: onThumbsDownToggle, onClose: onThumbsDownClose } = useDisclosure();
  const shareDetails = useCollectionKey(selectedResult.collectionId, "shareDetails");
  const isSharedRead = shareDetails && shareDetails.accessMode === "read";

  const toggleThumbsUp = useCallback(() => {
    onThumbsUpToggle();
    onThumbsDownClose();
  }, [onThumbsUpToggle, onThumbsDownClose]);

  const toggleThumbsDown = useCallback(() => {
    onThumbsDownToggle();
    onThumbsUpClose();
  }, [onThumbsDownToggle, onThumbsUpClose]);

  const updateAnswerStatus = useCallback(
    (reason?: string) => {
      if (!selectedResult.conversationId) {
        return;
      }
      const entities: RequestEntities = [];
      selectedResult.resultId &&
        entities.push({ entity: `${selectedResult.isQuestion ? "question_id" : "answer_id"}`, value: selectedResult.resultId });
      entities.push({ entity: `${selectedResult.isQuestion ? "question_status" : "answer_status"}`, value: AnswerStatus.unpinned });
      reason && entities.push({ entity: "content_delete_reason", value: reason });
      selectedResult.collectionId && entities.push({ entity: "collection_id", value: selectedResult.collectionId });

      dispatch(
        sendMessage({
          conversationId: selectedResult.conversationId,
          intent: `${selectedResult.isQuestion ? "/manage_question" : "/manage_answer"}`,
          entities: entities,
        })
      );
      onVerifiedAIPanelClose();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`I'll remove this result from your project now. You can open the conversation I started about it by clicking this message`}
            onClick={() => {
              selectedResult.conversationId && setConversationId(selectedResult.conversationId);
              // onConversationOpen();
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    [
      selectedResult.conversationId,
      selectedResult.resultId,
      selectedResult.isQuestion,
      selectedResult.collectionId,
      dispatch,
      onVerifiedAIPanelClose,
      toast,
      setConversationId,
    ]
  );

  const panelHeader: ReactNode = useMemo(
    () => (
      <Stack spacing="0" className="ch-verified-panel">
        <Box
          bgColor={selectedResult.verifiedStatus === "verified" ? headerBarColor : headerBarColorNotVerified}
          height="1rem"
          width="100%"
        />
        <Stack direction="row" justifyContent={"space-between"} backgroundColor={headerBgColor} px={"1.5rem"} py="1rem">
          <Stack width="100%" color={textColor}>
            <Text fontSize={"md"} fontWeight="semibold">
              {`Verified by AI`}
            </Text>
            <Text fontSize={"sm"} fontWeight="normal">
              Charli AI performs detail fact check analysis on the AI results and can also indicate if there is insufficient information to
              provide an answer. This is important to ensure that relevant and accurate results are provided. This page allows you to double
              check the results and make suggestions to improve overall accuracy.
            </Text>
          </Stack>
          <Center>
            <Image
              src={selectedResult.verifiedStatus === "verified" ? verifiedShield : verifiedShieldGray}
              width={["2rem", "2.5rem", "3rem"]}
              ml="1.5rem"
            />
          </Center>
        </Stack>
      </Stack>
    ),
    [headerBarColor, headerBarColorNotVerified, headerBgColor, selectedResult.verifiedStatus, textColor]
  );

  const confidenceValue = selectedResult?.confidence ? Math.round(selectedResult?.confidence * 100) : "--";
  const [showPopover, setShowPopover] = useState(false);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const popRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: popRef!,
    handler: () => {
      setShowPopover(false);
    },
  });

  useEffect(() => {
    onVerifiedAIPanelClose();
  }, [pathname, onVerifiedAIPanelClose]);

  const panelContent: ReactNode = useMemo(
    () => (
      <Stack spacing={isMobile ? "2rem" : "1rem"} mb="1rem">
        <Box
          {...commonTileProps}
          backgroundColor={bgColor}
          borderWidth={isMobile ? "0" : "1px"}
          cursor="default"
          p={isMobile ? "0" : "1rem"}
          height="unset">
          <LandingTitle text="AI Results" underline color={"primary.default"} pb={isMobile ? "0" : "1rem"} />
          <Stack className="ch-verified-details" justifyContent={"flex-start"} spacing="1rem" width="100%" pt=".5rem">
            <Stack spacing="1rem">
              <Stack direction="row" width="100%" justifyContent={"space-between"}>
                <Box ref={mainRef}>
                  <Popover
                    parentElement={mainRef?.current || undefined}
                    isOpen={showPopover}
                    positions={["top"]}
                    padding={10}
                    align="start"
                    transformMode="relative"
                    onClickOutside={() => setShowPopover(false)}
                    content={() => <ProjectQuestionHotlinks question={selectedResult.questionText} focus={selectedResult.focus} />}>
                    <Text
                      borderWidth={"1px"}
                      borderColor={"transparent"}
                      borderRadius={"full"}
                      px=".5rem"
                      _hover={{
                        borderColor: isSharedRead ? "unset" : "primary.default",
                        borderStyle: isSharedRead ? "unset" : "dashed",
                      }}
                      color={textColor}
                      cursor={isSharedRead ? "not-allowed" : "pointer"}
                      onClick={() => !isSharedRead && setShowPopover(true)}
                      width="100%"
                      className="ch-qa-result-question"
                      fontWeight="semibold"
                      fontSize="md">
                      {selectedResult.questionText}
                    </Text>
                  </Popover>
                </Box>
                {selectedResult.focus && (
                  <Box
                    borderRadius={"4px"}
                    height="1.2rem"
                    lineHeight={"1.2rem"}
                    px="5px"
                    fontSize={"10px"}
                    color={textColor}
                    background={focusBadgeColors[selectedResult.focus.toUpperCase()]}>
                    {selectedResult.focus.toUpperCase()}
                  </Box>
                )}
              </Stack>
              <Stack direction={isMobile ? "column" : "row"} justifyContent={"flex-start"} spacing="1rem" width="100%" pt=".5rem">
                <Stack direction={isMobile ? "row" : "column"} align={"center"} spacing="1rem">
                  <SmallActionConfidenceScoreBadge
                    score={confidenceValue}
                    scoreTooltip={`Confidence score: ${confidenceValue}%`}
                    scoreBgColor={iconColor}
                  />
                  <Stack direction="row" spacing="0">
                    <ProjectThumbHotlinks
                      isDisabled={isSharedRead}
                      action="up"
                      resultId={selectedResult.resultId}
                      collectionId={selectedResult.collectionId}
                      isOpen={isThumbsUpOpen}
                      onToggle={toggleThumbsUp}
                      onClose={onThumbsUpClose}
                    />
                    <ProjectThumbHotlinks
                      isDisabled={isSharedRead}
                      action="down"
                      resultId={selectedResult.resultId}
                      collectionId={selectedResult.collectionId}
                      isOpen={isThumbsDownOpen}
                      onToggle={toggleThumbsDown}
                      onClose={onThumbsDownClose}
                    />
                  </Stack>
                  {selectedResult.resultType === "answer" && (
                    <Box>
                      <SmallActionButton
                        isDisabled={isSharedRead}
                        iconTypeName={AiOutlineDelete}
                        iconSize="1.4rem"
                        onClick={onOpenConfirmDelete}
                        tooltip={`Remove result`}
                      />
                      <VerifyDeleteModal
                        title="Confirm Remove Result"
                        message="Please confirm that you would like to remove this result and select one of the reasons below. The reason will be used in future AI learning to improve results."
                        onHandleConfirmDelete={(reason: string) => updateAnswerStatus(reason)}
                        onClose={onCloseConfirmDelete}
                        isOpen={isOpenConfirmDelete}
                      />
                    </Box>
                  )}
                  {selectedResult.resultType === "highlight" && (
                    <Box>
                      <HighlightItemActionsDelete highlightRecordId={selectedResult.resultId} onClose={onVerifiedAIPanelClose} />
                    </Box>
                  )}
                </Stack>
                <Stack direction="column" spacing="1rem" width="100%">
                  <MarkupTextViewer searchText={searchText} markupText={selectedResult.resultText} />
                  <Stack direction="row">
                    <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
                      Confidence:
                    </Text>
                    <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="normal" color={textColor}>
                      {selectedResult.confidence ? selectedResult.confidence.toFixed(5) : "--"}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        {isMobile && <Divider />}
        {attributionContentItems && (
          <Box {...commonTileProps} bgColor={bgColor} borderWidth={isMobile ? "0" : "1px"} cursor="default" p="0" height="unset">
            <LandingTitle
              text={`AI Attribution${attributionContentItems.length > 1 ? "s" : ""}`}
              underline
              color={"primary.default"}
              px="1rem"
              pt="1rem"
            />
            <Box position="relative" mt="-.3rem">
              <Stack spacing="1rem">
                {attributionContentItems.map((itemContent, index) => (
                  <Box key={index}>
                    <Stack
                      p={isMobile ? "0" : "1rem"}
                      className="ch-verified-attribution"
                      direction="column"
                      spacing="1rem"
                      width="100%"
                      key={`attribution-item-${index}`}>
                      <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? "1rem" : "2rem"}>
                        <Box {...commonTileProps} cursor="default" p="5px" height="unset" width={isMobile ? "6rem" : "unset"}>
                          <ContentPreview
                            answerId={selectedResult.resultId}
                            itemContent={itemContent}
                            maxHeight="8rem"
                            thumbnailHeight="100px"
                            thumbnailWidth="100px"
                          />
                        </Box>
                        <Stack direction="column" spacing="1rem">
                          <Stack direction="row" align="center" spacing="1rem">
                            <Text className="ch-qa-result-answer" fontSize="sm" fontWeight="semibold" color={textColor} pr=".5rem">
                              {itemContent.name}
                            </Text>
                            <SmallActionButton
                              iconTypeName={HiOutlineExternalLink}
                              onClick={() => {
                                if (!itemContent) {
                                  return;
                                } else {
                                  onOpenContent(itemContent, selectedResult.resultId);
                                }
                              }}
                              tooltip={"Open link in new tab"}
                            />
                          </Stack>
                          {!isMobile && (
                            <>
                              <Box>
                                <TagInput tags={itemContent.autoTags} tagType="auto" hideInput allowUpdate={false} allowDelete={false} />
                              </Box>
                              {itemContent.sourceIntegrationUrn && (
                                <VerifiedAIPanelAttribution sourceIntegrationUrn={itemContent.sourceIntegrationUrn} />
                              )}
                            </>
                          )}
                        </Stack>
                      </Stack>
                      {isMobile && (
                        <Stack spacing="0.5rem">
                          <Box>
                            <TagInput tags={itemContent.autoTags} tagType="auto" hideInput allowUpdate={false} allowDelete={false} />
                          </Box>
                          {itemContent.sourceIntegrationUrn && (
                            <VerifiedAIPanelAttribution sourceIntegrationUrn={itemContent.sourceIntegrationUrn} />
                          )}
                        </Stack>
                      )}
                    </Stack>
                    {index !== attributionContentItems.length - 1 && <Divider />}
                  </Box>
                ))}
              </Stack>
              {shareDetails?.accessMode === "read" && (
                <Tooltip
                  label="Upgrade to Professional to access the attributions and source content used for analysis"
                  maxWidth={"15rem"}
                  textAlign={"center"}>
                  <Box position="absolute" top="0" left="0" right="0" bottom="0" bg={disabledBg} opacity={0.7} zIndex={2}>
                    <Box height="100%"></Box>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
      </Stack>
    ),
    [
      isMobile,
      commonTileProps,
      bgColor,
      showPopover,
      textColor,
      selectedResult.questionText,
      selectedResult.focus,
      selectedResult.resultId,
      selectedResult.collectionId,
      selectedResult.resultType,
      selectedResult.resultText,
      selectedResult.confidence,
      focusBadgeColors,
      confidenceValue,
      iconColor,
      isSharedRead,
      isThumbsUpOpen,
      toggleThumbsUp,
      onThumbsUpClose,
      isThumbsDownOpen,
      toggleThumbsDown,
      onThumbsDownClose,
      onOpenConfirmDelete,
      onCloseConfirmDelete,
      isOpenConfirmDelete,
      onVerifiedAIPanelClose,
      searchText,
      attributionContentItems,
      shareDetails?.accessMode,
      disabledBg,
      updateAnswerStatus,
    ]
  );

  return (
    <PanelView
      isOpen={isVerifiedAIPanelOpen}
      onClose={() => {
        onVerifiedAIPanelClose();
      }}
      panelHeader={panelHeader}>
      <Wizard>
        <PanelStep>{panelContent}</PanelStep>
      </Wizard>
    </PanelView>
  );
};
