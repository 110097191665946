import { Badge, Box, Button, Flex, useColorModeValue, useDisclosure, useToast, Icon, Tooltip } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { format, parseISO } from "date-fns";
import { createTicker, exportTickersToCSV, getPaginatedTickers, importTickers, updateTicker } from "api/tickers";
import { CreateTickerPanel } from "./components/CreateTicker";
import { ImportTickersPanel } from "./components/ImportTickersPanel";
import { TickersFilters } from "./components/TickersFilters";
import { TickersFiltersContextProvider, useTickersFiltersContext } from "./contexts/TickersFiltersContext";
import { TickerStatus } from "api/tickers/models/TickerStatus";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { UpdateTickerPanel } from "./components/UpdateTicker";
import { useButtonProps, useInfiniteLoading, useSidebarNavigation, useUserPreference } from "hooks";
import capitalize from "lodash/capitalize";
import React, { useCallback, useEffect, useMemo } from "react";
import type { CreateTickerForm } from "./components/CreateTicker";
import type { CreateTickerRequest } from "api/tickers/models/CreateTickerRequest";
import type { ImportTickersForm } from "./components/ImportTickersPanel";
import type { Ticker } from "api/tickers/models/Ticker";
import type { UpdateTickerForm } from "./components/UpdateTicker";
import type { UpdateTickerRequest } from "api/tickers/models/UpdateTickerRequest";
import { getTickerTypeOption } from "./utils/getTickerTypeOption";
import { ExportTickersPanel } from "./components/ExportTickersPanel";
import { getEnvironment } from "screens/common/app";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { DEFAULT_SIDEBAR_WIDTH } from "screens/landing/components";

const PAGE_SIZE = 40;

interface ColumnConfig {
  id: keyof Ticker | string;
  header: string;
  flex: number;
  centered?: boolean;
  render: (item: Ticker) => React.ReactNode;
}

const TableHeader: React.FC<{
  columns: ColumnConfig[];
  titleColor: string;
  bgColor: string;
}> = React.memo(({ columns, titleColor, bgColor }) => (
  <Box fontSize="small" borderBottomColor={bgColor} borderBottom="2px solid" mb={1} color={titleColor} display="flex" width="100%">
    {columns.map((col) => (
      <Box
        key={col.id}
        flex={col.flex}
        {...(col.centered && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}>
        {col.header}
      </Box>
    ))}
  </Box>
));

const TableRow: React.FC<{
  item: Ticker;
  columns: ColumnConfig[];
  bgColor: string;
  onClick: () => void;
}> = React.memo(({ item, columns, bgColor, onClick }) => (
  <Box onClick={onClick} cursor="pointer" fontSize="sm" _hover={{ bgColor }} display="flex" width="100%" p={1}>
    {columns.map((col) => (
      <Box
        key={col.id}
        flex={col.flex}
        minWidth="0"
        {...(col.centered && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}>
        {col.render(item)}
      </Box>
    ))}
  </Box>
));

const TickersInner = () => {
  const { status, exchange, searchQuery, searchSymbol, type } = useTickersFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen: isOpenImport, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();
  const { isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit } = useDisclosure();
  const { isOpen: isOpenCreate, onClose: onCloseCreate, onOpen: onOpenCreate } = useDisclosure();
  const { isOpen: isOpenExport, onClose: onCloseExport, onOpen: onOpenExport } = useDisclosure();
  const toast = useToast();
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const [tempTicker, setTempTicker] = React.useState<Ticker | undefined>(undefined);
  const sidebarWidth = (useUserPreference("ui_sidebar_width") as number) || (DEFAULT_SIDEBAR_WIDTH as number);
  const { currentSidebarType } = useSidebarNavigation();

  const columns = useMemo<ColumnConfig[]>(
    () => [
      {
        id: "name",
        header: "Name",
        flex: 3,
        render: (item) => <TextOverflowTooltip noOfLines={1} label={item.name} highlightBackground={false} />,
      },
      {
        id: "symbol",
        header: "Symbol",
        flex: 1.5,
        render: (item) => item.symbol,
      },
      {
        id: "exchange",
        header: "Exchange",
        flex: 1.5,
        render: (item) => item.exchange,
      },
      {
        id: "status",
        header: "Status",
        flex: 1,
        render: (item) => (
          <Badge colorScheme={item.status === "beta" ? "blue" : item.status === "active" ? "green" : "red"}>
            {capitalize(item.status)}
          </Badge>
        ),
      },
      {
        id: "type",
        header: "Type",
        flex: 1,
        render: (item) => (
          <TextOverflowTooltip noOfLines={1} label={item.type ? getTickerTypeOption(item.type).label : ""} highlightBackground={false} />
        ),
      },
      {
        id: "notes",
        header: "Notes",
        flex: 2,
        render: (item) => <TextOverflowTooltip breakWord={false} noOfLines={1} label={item.notes ?? ""} highlightBackground={false} />,
      },
      {
        id: "companyLogoUrl",
        header: "Logo",
        flex: 1,
        centered: true,
        render: (item) =>
          item.companyLogoUrl ? (
            <Tooltip label={item.companyLogoUrl} placement="top">
              <Icon as={CheckIcon} color="green.500" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        id: "companyUrl",
        header: "URL",
        flex: 1,
        centered: true,
        render: (item) =>
          item.companyUrl ? (
            <Tooltip label={item.companyUrl} placement="top">
              <Icon as={CheckIcon} color="green.500" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        id: "companyTaxId",
        header: "Tax ID",
        flex: 1,
        centered: true,
        render: (item) =>
          item.companyTaxId ? (
            <Tooltip label={item.companyTaxId} placement="top">
              <Icon as={CheckIcon} color="green.500" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        id: "companyLegalName",
        header: "Legal Name",
        flex: 2,
        centered: true,
        render: (item) =>
          item.companyLegalName ? (
            <Tooltip label={item.companyLegalName} placement="top">
              <Icon as={CheckIcon} color="green.500" />
            </Tooltip>
          ) : (
            ""
          ),
      },
      {
        id: "lastUpdatedDate",
        header: "Updated date",
        flex: 1.5,
        render: (item) => (item.lastUpdatedDate ? format(parseISO(item.lastUpdatedDate), "dd/MMM/yy HH:mm") : ""),
      },
      {
        id: "lastUpdatedByUser",
        header: "Update by",
        flex: 1.5,
        render: (item) => item.lastUpdatedByUser ?? "",
      },
    ],
    []
  );

  const fetchMore = useCallback(
    async (nextToken: string | null) => {
      const validateStatus = TickerStatus.validate(status);

      const response = await getPaginatedTickers({
        ...(nextToken && { token: nextToken }),
        ...(status && validateStatus.success && { status: validateStatus.value }),
        ...(searchQuery && { query: searchQuery }),
        ...(exchange && { exchange }),
        ...(searchSymbol && { symbol: searchSymbol }),
        ...(type && { type }),
        limit: PAGE_SIZE,
      });

      return { data: response.data, nextToken: response.nextToken };
    },
    [status, exchange, searchQuery, searchSymbol, type]
  );

  const {
    loading: isLoading,
    items,
    lastMessageObserverRef: lastMessageObserver,
    hasNextPage,
    clear,
  } = useInfiniteLoading<Ticker>({
    loadItems: fetchMore,
  });

  const handleSubmitExport = (values: { exchange: string }) => {
    setIsLoadingForm(true);

    exportTickersToCSV({ exchange: values.exchange })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${getEnvironment().label}-${values.exchange}-TICKERS.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: "Error exporting tickers",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitImport = (values: ImportTickersForm) => {
    const { file, exchange } = values;

    if (!file) {
      return;
    }

    setIsLoadingForm(true);
    importTickers({ exchange, file })
      .then(() => {
        toast({
          title: "Tickers imported",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseImport();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error importing tickers",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitUpdate = (values: UpdateTickerForm, id?: string) => {
    if (!id) {
      return;
    }

    const payload: UpdateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      status: values.status,
      notes: values.notes || null,
      companyLogoUrl: values.companyLogoUrl || null,
      companyUrl: values.companyUrl || null,
      companyTaxId: values.companyTaxId || null,
      companyLegalName: values.companyLegalName || null,
      ...(values.type !== "null" && { type: values.type }),
    };

    setIsLoadingForm(true);
    updateTicker({ id, ticker: payload })
      .then(() => {
        toast({
          title: "Ticker updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseEdit();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error updating ticker",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitCreate = (values: CreateTickerForm) => {
    const payload: CreateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      symbol: values.symbol,
      type: values.type,
      notes: values.notes || null,
      companyLogoUrl: values.companyLogoUrl || null,
      companyUrl: values.companyUrl || null,
      companyTaxId: values.companyTaxId || null,
      companyLegalName: values.companyLegalName || null,
    };

    setIsLoadingForm(true);
    createTicker({ ticker: payload })
      .then(() => {
        toast({
          title: "Ticker created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseCreate();
        clear();
      })
      .catch((error: Error) => {
        toast({
          title: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  useEffect(() => {
    clear();
  }, [clear, status, exchange, searchQuery, searchSymbol, type]);

  return (
    <>
      <ImportTickersPanel isLoading={isLoadingForm} onSubmit={handleSubmitImport} isOpen={isOpenImport} onClose={onCloseImport} />
      <ExportTickersPanel isLoading={isLoadingForm} onSubmit={handleSubmitExport} isOpen={isOpenExport} onClose={onCloseExport} />
      <TickersFilters isLoading={isLoading} />
      <UpdateTickerPanel
        isLoading={isLoadingForm}
        ticker={tempTicker}
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        onSubmit={handleSubmitUpdate}
      />

      <CreateTickerPanel
        isLoading={isLoadingForm}
        onSubmit={(values) => handleSubmitCreate(values)}
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
      />

      <Flex mb="1rem">
        <Button mr="0.5rem" onClick={() => onOpenImport()} {...commonButtonProps}>
          Import Tickers
        </Button>
        <Button mr="0.5rem" onClick={() => onOpenExport()} {...commonButtonProps}>
          Export Tickers
        </Button>
        <Button onClick={() => onOpenCreate()} {...commonButtonProps}>
          Create Ticker
        </Button>
      </Flex>

      {/* Table */}
      <Box overflowX="auto">
        <Box width={`calc(100vw - ${currentSidebarType === "hidden" ? 0 : sidebarWidth + 30}px)`}>
          <TableHeader columns={columns} titleColor={titleColor} bgColor={bgColor} />

          {items.map((item) => (
            <TableRow
              key={item.id}
              item={item}
              columns={columns}
              bgColor={bgColor}
              onClick={() => {
                setTempTicker(item);
                onOpenEdit();
              }}
            />
          ))}

          {hasNextPage && (
            <Flex visibility={isLoading ? "visible" : "hidden"} height="50vh" ref={lastMessageObserver} align="center" justify="center">
              <TypingIndicator size="small" />
            </Flex>
          )}
        </Box>
      </Box>
    </>
  );
};

export const Tickers = () => {
  return (
    <TickersFiltersContextProvider>
      <TickersInner />
    </TickersFiltersContextProvider>
  );
};
