import { useCallback, useEffect, useState } from "react";
import type { CSSObject } from "@emotion/react";
import { useColorModeValue } from "@chakra-ui/react";

type ScrollbarStyle = CSSObject;

interface CustomScrollbarProps {
  width?: string;
  barTransparency?: number;
  direction?: "right" | "left";
}

export const useCustomScrollbar = (
  ref: React.RefObject<HTMLElement>,
  { width = "5px", barTransparency = 0.15, direction = "right" }: CustomScrollbarProps = {}
) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const baseColor = useColorModeValue("0, 0, 0", "255, 255, 255");

  const handleScroll = useCallback(() => {
    setIsScrolling(true);
    const scrollTimer = setTimeout(() => setIsScrolling(false), 600);
    return () => clearTimeout(scrollTimer);
  }, []);

  useEffect(() => {
    const container = ref.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll, ref]);

  const scrollbarStyle: ScrollbarStyle = {
    "--scrollbar-width": width,
    overflow: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: `rgba(${baseColor}, ${barTransparency}) transparent`,

    "&::-webkit-scrollbar": {
      width: "var(--scrollbar-width)",
      ...(direction === "left" && {
        left: 0,
      }),
    },

    direction: direction === "left" ? "rtl" : "ltr",
    "& > *": {
      direction: "ltr",
    },

    "&::-webkit-scrollbar-track": {
      width: "var(--scrollbar-width)",
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      width: "var(--scrollbar-width)",
      background: isScrolling ? `rgba(${baseColor}, ${barTransparency * 1.5})` : `rgba(${baseColor}, ${barTransparency})`,
      borderRadius: "5px",
    },

    "&:hover::-webkit-scrollbar": {
      width: "var(--scrollbar-width)",
    },

    "&:hover::-webkit-scrollbar-track": {
      width: "var(--scrollbar-width)",
    },

    "&:hover::-webkit-scrollbar-thumb": {
      width: "var(--scrollbar-width)",
    },

    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  };

  return { scrollbarStyle, isScrolling };
};
