import type { JSONSchema7 } from "json-schema";

export const PreferenceValueSchema: JSONSchema7 = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "https://charli.ai/schemas/PreferenceValue.json",
  type: ["string", "boolean", "number", "array", "object"],
  oneOf: [
    {
      type: "array",
      items: {
        type: "string",
      },
    },
    {
      type: "object",
      additionalProperties: {
        type: "string",
      },
    },
    {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string" },
          question: { type: "string" },
          focus: {
            type: "string",
          },
        },
        required: ["id", "question", "focus"],
        additionalProperties: false,
      },
    },
  ],
};
