import { Array } from "runtypes";
import type { Static } from "runtypes";
import { SourceWeightsArrayItem } from "./SourceWeightsArrayItem";

export const SourceWeightsArray = Array(SourceWeightsArrayItem).withConstraint((sourceWeightsArray) => {
  // Allow 2 or 3 items in the array
  return sourceWeightsArray.length >= 2 && sourceWeightsArray.length <= 3;
});

export type SourceWeightsArray = Static<typeof SourceWeightsArray>;
