import { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useProjectParams } from "./useCollections";
import { useUserPreference } from "./useUserPreferences";
import { useConfigMap, useGetViewConfig } from "./useViewConfig";
import { isEqual } from "lodash";

export const useAnswerFocusOptions = (collectionType: string | undefined) => {
  const { pathname } = useLocation();
  const { projectFilter } = useProjectParams();
  const isHome = pathname.includes("/home");

  const configMap = useConfigMap();
  const effectiveCollectionType = useMemo(
    () => collectionType ?? projectFilter ?? (isHome ? "due_diligence" : ""),
    [collectionType, projectFilter, isHome]
  );

  const answerFocusOptions = useGetViewConfig("answerFocusOptions", effectiveCollectionType, configMap);

  const userPreferenceSourceWeight = useUserPreference("source_weights_index") as string;
  const prevOptionsRef = useRef(answerFocusOptions);
  const defaultSourceWeight = useMemo(() => {
    if (!isEqual(prevOptionsRef.current, answerFocusOptions)) {
      prevOptionsRef.current = answerFocusOptions;
      return answerFocusOptions && answerFocusOptions.findIndex((sourceWeight) => sourceWeight.default === true);
    }
    return prevOptionsRef.current && prevOptionsRef.current.findIndex((sourceWeight) => sourceWeight.default === true);
  }, [answerFocusOptions]);

  const selectedSourceWeightsIndex = useMemo(
    () => userPreferenceSourceWeight ?? defaultSourceWeight ?? "",
    [userPreferenceSourceWeight, defaultSourceWeight]
  );

  return {
    answerFocusOptions,
    selectedSourceWeightsIndex,
  };
};
