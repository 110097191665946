import type { Collection } from "types/collection";
import type { StockTickerData } from "api/stockTickerData";
import type { StockMarketChartData } from "../StockMarketChart/StockMarketChart";

type GetStockDataFn = (companyTicker: string, companyStockExchange: string) => StockTickerData | undefined;

export const generateTradingValueData = (
  groupedCollections: Collection[] | undefined,
  getStockData?: GetStockDataFn
): StockMarketChartData[] => {
  if (!groupedCollections) return [];

  return groupedCollections
    .map((collection) => {
      const { ticker, stockExchange, fairMarketValueAnalytical, fairMarketValueSentiment, id } = collection || {};

      if (!ticker || !stockExchange) return null;

      const stockData = getStockData?.(ticker, stockExchange);
      const analyticalValue = fairMarketValueAnalytical?.value ?? "";
      const sentimentValue = fairMarketValueSentiment?.value ?? "";

      const regexFairMarketValueAnalytical = analyticalValue.match(/\d+(\.\d+)?/g);
      const regexFairMarketValueSentiment = sentimentValue.match(/\d+(\.\d+)?/g);

      const price = parseFloat(stockData?.ticker_quote?.price ?? "0");
      const fairValueLow = Number(regexFairMarketValueAnalytical?.[0] ?? 0);
      const fairValueHigh = Number(regexFairMarketValueSentiment?.[0] ?? 0);

      return {
        projectId: id,
        ticker,
        currentPrice: price,
        fairValueLow,
        fairValueHigh,
      };
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);
};
