import React, { useRef, useEffect, useMemo, useCallback, useState } from "react";
import { Box, Flex, Tooltip, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { SemanticFocusedAnswer } from "screens/collection/views/SemanticFocusedAnswer";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import { useConversationDialogSuggestionLists } from "./useConversationDialogSuggestionLists";
import { useConversationDialogSubmitFunctions } from "./useConversationDialogSubmitFunctions";
import { useEntitlementKey, useProjectParams } from "hooks";
import { useLocation } from "react-router-dom";
import { useConversationContext } from "./ConversationContext";
import type { SuggestedQuestion } from "api/suggestions";
import { ProjectQuestionsProvider } from "screens/collection/ProjectQuestionsContext";
import AutocompleteSuggestionWrapper from "screens/collection/views/AutocompleteSuggestion";

export type ConversationInputIds = "conversation-input" | "view-input";

interface Props {
  initialText?: string;
  className?: string;
  inputId: ConversationInputIds;
  minWidth?: string;
  minWidthList?: string;
  marginLeftList?: string;
  afterSubmit?: () => void;
  conversationId?: string;
  renderRole?: "cli" | "embedded";
  portfolioId?: string;
  defaultIsOpen?: boolean;
  disableComponent?: boolean;
  tooltip?: string;
  showToast?: boolean;
}

export const ConversationDialogInputInner: React.FC<Props> = React.memo(
  ({
    initialText,
    className,
    inputId,
    minWidth,
    minWidthList,
    marginLeftList,
    afterSubmit,
    conversationId,
    renderRole = "cli",
    portfolioId,
    defaultIsOpen,
    disableComponent,
    tooltip,
    showToast,
  }) => {
    const { projectId } = useProjectParams();
    const inputRef = useRef<HTMLInputElement>(null);
    const { pathname } = useLocation();
    const {
      showCommands,
      setShowCommands,
      onConversationClose,
      initialQuestionText,
      setInitialQuestionText,
      setInitialQuestionFocus,
      initialQuestionFocus,
      focusedInputId,
      setFocusedInputId,
    } = useConversationContext();

    // Local state version of question focus
    const [questionText, setQuestionText] = useState(initialQuestionText);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

    const {
      hasExceededMaxProjects,
      currentCollectionType,
      maxAllowedProjects,
      currentWorkflowId,
      workflowStatus,
      currentWorkflowCompletionDate,
      canBypassDisabled,
      setCanBypassDisabled,
    } = useConversationDialogUtils();

    const {
      isLoading,
      currentSuggestionList,
      currentSuggestionListType,
      isInputDisabled: isBaseInputDisabled,
      answerFocusOptions,
      onResetQuestion,
      debouncedTickerFilter,
      activeFilter,
      setActiveFilter,
    } = useConversationDialogSuggestionLists({
      questionText,
      setQuestionText,
      maxSuggestions: pathname.includes("/register") ? 5 : 60,
    });

    const isInputDisabled = isBaseInputDisabled || (focusedInputId !== inputId && focusedInputId !== undefined);

    useEffect(() => {
      if (workflowStatus === "in_progress") {
        setIsSubmitDisabled(true);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      } else {
        setIsSubmitDisabled(false);
      }
    }, [workflowStatus]);

    const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");
    const canBypassUsageLimits = useEntitlementKey("bypass_usage_limits");

    const { currentSubmitFunction, onSubmitDueDiligenceProject, currentConversationID, isConversationOpen } =
      useConversationDialogSubmitFunctions({
        questionText,
        afterSubmit,
        conversationId,
        mode: renderRole === "cli" ? renderRole : "suggested",
        showToast,
      });

    useEffect(() => {
      if (currentSuggestionListType !== "tickers" || questionText?.startsWith("/")) return;
      debouncedTickerFilter(questionText);
    }, [debouncedTickerFilter, currentSuggestionListType, questionText]);

    useEffect(() => {
      setQuestionText(initialQuestionText ? initialQuestionText : questionText);
    }, [pathname, setQuestionText, initialQuestionText, questionText]);

    useEffect(() => {
      if (questionText !== initialQuestionText) {
        setInitialQuestionText("");
      }
    }, [questionText, initialQuestionText, setInitialQuestionText, pathname]);

    useEffect(() => {
      !isInputDisabled && onResetQuestion();
    }, [isInputDisabled, onResetQuestion, pathname]);

    useEffect(() => {
      if (hasCommandLineEntitlement && isConversationOpen && inputId === "conversation-input" && showCommands) {
        setQuestionText("/");
      }
      setShowCommands(false);
    }, [inputId, isConversationOpen, setShowCommands, showCommands, setQuestionText, hasCommandLineEntitlement]);

    useEffect(() => {
      const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === "Escape" && isConversationOpen && questionText?.length === 0 && inputId === "conversation-input") {
          onConversationClose();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [inputId, isConversationOpen, onConversationClose, questionText]);

    const errorMessage = useMemo(() => {
      const commonStyles = {
        fontSize: "sm",
        bgColor: "orange.100",
        borderRadius: "md",
        p: "8px",
        lineHeight: "1.4rem",
      };

      if (!canBypassUsageLimits && hasExceededMaxProjects) {
        return (
          <Box {...commonStyles}>
            {`You can only have ${maxAllowedProjects} active ${currentCollectionType?.replace(/_/g, " ")} projects at a time`}
          </Box>
        );
      }

      return null;
    }, [canBypassUsageLimits, hasExceededMaxProjects, maxAllowedProjects, currentCollectionType]);

    const handleChange = useCallback((question: string) => {
      setQuestionText(question);
    }, []);

    const handleSelectOption = useCallback(
      (selectedQuestion: SuggestedQuestion) => {
        const { question, focus, matchFilter, type } = selectedQuestion;
        const isQuestionFocus = answerFocusOptions?.some((option) => option.key === focus);

        if (questionText?.startsWith("/")) {
          setQuestionText(question);
        } else if (projectId && isQuestionFocus) {
          setInitialQuestionFocus(focus);
          setQuestionText(question);
        } else {
          const stockExchange = focus && ["analytical", "sentiment"].includes(focus) ? undefined : focus;
          matchFilter &&
            onSubmitDueDiligenceProject(
              matchFilter[0].toUpperCase(),
              stockExchange,
              afterSubmit,
              undefined,
              portfolioId,
              type,
              undefined,
              undefined,
              showToast
            );
          setQuestionText("");
        }
      },
      [
        answerFocusOptions,
        questionText,
        projectId,
        setInitialQuestionFocus,
        onSubmitDueDiligenceProject,
        afterSubmit,
        portfolioId,
        showToast,
      ]
    );

    const handleClickSubmit = useCallback(
      (selectedQuestion?: SuggestedQuestion) => {
        // TODO: we need the workflowId from conversations started on landing pages so we can setIsSubmitDisabled(false)
        projectId && setIsSubmitDisabled(true);
        const { question, focus } = selectedQuestion || {};

        const currentQuestion = question || questionText?.trim();
        const currentFocus = focus || initialQuestionFocus;

        if (isInputDisabled || !currentQuestion) return;
        currentSubmitFunction(currentQuestion, currentFocus);
        setQuestionText("");
      },
      [projectId, questionText, initialQuestionFocus, isInputDisabled, currentSubmitFunction]
    );

    const handleFocus = useCallback(() => {
      setFocusedInputId(inputId);
    }, [inputId, setFocusedInputId]);

    useEffect(() => {
      if (inputRef.current && focusedInputId === inputId) {
        inputRef.current.focus();
      }
    }, [focusedInputId, inputId]);

    const handleBlur = useCallback(() => {
      isConversationOpen && setFocusedInputId(isConversationOpen ? "conversation-input" : "view-input");
    }, [isConversationOpen, setFocusedInputId]);
    const disabledBg = useColorModeValue("whiteAlpha.700", "blackAlpha.400");

    return (
      <Box position="relative">
        {isConversationOpen && !projectId && (
          <Box pl=".5rem" bottom="3rem" zIndex={2} position="absolute" width="calc(100% - 5rem)" ml="2.5rem" mr="3rem">
            {errorMessage}
          </Box>
        )}
        <Box position="relative">
          <Box bg="transparent" borderRadius={"30px 30px 30px 30px!important"} border="none" minWidth={minWidth || "33rem"}>
            <AutocompleteSuggestionWrapper
              inputId={inputId}
              externalInputRef={inputRef}
              className={className}
              minWidthList={minWidthList || "22rem"}
              marginLeftList={marginLeftList}
              conversationId={conversationId || currentConversationID}
              value={isInputDisabled ? "" : questionText}
              onChange={handleChange}
              onReset={onResetQuestion}
              initialText={workflowStatus === "clarification_needed" ? "Respond to Charli..." : initialText}
              suggestedQuestions={workflowStatus === "clarification_needed" || isInputDisabled ? [] : currentSuggestionList}
              suggestionListType={disableComponent ? undefined : currentSuggestionListType}
              isInputDisabled={isInputDisabled || isSubmitDisabled}
              isSubmitDisabled={isInputDisabled || isSubmitDisabled}
              onSelectOption={handleSelectOption}
              onClickSubmit={handleClickSubmit}
              isLoading={isLoading}
              conversationState={workflowStatus}
              canBypassDisabled={canBypassDisabled}
              setCanBypassDisabled={setCanBypassDisabled}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disableFilter={currentSuggestionListType === "tickers"}
              defaultIsOpen={defaultIsOpen}
              disableComponent={disableComponent}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>
          {disableComponent && (
            <Tooltip label={tooltip || "Input disabled"} maxWidth={"15rem"} textAlign={"center"}>
              <Box
                position="absolute"
                className="ch-input-disabled-overlay"
                top={"-10px"}
                left={"-6px"}
                right={"-6px"}
                bottom={"-9px"}
                bg={disabledBg}
                opacity={0.7}
                borderRadius="30px"
                cursor="not-allowed"
                zIndex={1}
              />
            </Tooltip>
          )}
        </Box>
        {projectId && !pathname.includes("/panel") && (
          <Box height="1rem" width="100%" mt="5px">
            {!isConversationOpen && currentWorkflowId && workflowStatus === "in_progress" ? (
              <Box pr="1rem" pl="10rem" justifyContent="end" width="100%">
                <WorkflowProgressBar
                  workflowId={currentWorkflowId}
                  lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                    addSuffix: true,
                    includeSeconds: false,
                  })}
                  size="compact"
                  showInline={false}
                />
              </Box>
            ) : answerFocusOptions ? (
              <Flex justifyContent={isMobile ? "center" : "flex-end"}>
                <SemanticFocusedAnswer
                  isDisabled={isSubmitDisabled || isInputDisabled || currentSuggestionListType === "commands" || disableComponent}
                  answerFocusOptions={answerFocusOptions}
                  initialQuestionFocus={initialQuestionFocus}
                  setInitialQuestionFocus={(focus) => setInitialQuestionFocus(focus)}
                />
              </Flex>
            ) : null}
          </Box>
        )}
        {!isConversationOpen && !projectId && (
          <Box ml="3rem" position="absolute" width="30rem" boxShadow="xl">
            {errorMessage}
          </Box>
        )}
      </Box>
    );
  }
);

export const ConversationDialogInput: React.FC<Props> = React.memo(({ disableComponent, tooltip, showToast, ...props }) => {
  const { projectId } = useProjectParams();

  return (
    <ProjectQuestionsProvider collectionId={projectId}>
      <ConversationDialogInputInner disableComponent={disableComponent} tooltip={tooltip} showToast={showToast} {...props} />
    </ProjectQuestionsProvider>
  );
});
