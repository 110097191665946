import { CloseIcon } from "@chakra-ui/icons";
import {
  Tooltip,
  Box,
  IconButton,
  Center,
  useDisclosure,
  Image,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Icon,
} from "@chakra-ui/react";
import { useUserPreference, useUserProfile } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";
import onboarding_video_link from "screens/common/static/images/onboarding_video_link.png";
import { FaPlay } from "react-icons/fa";
import { track } from "api/analytics";
import { ONBOARDING_VIDEO_PLAYED } from "api/analytics/events";

export interface Link {
  stepNumber?: number;
  stepColor?: string;
  stepText?: string;
  stepTitle?: string;
  thumbnailSource?: string;
  url?: string;
  tooltip?: string;
}

interface OnboardingVideoProps {
  videoURL: string;
  maxImageWidth?: string;
  showCloseButton?: boolean;
  onCloseVideo?: () => void;
}

const OnboardingVideo: React.FC<OnboardingVideoProps> = ({ videoURL, maxImageWidth = "10rem", showCloseButton = true, onCloseVideo }) => {
  const showOnboardingVideos = useUserPreference("ui_hide_onboarding_videos") as boolean;
  const dispatch = useDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { fullName = "", email = "" } = useUserProfile();

  const onCloseHandler = () => {
    onCloseVideo && onCloseVideo();
    onClose();
    !showOnboardingVideos && dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
  };

  const onClickHandler = () => {
    dispatch(updateTypedUserPreference({ preferenceKey: "ui_hide_onboarding_videos", value: false }));
  };

  const handleVideoPlayed = () => {
    track(ONBOARDING_VIDEO_PLAYED, { userName: fullName, email });
    onOpen();
  };

  return (
    <>
      <Box
        position={"relative"}
        height={"100%"}
        maxWidth={maxImageWidth}
        width="100%"
        className="ch-sidebar-onboarding-videos"
        paddingInline={"0"}>
        {showCloseButton && (
          <Tooltip label="Hide videos" aria-label="Close" placement="top">
            <IconButton
              position={"absolute"}
              zIndex={1}
              right="-0.8rem"
              top="-0.5rem"
              p=".3rem"
              aria-label=""
              borderRadius={"lg"}
              bgColor={"gray.200"}
              icon={<CloseIcon color={"gray.500"} boxSize="10px" borderRadius={"full"} />}
              size="xs"
              onClick={(event) => {
                onClickHandler();
                event.stopPropagation();
              }}
            />
          </Tooltip>
        )}
        <Center boxShadow={"xs"} position="relative" _hover={{ "& > .play-icon": { transform: "translate(-50%, -50%) scale(1.3)" } }}>
          <Image
            src={onboarding_video_link}
            alt="help video"
            width="100%"
            height="100%"
            objectFit="cover"
            onClick={handleVideoPlayed}
            cursor="pointer"
            borderRadius="0.375rem"
          />
          <Box
            className="play-icon"
            position="absolute"
            top="50%"
            left="75%"
            transform="translate(-50%, -50%)"
            bg="transparent"
            onClick={handleVideoPlayed}
            cursor="pointer"
            transition="transform 0.2s ease-in-out">
            <Icon as={FaPlay} color="#4bb3d7" boxSize={"2rem"} />
          </Box>
        </Center>
      </Box>
      <Modal isOpen={isOpen} onClose={onCloseHandler} isCentered closeOnOverlayClick closeOnEsc>
        <ModalOverlay />
        <ModalContent
          maxWidth={"unset"}
          width={["100vw", "95vw", "95vw"]}
          height={["100vw", "95vh", "95vh"]}
          backgroundColor={"transparent"}
          border="none"
          boxShadow={"none"}>
          <ModalCloseButton backgroundColor={"white"} />
          <iframe
            src={videoURL}
            title="Interacting with Charli"
            style={{
              width: "100%",
              height: "100%",
              colorScheme: "light",
              borderRadius: "0.375rem",
              border: "none",
              display: "block",
            }}
            sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
            allow="clipboard-write; autoplay"
            allowFullScreen
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardingVideo;
