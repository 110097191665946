import { Button, FormControl, Input, Link, Stack, Text, Grid, FormHelperText, Select, useColorModeValue } from "@chakra-ui/react";
import { validate as validateEmail } from "email-validator";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useButtonProps } from "hooks";
import { FormErrorLabel } from "screens/panels/settings/tabs/subscription/SubscribeForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCopyValue } from "hooks/useCopies";

type InvestmentInterestType = "Investment Advisor" | "Wealth Manager" | "Investor" | "Equity Analyst" | "Venture Capital" | "Other";

interface FormData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  investmentInterest: InvestmentInterestType;
}

interface Props {
  onSubmit: (fields: FormData) => void;
  isCheckingAccount?: boolean;
  usernameError?: string;
}

// Regular expression to check for HTML tags
const noHtmlTags = /<\/?[^>]+(>|$)/g;
const passwordLength = 8;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name cannot exceed 50 characters")
    .test("no-html-tags", "Name is not valid", (value) => !noHtmlTags.test(value)),
  lastName: yup
    .string()
    .required("Last name is required")
    .min(3, "Last name must be at least 3 characters")
    .max(50, "Last name cannot exceed 50 characters")
    .test("no-html-tags", "Last name is not valid", (value) => !noHtmlTags.test(value)),
  username: yup
    .string()
    .required("Email is required")
    .email("Email must be a valid email address")
    .test("email", "Email must be a valid email address", (value) => validateEmail(value)),
  password: yup
    .string()
    .required("Password is required")
    .min(passwordLength, `Password must be at least ${passwordLength} characters`)
    .max(64, "Password must be less than 65 characters"),
  investmentInterest: yup
    .string()
    .oneOf(
      ["Investment Advisor", "Wealth Manager", "Investor", "Equity Analyst", "Venture Capital", "Other"],
      "Please select a valid investment interest"
    )
    .transform((value) => value || "Other") as yup.Schema<InvestmentInterestType>,
});

export const RegisterForm = ({ onSubmit, isCheckingAccount, usernameError: propUsernameError }: Props) => {
  const [localUsernameError, setLocalUsernameError] = useState(propUsernameError);
  const registrationSubmitButton = useCopyValue("copy_registration_submit_button");

  // Color mode values
  const inputBgColor = useColorModeValue("white", "gray.700");
  const helperTextColor = useColorModeValue("gray.500", "gray.400");
  const buttonHoverBgColor = useColorModeValue("#81c34c", "#6ba33f");
  const buttonHoverBorderColor = useColorModeValue("#81c34c", "#6ba33f");
  const linkColor = useColorModeValue("primary.500", "primary.300");

  useEffect(() => {
    setLocalUsernameError(propUsernameError);
  }, [propUsernameError]);

  useEffect(() => {
    // Create and inject LinkedIn partner ID script
    const partnerScript = document.createElement("script");
    partnerScript.type = "text/javascript";
    partnerScript.text = `
      _linkedin_partner_id = "6779724";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
    document.body.appendChild(partnerScript);

    // Create and inject LinkedIn tracking script
    const trackingScript = document.createElement("script");
    trackingScript.type = "text/javascript";
    trackingScript.text = `
      (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    `;
    document.body.appendChild(trackingScript);

    // Create and inject noscript pixel
    const noscript = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = 1;
    img.width = 1;
    img.style.display = "none";
    img.alt = "";
    img.src = "https://px.ads.linkedin.com/collect/?pid=6779724&fmt=gif";
    noscript.appendChild(img);
    document.body.appendChild(noscript);

    // Cleanup function
    return () => {
      document.body.removeChild(partnerScript);
      document.body.removeChild(trackingScript);
      document.body.removeChild(noscript);
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const commonButtonProps = useButtonProps("md", "cta");

  return (
    <Stack width="100%" spacing={6}>
      <form
        onSubmit={(e) => {
          if (isCheckingAccount) {
            e.preventDefault();
            return;
          }
          handleSubmit((data) => {
            const formData = {
              ...data,
              investmentInterest: data.investmentInterest || "Other",
            };
            onSubmit(formData);
          })(e);
        }}
        noValidate>
        <Stack spacing={6}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <FormControl isInvalid={!!errors.firstName}>
              <Input
                {...register("firstName")}
                id="register-firstName"
                type="text"
                placeholder="First Name"
                size="md"
                bg={inputBgColor}
                onFocus={() => errors.firstName && resetField("firstName")}
              />
              {errors.firstName?.message && <FormErrorLabel error={errors.firstName.message} />}
            </FormControl>
            <FormControl isInvalid={!!errors.lastName}>
              <Input
                {...register("lastName")}
                id="register-lastName"
                type="text"
                placeholder="Last Name"
                size="md"
                bg={inputBgColor}
                onFocus={() => errors.lastName && resetField("lastName")}
              />
              {errors.lastName?.message && <FormErrorLabel error={errors.lastName.message} />}
            </FormControl>
          </Grid>
          <FormControl isInvalid={!!errors.username || !!localUsernameError}>
            <Input
              {...register("username")}
              id="register-username"
              type="email"
              placeholder="Email Address"
              size="md"
              bg={inputBgColor}
              onFocus={() => {
                if (errors.username || localUsernameError) {
                  resetField("username");
                  setLocalUsernameError("");
                }
              }}
            />
            {(errors.username?.message || localUsernameError) && (
              <FormErrorLabel error={errors.username?.message || localUsernameError || ""} />
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.password}>
            <Input
              {...register("password")}
              id="register-password"
              type="password"
              placeholder="Password"
              size="md"
              bg={inputBgColor}
              autoComplete="new-password"
              onFocus={() => errors.password && resetField("password")}
            />
            <FormHelperText color={helperTextColor} fontSize="xs" mt={1} ml="1rem">
              Must be at least {passwordLength} characters long
            </FormHelperText>
            {errors.password?.message && <FormErrorLabel error={errors.password.message} />}
          </FormControl>

          <FormControl isInvalid={!!errors.investmentInterest}>
            <Select
              {...register("investmentInterest")}
              id="register-investment-interest"
              placeholder="Select your investment focus"
              size="md"
              bg={inputBgColor}
              onFocus={() => errors.investmentInterest && resetField("investmentInterest")}>
              <option value="Investment Advisor">Investment Advisor</option>
              <option value="Wealth Manager">Wealth Manager</option>
              <option value="Investor">Investor</option>
              <option value="Equity Analyst">Equity Analyst</option>
              <option value="Venture Capital">Venture Capital</option>
              <option value="Other">Other</option>
            </Select>
            {errors.investmentInterest?.message && <FormErrorLabel error={errors.investmentInterest.message} />}
          </FormControl>

          <Button
            {...commonButtonProps}
            _hover={{ bgColor: buttonHoverBgColor, borderColor: buttonHoverBorderColor }}
            type="submit"
            width="100%"
            size="lg"
            borderRadius="full"
            id="register-submit"
            disabled={isCheckingAccount}
            borderColor={isCheckingAccount ? "#81c34c" : "primary.default"}
            bgColor={isCheckingAccount ? "#81c34c" : "primary.default"}
            opacity={isCheckingAccount ? 0.6 : 1}
            cursor={isCheckingAccount ? "default" : "pointer"}>
            {isCheckingAccount ? (
              <Text as="span" position="relative">
                CREATING ACCOUNT
                <Text
                  as="span"
                  sx={{
                    "@keyframes ellipsis": {
                      "0%": { content: "''" },
                      "25%": { content: "'.'" },
                      "50%": { content: "'..'" },
                      "75%": { content: "'...'" },
                    },
                    "&::after": {
                      content: "''",
                      animation: "ellipsis 1.2s steps(4, end) infinite",
                      position: "absolute",
                      display: "inline-block",
                      marginLeft: "2px",
                      width: "12px",
                    },
                  }}></Text>
              </Text>
            ) : (
              registrationSubmitButton
            )}
          </Button>
        </Stack>
      </form>
      <Text fontSize="xs" color={helperTextColor} textAlign="center" px="3rem">
        By signing up you agree to Charli's{" "}
        <Link href="https://charliai.com/terms-and-services/" isExternal color={linkColor}>
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href="https://charliai.com/privacy-policy/" isExternal color={linkColor}>
          Privacy Policy
        </Link>
      </Text>
    </Stack>
  );
};
