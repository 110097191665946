import type { PropsWithChildren } from "react";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  Icon,
  Box,
  Stack,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Tooltip,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import type { IconType } from "react-icons";
import { track } from "api/analytics";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import {
  useCollection,
  useCollectionKey,
  useEntitlementKey,
  useItemSelector,
  useProjectParams,
  useSidebarNavigation,
  useUserProfile,
} from "hooks";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import { IoEllipsisVertical } from "react-icons/io5";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { MdDragIndicator } from "react-icons/md";
import { SharePortfolioButton } from "screens/collection/views/ProjectActions/SharePortfolioButton";
import { FiShare2 } from "react-icons/fi";

interface Props {
  text?: string;
  tooltip?: string;
  icon?: IconType;
  iconSize?: string;
  screen?: string;
  routeParams?: string[];
  onClick?: () => void;
  analyticsEvent?: string;
  cssClasses?: string[];
  style?: React.CSSProperties;
  isSelected?: boolean;
  isDisabled?: boolean;
  isChildProject?: boolean;
  menuProjectId?: string;
  isStatic?: boolean;
}

export const SidebarButton = React.forwardRef<unknown, PropsWithChildren<Props>>(
  (props: PropsWithChildren<Props>, ref: React.Ref<unknown>) => {
    const {
      text,
      tooltip,
      routeParams,
      icon,
      iconSize = "1.2rem",
      screen,
      onClick,
      analyticsEvent,
      cssClasses,
      style,
      isSelected,
      isChildProject = false,
      menuProjectId,
      isStatic,
      children,
    } = props;
    const { projectFilter, projectId, parentRoute } = useProjectParams();
    const navigate = useNavigate();
    const selectedButtonColor = useColorModeValue("charli.primaryBlue", "charli.primaryBlue");
    const buttonColor = useColorModeValue("charli.darkBlue", "gray.300");
    const { onConversationClose, setRequestEntities, setConversationId, setIsAnotherInputFocused } = useContext(ConversationContext);
    const { setIsHeaderTextEditable, setIsEditing, setIsSideNavigationOpen } = useContext(SettingsProviderContext);
    const { resetSelectedItems } = useItemSelector();
    const { setSelectedSidebar, selectedView } = useSidebarNavigation();
    const portfolioProject = useCollection(projectFilter);
    const { onDeleteProjectModalOpen, onSharePortfolioModalOpen } = useContext(ConversationContext);
    const { onPortfolioSettingsOpen } = useAddToCharliContext();
    const [showActionMenu, setShowActionMenu] = React.useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const hasShareEntitlement = useEntitlementKey("enable_share_portfolio");
    const { id: currentUserId } = useUserProfile();
    const currentSharedDetails = useCollectionKey(menuProjectId, "shareDetails");

    const isCurrentProjectShared = useMemo(() => {
      return currentSharedDetails?.ownerId === currentUserId;
    }, [currentSharedDetails?.ownerId, currentUserId]);

    useEffect(() => {
      setSelectedSidebar(portfolioProject?.name || projectFilter || parentRoute || "");
    }, [portfolioProject?.name, projectFilter, parentRoute, setSelectedSidebar, projectId]);

    useEffect(() => {
      if (menuButtonRef.current) {
        if (showActionMenu) {
          menuButtonRef.current.removeAttribute("inert");
        } else {
          menuButtonRef.current.setAttribute("inert", "");
        }
      }
    }, [showActionMenu]);

    const onClickHandler = () => {
      if (analyticsEvent) track(analyticsEvent);
      if (onClick) {
        onClick();
      } else if (screen) {
        const params = routeParams && routeParams.length > 0 ? `/${routeParams.join("/")}` : "";
        navigate(`/${screen}${params}`);
      }
      setRequestEntities([]);
      setConversationId("");
      onConversationClose();
      setIsHeaderTextEditable(false);
      setIsEditing(false);
      resetSelectedItems();
      setIsAnotherInputFocused(false);
      setIsSideNavigationOpen(false);
    };

    const getIsItemSelected = React.useCallback((): boolean => {
      if (isSelected) return true;
      if (projectFilter && menuProjectId && projectFilter.trim() === menuProjectId.trim()) return true;
      if (screen?.includes(selectedView)) return true;
      if (!projectFilter && text?.toLowerCase() === selectedView.toLowerCase()) return true;
      return false;
    }, [isSelected, projectFilter, menuProjectId, screen, selectedView, text]);

    const isItemSelected = getIsItemSelected();

    return (
      <Box
        position="relative"
        width="100%"
        cursor={screen ? "pointer" : "default"}
        borderRadius="none"
        borderWidth="0"
        backgroundColor="unset"
        _hover={{ backgroundColor: "unset", color: selectedButtonColor }}
        _active={{}}
        onClick={onClickHandler}
        onMouseEnter={() => setShowActionMenu(true)}
        onMouseLeave={() => setShowActionMenu(false)}
        className={cssClasses?.join(" ")}
        style={style}>
        <Stack direction="row" align="center" spacing=".3rem" width="100%" justifyContent={"space-between"}>
          <Center>
            <Tooltip offset={[35, 15]} maxWidth={"11rem"} label={tooltip} aria-label={tooltip}>
              <Stack direction={"row"} spacing=".8rem">
                {!isMobile && isChildProject && !isStatic && (
                  <Icon cursor={"grab"} opacity={showActionMenu ? 1 : 0} as={MdDragIndicator} boxSize="1.2rem" />
                )}
                {!isMobile && isChildProject && isStatic && <Box width="1.2rem" />}
                {icon && (
                  <Icon
                    as={icon}
                    fontWeight={isItemSelected ? "bold" : "normal"}
                    color={isItemSelected || showActionMenu ? selectedButtonColor : buttonColor}
                    borderColor={isItemSelected ? selectedButtonColor : buttonColor}
                    boxSize={iconSize}
                  />
                )}
                {text && (
                  <TextOverflowTooltip
                    color={isItemSelected || showActionMenu ? selectedButtonColor : buttonColor}
                    style={{
                      fontWeight: isItemSelected ? "bold" : "normal",
                    }}
                    fontSize={isMobile ? "lg" : "sm"}
                    label={text}
                    breakWord={false}
                  />
                )}
                {isCurrentProjectShared && (
                  <Center>
                    <Icon
                      as={FiShare2}
                      fontWeight={isItemSelected ? "bold" : "normal"}
                      color={isItemSelected || showActionMenu ? selectedButtonColor : buttonColor}
                      borderColor={isItemSelected ? selectedButtonColor : buttonColor}
                      boxSize={"10px"}
                    />
                  </Center>
                )}
              </Stack>
            </Tooltip>
          </Center>
          <Stack direction={"row"} spacing="5px">
            {children}
            {isChildProject && !isMobile && !isStatic && (
              <Menu isLazy>
                {showActionMenu && (
                  <MenuButton
                    ref={menuButtonRef}
                    className="ch-sidebar-options-menu"
                    height={"1rem"}
                    width={"1.2rem"}
                    pr="10px"
                    _hover={{ color: "primary.default" }}
                    _active={{}}
                    variant="ghost"
                    as={IconButton}
                    icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                    onClick={(event) => event.stopPropagation()}
                    minWidth="0"
                  />
                )}
                <Portal>
                  <MenuList
                    minWidth={"unset"}
                    zIndex={10000}
                    onMouseEnter={() => setShowActionMenu(true)}
                    onMouseLeave={() => setShowActionMenu(false)}>
                    <OptionsMenuItem
                      menuLabel="Portfolio Settings"
                      className="ch-open-project-settings"
                      onClick={() => {
                        onPortfolioSettingsOpen();
                      }}
                    />
                    {menuProjectId && (
                      <>
                        {hasShareEntitlement && <SharePortfolioButton projectId={menuProjectId} onClick={onSharePortfolioModalOpen} />}
                        <ProjectDeleteButton
                          className="ch-delete-portfolio-sidebar"
                          onOpenConfirmation={onDeleteProjectModalOpen}
                          projectId={menuProjectId}
                          projectType="portfolio"
                          isMenuItem
                          navigatePath={projectFilter === menuProjectId ? "/home" : undefined}
                        />
                      </>
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }
);
