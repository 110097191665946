import { DeleteIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useButtonProps } from "hooks";
import React from "react";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import type { BillingFormValues } from "../utils/BillingFormValues";

export const PlanDetailsField = () => {
  const commonButtonProps = useButtonProps("sm", "secondary");
  const {
    control,
    formState: { errors },
  } = useFormContext<BillingFormValues>();
  const { fields: componentsFields, insert, remove } = useFieldArray({ control, name: "components" });
  const watchedComponents = useWatch({ control, name: "components" });

  const controlledComponentsFields = componentsFields.map((component, index) => ({
    ...component,
    ...watchedComponents[index],
  }));

  return (
    <Box>
      <Controller
        name="productHandle"
        control={control}
        render={({ field }) => (
          <FormControl mb={"1rem"} isInvalid={!!errors[field.name]?.message}>
            <FormLabel htmlFor={field.name}>Product Handle</FormLabel>
            <Input {...field} type="text" />
            {!!errors[field.name]?.message && <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>}
          </FormControl>
        )}
      />

      <Button
        mb={"1rem"}
        onClick={() =>
          insert(0, {
            componentHandle: "",
            quantityToAllocate: 1,
            pricePointHandle: "",
          })
        }
        {...commonButtonProps}>
        Add Component
      </Button>
      {errors.components?.message && (
        <Alert status="error">
          <AlertIcon />
          {errors.components.message}
        </Alert>
      )}

      {controlledComponentsFields.map((component, index) => {
        const maybeErrors = errors["components"] && errors["components"][index];

        return (
          <Box mb={"1rem"} p="6" rounded="md" key={component.id}>
            <Controller
              name={`components.${index}.componentHandle`}
              control={control}
              render={({ field }) => (
                <FormControl mb={"1rem"} isInvalid={!!maybeErrors?.componentHandle?.message}>
                  <FormLabel htmlFor="name">Component Handle</FormLabel>
                  <Input {...field} type="text" />
                  {!!maybeErrors?.componentHandle?.message && <FormErrorMessage>{maybeErrors.componentHandle.message}</FormErrorMessage>}
                </FormControl>
              )}
            />

            <Controller
              name={`components.${index}.quantityToAllocate`}
              control={control}
              render={({ field }) => (
                <FormControl mb={"1rem"} isInvalid={!!maybeErrors?.quantityToAllocate?.message}>
                  <FormLabel htmlFor="name">Quantity To Allocate</FormLabel>
                  <NumberInput min={1} precision={0} {...field}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {!!maybeErrors?.quantityToAllocate?.message && (
                    <FormErrorMessage>{maybeErrors.quantityToAllocate.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name={`components.${index}.pricePointHandle`}
              control={control}
              render={({ field }) => (
                <FormControl mb={"1rem"} isInvalid={!!maybeErrors?.pricePointHandle?.message}>
                  <FormLabel htmlFor="name">Price Point Handle</FormLabel>
                  <Input {...field} type="text" />
                  {!!maybeErrors?.pricePointHandle?.message && <FormErrorMessage>{maybeErrors.pricePointHandle.message}</FormErrorMessage>}
                </FormControl>
              )}
            />

            <Flex justifyContent={"flex-end"}>
              <IconButton
                {...commonButtonProps}
                aria-label="Delete component"
                icon={<DeleteIcon />}
                onClick={() => remove(index)}
                colorScheme="red"
              />
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
};
