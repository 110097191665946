import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../state/rootReducer";
import { useEntitlements } from "./useEntitlements";
import { useCallback, useEffect } from "react";
import {
  downloadUserBillingPlan,
  downloadUserEntitlements,
  downloadUserOrganizations,
  downloadUsers,
  searchUsers,
} from "../state/users/operations";
import type { Entitlement } from "types/entitlements";
import type { MemberOrganization } from "types/organization/MemberOrganization";
import { shallowEqual } from "react-redux";

export function useUsersSearch(target: string, options?: { returnOrganizations: boolean }) {
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.users.users);

  const userEntitlements = useEntitlements();
  const { view_users: hasViewUsers } = userEntitlements;

  useEffect(() => {
    if (!hasViewUsers) {
      return;
    }

    const timeout = setTimeout(() => {
      if (target.length > 0) {
        dispatch(searchUsers({ search: target, returnOrganizations: options?.returnOrganizations }));
      } else {
        dispatch(searchUsers({ returnOrganizations: options?.returnOrganizations }));
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, hasViewUsers, target, options?.returnOrganizations]);

  return users;
}

export function useIsUserSearching(): boolean {
  return useSelector((state: RootState) => state.users.isLoading);
}

export function useUsersMap() {
  return useSelector((state: RootState) => state.users.usersMap, shallowEqual);
}

export function useGetUserName(): (id: string) => string {
  const usersMap = useUsersMap();

  return useCallback(
    (id: string) => {
      const user = usersMap[id];

      return user ? `${user.firstName} ${user.lastName}` : `User-${id}`;
    },
    [usersMap]
  );
}

export function useDownloadUsers(ids?: string[]) {
  const usersToDownload = useSelector((state: RootState) => ids?.flatMap((id) => (state.users.usersMap[id] ? [] : [id])), shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!usersToDownload || usersToDownload.length === 0) return;

    dispatch(downloadUsers(usersToDownload));
  }, [usersToDownload, dispatch]);
}

export function useUserOrganizations(userId: string): MemberOrganization[] | undefined {
  const dispatch = useDispatch();
  const organizations = useSelector((state: RootState) => state.users.userOrganizations[userId]);

  useEffect(() => {
    dispatch(downloadUserOrganizations(userId));
  }, [userId, dispatch]);

  return organizations;
}

export function useUserEntitlements(userId: string): Entitlement[] | undefined {
  const dispatch = useDispatch();
  const entitlements = useSelector((state: RootState) => state.users.userEntitlements[userId]);

  useEffect(() => {
    dispatch(downloadUserEntitlements(userId));
  }, [userId, dispatch]);

  return entitlements;
}

export function useUserBillingPlan(userId: string) {
  const dispatch = useDispatch();
  const plan = useSelector((state: RootState) => state.users.userPlan[userId]);

  useEffect(() => {
    dispatch(downloadUserBillingPlan(userId));
  }, [userId, dispatch]);

  return plan;
}

export const useOrganizationsLoading = (userId: string) => {
  return useSelector((state: RootState) => state.users.isOrganizationsLoading && state.users.userOrganizations[userId] === undefined);
};

export const useEntitlementsLoading = (userId: string) => {
  return useSelector((state: RootState) => state.users.isEntitlementsLoading && state.users.userEntitlements[userId] === undefined);
};

export const useBillingPlanLoading = (userId: string) => {
  return useSelector((state: RootState) => state.users.isBillingPlanLoading && state.users.userPlan[userId] === undefined);
};
