import type { FunctionComponent } from "react";
import React, { useMemo } from "react";
import { Box, Button, Icon, Tooltip, useColorModeValue, Center } from "@chakra-ui/react";
import { OptionsMenuItem } from "../../components/OptionsMenuItem";
import { IoShareOutline } from "react-icons/io5";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useUserProfile, useCollectionKey, useButtonProps } from "hooks";

interface Props {
  isDisabled?: boolean;
  onClick: () => void;
  isMenuItem?: boolean;
  isIcon?: boolean;
  size?: string;
  className?: string;
  projectId: string;
}

export const SharePortfolioButton: FunctionComponent<React.PropsWithChildren<Props>> = ({
  isDisabled = false,
  onClick,
  isMenuItem = true,
  isIcon = false,
  size = "1rem",
  className = "ch-share-portfolio",
  projectId,
}) => {
  const tileBorderColor = useColorModeValue("gray.500", "gray.600");
  const additionalThumbnailCountTextColor = useColorModeValue("gray.500", "gray.400");
  const { setSharePortfolioId } = useConversationContext();
  const { id: currentUserId } = useUserProfile();
  const primaryButtonProps = useButtonProps("sm", "primary");
  const currentSharedDetails = useCollectionKey(projectId, "shareDetails");

  const isCurrentProjectShared = useMemo(() => {
    return currentSharedDetails?.ownerId === currentUserId;
  }, [currentSharedDetails?.ownerId, currentUserId]);

  const handleClick = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    onClick();
    setSharePortfolioId(projectId);
  };

  return (
    <>
      {isMenuItem ? (
        <OptionsMenuItem
          isDisabled={isDisabled}
          menuLabel={`${isCurrentProjectShared ? "Un-S" : "S"}hare Portfolio`}
          className={className}
          onClick={handleClick}
        />
      ) : (
        <Box className={className} width="unset" cursor="pointer" backgroundColor="transparent" borderRadius="md" onClick={handleClick}>
          {!isIcon ? (
            <Button aria-label={`${isCurrentProjectShared ? "Un-S" : "S"}hare Portfolio`} {...primaryButtonProps}>
              {`${isCurrentProjectShared ? "Un-S" : "S"}hare Portfolio`}
            </Button>
          ) : (
            <Tooltip aria-label="" label={`${isCurrentProjectShared ? "Un-S" : "S"}hare Portfolio`} placement="top" hasArrow>
              <Center>
                <Icon as={IoShareOutline} color={tileBorderColor} boxSize={size} _hover={{ color: additionalThumbnailCountTextColor }} />
              </Center>
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};
