import { Record, String } from "runtypes";
import type { Static } from "runtypes";
import { TickerStatus } from "./TickerStatus";

export const Ticker = Record({
  creationByUser: String,
  creationDate: String,
  exchange: String,
  id: String,
  lastUpdatedByUser: String.nullable(),
  lastUpdatedDate: String.nullable(),
  name: String,
  notes: String.nullable().optional(),
  companyLogoUrl: String.nullable().optional(),
  companyUrl: String.nullable().optional(),
  companyTaxId: String.nullable().optional(),
  companyLegalName: String.nullable().optional(),
  status: TickerStatus,
  type: String.nullable(),
  symbol: String,
});

export type Ticker = Static<typeof Ticker>;
