import React, { startTransition, useCallback, useDeferredValue, useEffect, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import type { DropResult, DraggableStyle } from "@hello-pangea/dnd";
import { useFieldArray, Controller, useWatch, useFormContext } from "react-hook-form";
import {
  Stack,
  Text,
  FormControl,
  FormLabel,
  Box,
  Button,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  Flex,
  Input,
  FormErrorMessage,
  UnorderedList,
  ListItem,
  Code,
} from "@chakra-ui/react";
import { RequirementsFieldArray } from "./RequirementsFieldArray";
import { useButtonProps } from "hooks";
import type { FormValues } from "./ConfiguredWorkflowUpsertModal";
import { IncludedEntitiesFieldArray } from "./IncludedEntitiesFieldArray";
import { EntitiesToRenameFieldArray } from "./EntitiesToRenameFieldArray";
import { EntitiesToInjectFieldArray } from "./EntitiesToInjectFieldArray";
import { CheckpointUserIntentField } from "./CheckpointUserIntentField";
import { CheckpointIntentContextProvider } from "../context/CheckpointIntentContext";
import { EntityToSplit } from "./EntityToSplit";
import { CheckpointType } from "./CheckpointType";
import startCase from "lodash/startCase";

const grid = 8;
const getItemStyle = (isDragging: boolean, draggableStyle?: DraggableStyle): React.CSSProperties => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  boxShadow: isDragging ? "0 0 10px rgba(0,0,0,0.5)" : "none",

  ...draggableStyle,
});

type EntityType = "restrict" | "inject" | "require" | "rename" | "create";

type SearchField = {
  entityType: EntityType;
  entity: string;
  value?: string;
};

interface CheckpointSearchList {
  type: string;
  intent?: string;
  fields: SearchField[];
  id: string;
}

export const CheckpointsFieldArray = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>();
  const [search, setSearch] = React.useState<string>("");
  const [checkpointsSearchList, setCheckpointsSearchList] = React.useState<CheckpointSearchList[]>([]);
  const deferredSearch = useDeferredValue(search);
  const commonButtonProps = useButtonProps("sm", "secondary");
  const { fields, prepend, remove, move, insert } = useFieldArray({
    control,
    name: "config.checkpoints",
    keyName: "checkpointId",
  });

  const checkpoints = useWatch({
    control,
    name: "config.checkpoints",
  });

  const controlledCheckpoints = useMemo(
    () =>
      fields.map((field, index) => {
        return {
          ...field,
          ...checkpoints[index],
        };
      }),
    [checkpoints, fields]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      if (source.index === destination.index && source.droppableId === destination.droppableId) {
        return;
      }

      move(source.index, destination.index);
    },
    [move]
  );

  const renderCheckpoint = useCallback(
    (index: number) => {
      const checkpoint = controlledCheckpoints[index];

      if (checkpoint?.type === "execute_intent") {
        return (
          <>
            <Stack mb="1rem" direction="row" spacing={2} alignItems="flex-end">
              <CheckpointUserIntentField index={index} />
            </Stack>
            <Stack direction="row">
              <Box flex={"1"} padding={"1rem"} mt="1rem" mb="1rem" borderWidth="1px" borderRadius="lg">
                <EntitiesToInjectFieldArray checkpointIndex={index} />
              </Box>
            </Stack>
            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Confirm before running
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.needsConfirmation`}
                control={control}
              />
            </Stack>

            <Stack direction="row">
              <Controller
                render={({ field }) => (
                  <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                    Run in separate child workflow
                  </Checkbox>
                )}
                name={`config.checkpoints.${index}.runInNewChildWorkflow`}
                control={control}
              />
            </Stack>
            {checkpoint.runInNewChildWorkflow && (
              <>
                <Box mt={"1rem"} mb="1rem" borderWidth="1px" borderRadius="lg">
                  <Tabs isFitted>
                    <TabList>
                      <Tab>
                        <Flex>
                          <Text>Entities to Restrict to Child Workflow</Text>
                          {checkpoint.onlyIncludeTheseEntities?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.onlyIncludeTheseEntities.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                      <Tab>
                        <Flex>
                          <Text>Entities to Rename</Text>
                          {checkpoint.entitiesToRename?.length ? (
                            <Badge ml={"0.5rem"}>{checkpoint.entitiesToRename.length}</Badge>
                          ) : undefined}
                        </Flex>
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <IncludedEntitiesFieldArray checkpointIndex={index} />
                      </TabPanel>
                      <TabPanel>
                        <EntitiesToRenameFieldArray checkpointIndex={index} />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
                <EntityToSplit checkpointIndex={index} />
                <Stack direction="row">
                  <Controller
                    render={({ field }) => (
                      <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                        Ignore child workflow if it fails (Don't fail entire workflow)
                      </Checkbox>
                    )}
                    name={`config.checkpoints.${index}.childWorkflowSuccessIsOptional`}
                    control={control}
                  />
                </Stack>
                <Stack direction="row">
                  <Controller
                    render={({ field }) => (
                      <Checkbox size={"sm"} isChecked={field.value} name={field.name} onChange={(e) => field.onChange(e.target.checked)}>
                        Do not wait for previous child workflow to complete
                      </Checkbox>
                    )}
                    name={`config.checkpoints.${index}.doNotWaitForPreviousChildWorkflowsToComplete`}
                    control={control}
                  />
                </Stack>
              </>
            )}
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      } else if (checkpoint?.type === "create_entities") {
        return (
          <>
            <FormControl>
              <FormLabel fontSize="sm">Entities to Inject</FormLabel>
              <EntitiesToInjectFieldArray checkpointIndex={index} />
            </FormControl>
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      } else if (checkpoint?.type === "milestone") {
        const maybeCheckpointError = errors?.config?.checkpoints?.[index]?.label;

        return (
          <>
            <FormControl isInvalid={!!maybeCheckpointError}>
              <FormLabel fontSize="sm">Milestone label</FormLabel>
              <Controller
                name={`config.checkpoints.${index}.label`}
                control={control}
                render={({ field }) => <Input {...field} size={"sm"} placeholder="Milestone label" />}
              />
              {maybeCheckpointError?.message && <FormErrorMessage>{maybeCheckpointError.message}</FormErrorMessage>}
            </FormControl>
            <RequirementsFieldArray checkpointIndex={index} />
          </>
        );
      }
    },
    [controlledCheckpoints, errors, control]
  );

  const renderFormattedEntity = useCallback((type: EntityType, entity: string, value?: string) => {
    switch (type) {
      case "restrict":
        return (
          <>
            Restrict{" "}
            <Code>
              {entity}={value}
            </Code>
          </>
        );
      case "create":
        return (
          <>
            <Code>
              {entity}={value}
            </Code>
          </>
        );
      case "inject":
        return (
          <>
            Inject{" "}
            <Code>
              {entity}={value}
            </Code>
          </>
        );
      case "require":
        return (
          <>
            Requirement{" "}
            <Code>
              {entity}={value}
            </Code>
          </>
        );
      case "rename":
        return (
          <>
            Rename <Code>{entity} </Code> into <Code>{value}</Code>
          </>
        );

      default:
        return null;
    }
  }, []);

  useEffect(() => {
    if (!deferredSearch) {
      setCheckpointsSearchList([]);
      return;
    }

    startTransition(() => {
      setCheckpointsSearchList(
        controlledCheckpoints.reduce((ids: CheckpointSearchList[], checkpoint, index) => {
          let storedIndex: undefined | number;
          const maybeIntent = checkpoint.intent;
          if (checkpoint?.intent?.toLowerCase().includes(deferredSearch.toLowerCase())) {
            ids.push({ type: checkpoint.type, intent: maybeIntent, id: `config.checkpoints.${index}`, fields: [] });

            storedIndex = ids.length - 1;
          }

          checkpoint.entitiesToInject?.forEach(({ entity, value }) => {
            if (`${entity}-${value}`.toLowerCase().includes(deferredSearch.toLowerCase())) {
              const newFields: SearchField[] = [{ entityType: checkpoint.type === "create_entities" ? "create" : "inject", entity, value }];

              if (storedIndex !== undefined) {
                ids[storedIndex].fields.push(...newFields);
              } else {
                ids.push({
                  type: checkpoint.type,
                  intent: maybeIntent,
                  fields: newFields,
                  id: `config.checkpoints.${index}`,
                });

                storedIndex = ids.length - 1;
              }
            }
          });

          checkpoint.onlyIncludeTheseEntities?.forEach(({ value }, i) => {
            if (value.toLowerCase().includes(deferredSearch.toLowerCase())) {
              const fields: SearchField[] = [{ entityType: "restrict", entity: "onlyIncludeTheseEntities", value }];

              if (storedIndex !== undefined) {
                ids[storedIndex].fields.push(...fields);
              } else {
                ids.push({
                  type: checkpoint.type,
                  intent: maybeIntent,
                  fields,
                  id: `config.checkpoints.${index}`,
                });

                storedIndex = ids.length - 1;
              }
            }
          });

          checkpoint.requirements?.forEach(({ entity, value }) => {
            if (entity.toLowerCase().includes(deferredSearch.toLowerCase())) {
              const fields: SearchField[] = [{ entityType: "require", entity, value }];
              if (storedIndex !== undefined) {
                ids[storedIndex].fields.push(...fields);
              } else {
                ids.push({
                  type: checkpoint.type,
                  intent: maybeIntent,
                  fields,
                  id: `config.checkpoints.${index}`,
                });

                storedIndex = ids.length - 1;
              }
            }
          });

          checkpoint.entitiesToRename?.forEach(({ entityName, renameTo }) => {
            if (
              entityName.toLowerCase().includes(deferredSearch.toLowerCase()) ||
              renameTo.toLowerCase().includes(deferredSearch.toLowerCase())
            ) {
              const newFields: SearchField[] = [{ entityType: "rename", entity: entityName, value: renameTo }];

              if (storedIndex !== undefined) {
                ids[storedIndex].fields.push(...newFields);
              } else {
                ids.push({
                  type: checkpoint.type,
                  intent: maybeIntent,
                  fields: newFields,
                  id: `config.checkpoints.${index}`,
                });

                storedIndex = ids.length - 1;
              }
            }
          });

          return ids;
        }, [])
      );
    });
  }, [deferredSearch, controlledCheckpoints]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <Stack alignItems={"center"} direction="row" justifyContent="space-between" mb="1rem">
          <Input value={search} onChange={(evt) => setSearch(evt.target.value)} size={"sm"} placeholder="Search for checkpoint..." />
          <Button
            {...commonButtonProps}
            onClick={() =>
              prepend({
                intent: "",
                requirements: [],
                runInNewChildWorkflow: false,
                needsConfirmation: false,
                doNotWaitForPreviousChildWorkflowsToComplete: false,
                showEntityToSplit: false,
                entityToSplitName: "",
                entityToSplitRenameTo: "",
                entityToSplitShouldUnwrap: false,
                entitiesToInject: [],
                entitiesToRename: [],
                type: "execute_intent",
              })
            }>
            Add Checkpoint
          </Button>
        </Stack>
        {search && checkpointsSearchList.length > 0 && (
          <Box maxH={200} overflow="auto" mb={"1rem"} mt={"1rem"} p="6" rounded="md">
            <Text fontSize={"md"}>Found {checkpointsSearchList.length} results</Text>
            <UnorderedList>
              {checkpointsSearchList.map(({ intent, type, fields, id }, index) => (
                <ListItem
                  onClick={() => {
                    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
                  }}
                  role={"button"}
                  _hover={{ cursor: "pointer", backgroundColor: "gray.100" }}
                  p={2}
                  key={index}>
                  <Text wordBreak={"break-all"} fontSize={"sm"}>
                    {startCase(type)}:{" "}
                    {intent && (
                      <Text as={"b"} fontSize={"xs"}>
                        {intent}
                      </Text>
                    )}
                  </Text>

                  <UnorderedList>
                    {fields.length > 0 &&
                      fields.map(({ entityType, entity, value }, i) => (
                        <ListItem wordBreak={"break-all"} fontSize={"sm"} key={i}>
                          {renderFormattedEntity(entityType, entity, value)}
                        </ListItem>
                      ))}
                  </UnorderedList>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
        {search && checkpointsSearchList.length === 0 && (
          <Text fontSize={"md"} mb={"1rem"}>
            No results found
          </Text>
        )}
        <CheckpointIntentContextProvider>
          <Droppable droppableId="checkpoints">
            {(droppableProvided) => (
              <Stack {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} spacing={4}>
                {fields.map((field, index) => (
                  <Draggable key={field.checkpointId} draggableId={field.checkpointId || String(index)} index={index}>
                    {(draggableProvided, snapshot) => (
                      <Box
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, draggableProvided.draggableProps.style)}
                        borderWidth="2px"
                        borderRadius="md"
                        padding="1rem"
                        left={"auto !important"}
                        id={`config.checkpoints.${index}`}>
                        {field.id && (
                          <Controller
                            name={`config.checkpoints.${index}.id`}
                            control={control}
                            render={({ field: controllerField }) => (
                              <FormControl>
                                <Input {...controllerField} type="hidden" />
                              </FormControl>
                            )}
                          />
                        )}

                        <CheckpointType control={control} checkpointIndex={index} onRemove={() => remove(index)} />

                        {renderCheckpoint(index)}

                        <Stack direction="row" spacing="1rem" mt="1rem">
                          <Button
                            {...commonButtonProps}
                            onClick={() =>
                              insert(index + 1, {
                                intent: "",
                                requirements: [],
                                runInNewChildWorkflow: false,
                                needsConfirmation: false,
                                doNotWaitForPreviousChildWorkflowsToComplete: false,
                                showEntityToSplit: false,
                                entityToSplitName: "",
                                entityToSplitRenameTo: "",
                                entityToSplitShouldUnwrap: false,
                                entitiesToInject: [],
                                entitiesToRename: [],
                                type: "execute_intent",
                              })
                            }>
                            Add new checkpoint after this one
                          </Button>
                          <Button
                            {...commonButtonProps}
                            ml={"0.5"}
                            onClick={() =>
                              insert(index + 1, {
                                ...field,
                                id: undefined,
                              })
                            }>
                            Duplicate checkpoint
                          </Button>
                        </Stack>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </Stack>
            )}
          </Droppable>
        </CheckpointIntentContextProvider>
      </Box>
    </DragDropContext>
  );
};
