import type { JSONSchema7 } from "json-schema";

export const AnswerFocusOptionsSchema: JSONSchema7 = {
  $id: "http://json-schema.org/draft-07/schema#",
  description: "The weights of the sources used in the project. Note: deep_analysis uses the same weights as analytical.",
  type: "array",
  maxLength: 3,
  minLength: 2,
  examples: [
    [
      {
        label: "Analytical",
        key: "analytical",
        sourceContentWeights: { factset: 1.5, user_uploaded: 1.0 },
        default: true,
        color: "#009AD8",
      },
    ],
  ],
  items: {
    type: "object",
    properties: {
      label: {
        type: "string",
      },
      key: {
        type: "string",
        description: "The focus type. Note: deep_analysis uses the same source weights as analytical.",
      },
      color: {
        type: "string",
        description: "The color of the focus type.",
      },
      default: {
        type: "boolean",
      },
      sourceContentWeights: {
        type: "object",
        properties: {
          factset: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          sec: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          google_search: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          bing: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          duckduckgo: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
          user_uploaded: {
            type: "number",
            minimum: 0.7,
            maximum: 1.5,
          },
        },
      },
    },
    required: ["label", "key", "sourceContentWeights"],
  },
};
