import React from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { Tooltip, YAxis, ResponsiveContainer, Bar, BarChart, Cell, XAxis } from "recharts";
import { CustomTooltip, chartItemColor } from "./CustomChartUtils";
import type { DataType } from "hooks/useStats";
interface Props {
  data: DataType[];
  width?: number | string;
  title?: string;
  onClick?: (event: any) => void;
  selectedWorkflowIds?: string[];
  chartId?: string;
  showStateTitle?: boolean;
  showVertical?: boolean;
  barColor?: string;
  yAxisType?: "number" | "category";
  height?: string;
  chartHeight?: number;
  showXAxis?: boolean;
  showYAxis?: boolean;
}

export const CustomBarChart: React.FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  data,
  width = "100%",
  title,
  onClick,
  selectedWorkflowIds,
  chartId,
  showStateTitle = false,
  showVertical = false,
  barColor,
  yAxisType = "number",
  height = "13rem",
  chartHeight = 100,
  showXAxis = true,
  showYAxis = true,
}) => {
  return (
    <Stack height={height} width={width}>
      {data.every((item) => !item.value) ? (
        <Center height={height} pb="1rem" fontSize="xs">
          No Projects
        </Center>
      ) : (
        <Stack height={height} width={width}>
          <ResponsiveContainer width={"100%"} height={chartHeight}>
            <BarChart
              key={chartId}
              layout={showVertical ? "vertical" : "horizontal"}
              id={chartId}
              data={data}
              margin={{ top: 0, right: 0, left: 0, bottom: 38 }}>
              <Tooltip isAnimationActive={false} content={<CustomTooltip color="white" showState={showStateTitle} />} />
              {showYAxis && (
                <YAxis type={yAxisType} dataKey={showVertical ? "label" : "value"} axisLine={false} fontSize="10px" width={30} />
              )}
              {showXAxis && <XAxis fontSize="10px" dataKey={showVertical ? "value" : "label"} height={10} />}
              <Bar maxBarSize={40} minPointSize={30} onClick={(data) => onClick?.(data)} dataKey="value">
                {data.map((entry, index) => (
                  <Cell
                    onClick={(data) => onClick?.(data)}
                    key={`cell-${index}`}
                    cursor="pointer"
                    fill={
                      barColor
                        ? barColor
                        : selectedWorkflowIds && entry.workflowId && selectedWorkflowIds.includes(entry.workflowId)
                        ? "#4A5568"
                        : entry.state && chartItemColor(entry.state)
                    }
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          {title && (
            <Text mt="0!important" fontSize={"xs"} width={"100%"} align={"center"} pl="3rem">
              {title}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
