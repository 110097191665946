import { SourceWeights } from "./SourceWeights";
import { Record, String, Boolean } from "runtypes";
import type { Static } from "runtypes";

export const SourceWeightsArrayItem = Record({
  label: String,
  key: String,
  color: String,
  sourceContentWeights: SourceWeights,
  default: Boolean.optional(),
});

export type SourceWeightsArrayItem = Static<typeof SourceWeightsArrayItem>;
