import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserEntitlements } from "api/entitlements";
import type { RootState } from "state/rootReducer";
import type { Entitlement } from "types/entitlements";
import {
  deleteEntitlementConfig,
  deleteOrganizationEntitlementConfig,
  deleteUserEntitlementConfig,
  listEntitlementConfigs,
  postEntitlementConfig,
  postPlanEntitlementConfig,
  putEntitlementConfig,
  putOrganizationEntitlementConfig,
  putPlanEntitlementConfig,
  putUserEntitlementConfig,
} from "api/entitlementConfigs";
import type { EntitlementConfig } from "api/entitlementConfigs/models/Entitlement";
import type { EntitlementPlanConfig } from "api/entitlementConfigs/models/EntitlementPlanConfig";

export type PlanUpsert = EntitlementPlanConfig & { plan: string; newPlan: string };

export const downloadEntitlements = createAsyncThunk("entitlement/download", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;

  if (state.session.user?.id) {
    return await getUserEntitlements(state.session.user.id);
  } else {
    console.warn(`Tried to fetch entitlements but no user id was available`);

    return [] as Entitlement[];
  }
});

export const downloadEntitlementConfigs = createAsyncThunk("entitlementConfig/download", async () => {
  return await listEntitlementConfigs();
});

export const createEntitlementConfig = createAsyncThunk("entitlementConfig/create", async (entitlement: EntitlementConfig) => {
  return await postEntitlementConfig(entitlement);
});

export const addPlanEntitlementConfig = createAsyncThunk(
  "entitlementConfig/add/plan",
  async ({ entitlementId, plan }: { entitlementId: string; plan: Partial<PlanUpsert> }) => {
    return await postPlanEntitlementConfig(entitlementId, plan);
  }
);

export const updatePlanEntitlementConfig = createAsyncThunk(
  "entitlementConfig/update/plan",
  async ({ entitlementId, plan }: { entitlementId: string; plan: Partial<PlanUpsert> }) => {
    return await putPlanEntitlementConfig(entitlementId, plan);
  }
);

export const updateEntitlementConfig = createAsyncThunk(
  "entitlementConfig/update",
  async (payload: { name: string; description: string; code: string }) => {
    return await putEntitlementConfig(payload);
  }
);

export const addUserEntitlementConfig = createAsyncThunk(
  "entitlementConfig/add/user",
  async ({ entitlementId, userId }: { entitlementId: string; userId: string }, thunkAPI) => {
    const response = await putUserEntitlementConfig(entitlementId, userId);

    thunkAPI.dispatch(downloadEntitlements());

    return response;
  }
);

export const removeUserEntitlementConfig = createAsyncThunk(
  "entitlementConfig/remove/user",
  async ({ entitlementId, userId }: { entitlementId: string; userId: string }, thunkAPI) => {
    const response = await deleteUserEntitlementConfig(entitlementId, userId);

    thunkAPI.dispatch(downloadEntitlements());

    return response;
  }
);

export const addOrganizationEntitlementConfig = createAsyncThunk(
  "entitlementConfig/add/organization",
  async ({ entitlementId, organizationId }: { entitlementId: string; organizationId: string }, thunkAPI) => {
    const response = await putOrganizationEntitlementConfig(entitlementId, organizationId);

    thunkAPI.dispatch(downloadEntitlements());

    return response;
  }
);

export const removeOrganizationEntitlementConfig = createAsyncThunk(
  "entitlementConfig/remove/organization",
  async ({ entitlementId, organizationId }: { entitlementId: string; organizationId: string }, thunkAPI) => {
    const response = await deleteOrganizationEntitlementConfig(entitlementId, organizationId);

    thunkAPI.dispatch(downloadEntitlements());

    return response;
  }
);

export const removeEntitlementConfig = createAsyncThunk("entitlementConfig/remove", async (entitlementId: string) => {
  return await deleteEntitlementConfig(entitlementId);
});
